var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import Component from 'navigation/component/Component';
import browser from 'helpers/browser';
import store from 'store/store';
var Toaster = /** @class */ (function (_super) {
    __extends(Toaster, _super);
    function Toaster() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        Object.defineProperty(_this, "hideMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (div, _a) {
                var close = _a.close, inner = _a.inner;
                return function () {
                    if (!div.parentNode)
                        return;
                    var height = inner.offsetHeight;
                    div.style.setProperty('--height', height + 'px');
                    close.onclick = null;
                    browser.waitRepaint(function () {
                        div.classList.add('hide');
                    });
                    setTimeout(function () {
                        _this.el.removeChild(div);
                    }, 1000);
                };
            }
        });
        Object.defineProperty(_this, "hideAllMessages", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                Array.from(_this.el.children).forEach(function (child) {
                    _this.hideMessage(child, {
                        close: child.querySelector('.toaster__message-close'),
                        inner: child.querySelector('.toaster__message-inner')
                    })();
                });
            }
        });
        Object.defineProperty(_this, "onErrorUpdate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (message) {
                if (!message)
                    return;
                store.message.value = null;
                _this.addMessage(message);
            }
        });
        return _this;
    }
    Object.defineProperty(Toaster.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            store.message.listenAndStart(this.onErrorUpdate);
        }
    });
    Object.defineProperty(Toaster.prototype, "addMessage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (_a) {
            var _this = this;
            var text = _a.text, error = _a.error, _b = _a.cart, cart = _b === void 0 ? false : _b;
            var div = document.createElement('div');
            var inner = document.createElement('div');
            var close = document.createElement('button');
            close.className = 'toaster__message-close';
            close.innerHTML = '<i class="icon icon-large icon__close"></i>';
            close.onclick = this.hideMessage(div, { close: close, inner: inner });
            div.className = 'toaster__message initial';
            div.classList.toggle('error', error);
            div.classList.toggle('cart-toaster', cart);
            inner.className = 'toaster__message-inner';
            inner.innerHTML = text;
            inner.appendChild(close);
            div.appendChild(inner);
            this.el.appendChild(div);
            browser.waitRepaint(function () {
                var height = inner.offsetHeight;
                div.style.setProperty('--height', height + 'px');
                div.classList.remove('initial');
                if (cart)
                    _this.bindInternalRouter();
            });
            setTimeout(function () {
                div.style.setProperty('--height', 'auto');
            }, 1000);
            setTimeout(this.hideMessage(div, { close: close, inner: inner }), 5000);
        }
    });
    return Toaster;
}(Component));
export default Toaster;
