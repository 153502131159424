var shortestAngle = function (from, to, lower, upper) {
    var difference = from - to;
    var halfDistance = (upper - lower) / 2;
    return wrap(difference + halfDistance, lower, upper) - halfDistance;
};
var wrap = function (value, lower, upper) {
    var distance = upper - lower;
    var times = Math.floor((value - lower) / distance);
    return value - times * distance;
};
var round = function (number, precision) { return Math.round(number * Math.pow(10, precision)) / Math.pow(10, precision); };
var even = function (number) { return Math.ceil(number / 2) * 2; };
var scale = function (method, containerW, containerH, contentW, contentH, safeZone) {
    if (safeZone === void 0) { safeZone = null; }
    var scaleX = containerW / contentW;
    var scaleY = containerH / contentH;
    var scaleXSafe, scaleYSafe;
    if (safeZone) {
        scaleXSafe = containerW / (contentW * safeZone);
        scaleYSafe = containerH / (contentH * safeZone);
    }
    switch (method) {
        case 'contain':
            return Math.min(scaleX, scaleY);
        case 'width':
            return scaleX;
        case 'height':
            return safeZone
                ? Math.max(Math.min(scaleX, scaleYSafe), scaleY)
                : scaleY;
        case 'cover':
            return safeZone
                ? Math.max(Math.min(scaleX, scaleYSafe), Math.min(scaleY, scaleXSafe))
                : Math.max(scaleX, scaleY);
    }
};
var clamp = function (value, min, max) { return Math.min(max, Math.max(min, value)); };
var map = function (value, start1, stop1, start2, stop2) { return start2 + (stop2 - start2) * ((value - start1) / (stop1 - start1)); };
var mapClamp = function (value, start1, stop1, start2, stop2) {
    if (start2 === void 0) { start2 = 0; }
    if (stop2 === void 0) { stop2 = 1; }
    return clamp(map(value, start1, stop1, start2, stop2), start2, stop2);
};
var modulo = function (value, mod) { return ((value % mod) + mod) % mod; };
export default {
    shortestAngle: shortestAngle,
    wrap: wrap,
    round: round,
    even: even,
    scale: scale,
    clamp: clamp,
    map: map,
    mapClamp: mapClamp,
    modulo: modulo
};
