var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import MainPage from 'navigation/pages/MainPage';
import modulesMap from 'core/modulesMap';
import EditorialSubnav from 'components/editorial/EditorialSubnav';
import DragController from 'components/DragController/DragController';
import EditorialRelatedContent from 'components/editorial-related-content/EditorialRelatedContent';
import ArticleGallery from './partials/ArticleGallery';
import ArticleImage from './partials/ArticleImage';
import ArticleProducts from './partials/ArticleProducts';
var Article = /** @class */ (function (_super) {
    __extends(Article, _super);
    function Article() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Article.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __assign(__assign({}, modulesMap), { image: ['.article-image', ArticleImage], dragController: ['.article-carousel__wrapper', DragController], gallery: ['.article-gallery', ArticleGallery], subnav: ['.editorial-subnav', EditorialSubnav], products: ['.article-products', ArticleProducts], editorialRelatedContent: ['.editorial-related-content', EditorialRelatedContent] });
        }
    });
    Object.defineProperty(Article.prototype, "getModuleParams", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (el, componentConstructor) {
            return __assign(__assign({}, _super.prototype.getModuleParams.call(this, el, componentConstructor)), { snaps: true, start: 1 });
        }
    });
    Object.defineProperty(Article.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
        }
    });
    Object.defineProperty(Article.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.resize.call(this);
            if (this.modules.subnav)
                this.modules.subnav.endOfPage = this.refs.articleWrapper.getBoundingClientRect().bottom;
        }
    });
    return Article;
}(MainPage));
export default Article;
