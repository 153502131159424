import Component from 'navigation/component/Component'

class DrawerPanel extends Component {
  initialized () {
    super.initialized()
  }

  resize () {
    super.resize()
    this.el.style.setProperty('--drawer-height', this.el.firstElementChild.clientHeight + 'px')
  }
}

export default DrawerPanel
