var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Component from 'navigation/component/Component';
import config from 'core/config';
import sizeStore from 'store/sizeStore';
import { bindMethod } from 'helpers/bind';
import anime from 'animejs';
import { EASE_OUT, frameToMs } from 'helpers/easings';
import store from 'store/store';
import { startPanels } from 'store/middlewares/panels';
var Header = /** @class */ (function (_super) {
    __extends(Header, _super);
    function Header(el, options) {
        var _this = _super.call(this, el, options) || this;
        Object.defineProperty(_this, "motions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(_this, "onCartUpdate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (count) {
                _this.refs.headerCart.setAttribute('data-count', count);
                _this.refs.headerCart.classList.toggle('header-right__cart-not-empty', count !== 0);
            }
        });
        Object.defineProperty(_this, "onAlternateLinkUpdate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (links) {
                if (links === void 0) { links = {}; }
                if (!_this.refs.headerAlternateLinks)
                    return;
                _this.refs.headerAlternateLinks.forEach(function (link) {
                    var lang = link.getAttribute('data-lang');
                    var href = links[lang];
                    if (href)
                        link.setAttribute('href', href);
                    else
                        link.setAttribute('href', config.baseurl + '/' + lang);
                });
            }
        });
        Object.defineProperty(_this, "onLoginStatusUpdate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (logged) {
                _this.el.classList.toggle('logged', logged);
            }
        });
        Object.defineProperty(_this, "onMenuClicked", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                if (store.search.get())
                    store.search.set(null);
                var drawer = store.drawer.get();
                store.drawer.set(drawer === 'menu' ? null : 'menu');
                e.stopImmediatePropagation();
                e.stopPropagation();
                e.preventDefault();
            }
        });
        Object.defineProperty(_this, "onDrawerChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (value, previous) {
                var isOpen = value === 'menu';
                if (!isOpen && previous !== 'menu')
                    return;
                _this.cancelMotions();
                if (isOpen)
                    _this.setMenuIconOpened();
                else
                    _this.setMenuIconClosed();
            }
        });
        Object.defineProperty(_this, "onHeaderSearchClicked", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                store.drawer.set(store.drawer.get() === 'search' ? null : 'search');
            }
        });
        Object.defineProperty(_this, "onScroll", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                sizeStore.headerBottom.set(_this.el.offsetTop + _this.el.offsetHeight);
            }
        });
        _this.resize();
        return _this;
    }
    Object.defineProperty(Header.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            if (add === void 0) { add = true; }
            var method = bindMethod(add);
            store.cart.toggleListener(add, this.onCartUpdate);
            store.logged.toggleListener(add, this.onLoginStatusUpdate);
            store.alternateLinks.toggleListener(add, this.onAlternateLinkUpdate);
            store.drawer.toggleListener(add, function (drawer, previous) { return _this.onDrawerChange(drawer, previous); });
            this.refs.headerSearchButton[method]('click', this.onHeaderSearchClicked);
            this.refs.headerMenuButton[method]('click', this.onMenuClicked);
            this.bindInternalRouter();
        }
    });
    Object.defineProperty(Header.prototype, "show", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.el.classList.add('shown');
            startPanels();
        }
    });
    Object.defineProperty(Header.prototype, "bindRefs", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.bindRefs.call(this);
        }
    });
    Object.defineProperty(Header.prototype, "setMenuIconClosed", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var bar = this.refs.headerMenuButton.querySelector('svg line:first-child');
            var secondBar = this.refs.headerMenuButton.querySelector('svg line:last-child');
            var m, m1, m2, m3;
            var opened = {
                first: {
                    x1: 10,
                    y1: 13,
                    x2: 22,
                    y2: 13
                },
                second: {
                    x1: 10,
                    y1: 19,
                    x2: 22,
                    y2: 19
                }
            };
            m = anime({
                targets: secondBar,
                x1: opened.second.x2,
                y1: opened.second.y2,
                x2: opened.second.x1,
                y2: opened.second.y1,
                easing: EASE_OUT,
                duration: frameToMs(25)
            });
            m1 = anime({
                targets: bar,
                x1: opened.first.x1,
                y1: opened.first.y1,
                x2: opened.first.x2,
                y2: opened.first.y2,
                easing: EASE_OUT,
                duration: frameToMs(25)
            });
            m2 = anime({
                targets: this.refs.headerMenuOpenText,
                opacity: 1,
                translateX: 0,
                easing: EASE_OUT,
                duration: frameToMs(25)
                // delay: isOpen ? 0 : frameToMs(50 / 2)
            });
            m3 = anime({
                targets: this.refs.headerMenuCloseText,
                opacity: 0,
                translateX: '-30%',
                easing: EASE_OUT,
                duration: frameToMs(25)
            });
            this.motions.push(m, m1, m2, m3);
        }
    });
    Object.defineProperty(Header.prototype, "setMenuIconOpened", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var bar = this.refs.headerMenuButton.querySelector('svg line:first-child');
            var secondBar = this.refs.headerMenuButton.querySelector('svg line:last-child');
            var m, m1, m2, m3;
            var closed = {
                first: {
                    x1: 12.1,
                    y1: 11.9,
                    x2: 20.6,
                    y2: 21.1
                },
                second: {
                    x1: 20.6,
                    y1: 11.9,
                    x2: 12.1,
                    y2: 21.1
                }
            };
            m = anime({
                targets: secondBar,
                x1: closed.second.x1,
                y1: closed.second.y1,
                x2: closed.second.x2,
                y2: closed.second.y2,
                easing: EASE_OUT,
                duration: frameToMs(25)
            });
            m1 = anime({
                targets: bar,
                x1: closed.first.x1,
                y1: closed.first.y1,
                x2: closed.first.x2,
                y2: closed.first.y2,
                easing: EASE_OUT,
                duration: frameToMs(25)
            });
            m2 = anime({
                targets: this.refs.headerMenuOpenText,
                opacity: 0,
                translateX: '30%',
                easing: EASE_OUT,
                duration: frameToMs(25)
                // delay: isOpen ? 0 : frameToMs(50 / 2)
            });
            m3 = anime({
                targets: this.refs.headerMenuCloseText,
                opacity: 1,
                translateX: 0,
                easing: EASE_OUT,
                duration: frameToMs(25)
            });
            this.motions.push(m, m1, m2, m3);
        }
    });
    Object.defineProperty(Header.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var height = this.el.offsetHeight;
            sizeStore.headerHeight.set(height);
        }
    });
    Object.defineProperty(Header.prototype, "cancelMotions", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.motions.forEach(function (m) { return m.pause(); });
            this.motions = [];
        }
    });
    return Header;
}(Component));
export default Header;
