var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { throttle, defer } from 'lodash-es';
import Component from 'navigation/component/Component';
import mqStore from 'store/mqStore';
import scroll from 'core/scroll';
import { dimensions } from 'helpers/resize';
import store from 'store';
import sizeStore from 'store/sizeStore';
var ProductGallery = /** @class */ (function (_super) {
    __extends(ProductGallery, _super);
    function ProductGallery(el, options) {
        var _this = _super.call(this, el, options) || this;
        Object.defineProperty(_this, "bounds", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "thumbFollowHeight", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "onOpenZoom", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (index) { return function () {
                _this.options.parent.modules.zoom.open(index);
                _this.resize();
            }; }
        });
        Object.defineProperty(_this, "onKeyPress", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (event) {
                if (event.key === 'Escape') {
                    _this.options.parent.modules.zoom.onClose();
                    _this.resize();
                }
            }
        });
        Object.defineProperty(_this, "onThumbClick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (event) {
                var mouseEvent = event; // Cast event to MouseEvent
                var thumbClickPosition = mouseEvent.clientY - (_this.refs.thumbItems.getBoundingClientRect().top);
                var thumbClickRatio = thumbClickPosition / _this.refs.thumbItems.scrollHeight;
                if (_this.refs.list) {
                    var scrollTarget = thumbClickRatio * _this.refs.list.scrollHeight - dimensions().innerHeight / 2;
                    scroll.scrollTo(scrollTarget);
                }
            }
        });
        Object.defineProperty(_this, "onScroll", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: throttle(function () {
                if (mqStore.tabletPortrait.get())
                    return;
                defer(function () {
                    if (!_this.refs.list)
                        return;
                    var scrollableHeight = _this.refs.list.scrollHeight;
                    var scrollY = scroll.scrollTop();
                    var thumbTop = (scrollY / scrollableHeight) * 100;
                    var thumbFollowHeightPercentage = (_this.thumbFollowHeight / _this.refs.thumbItems.scrollHeight) * 100;
                    var adjustedThumbTop = Math.min(thumbTop, 100 - thumbFollowHeightPercentage);
                    _this.refs.track.style.setProperty('--zoom-thumb-top', "".concat(adjustedThumbTop, "%"));
                });
            })
        });
        Object.defineProperty(_this, "onScrollMobile", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                var progress = _this.refs.list.scrollLeft / (_this.refs.list.scrollWidth - _this.refs.list.clientWidth);
                _this.refs.trackMobile.style.transform = "translateX(".concat(progress * (100) * (_this.refs.galleryItems.length - 1), "%)");
            }
        });
        Object.defineProperty(_this, "onShopLookClick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                store.drawer.set('shop-the-look');
            }
        });
        _this.thumbFollowHeight = 0;
        return _this;
    }
    Object.defineProperty(ProductGallery.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
            if (this.refs.thumbItems)
                this.onScroll();
        }
    });
    Object.defineProperty(ProductGallery.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            var _a, _b;
            if (add === void 0) { add = true; }
            var m = add ? 'addEventListener' : 'removeEventListener';
            if (this.refs.zoomItem)
                scroll[add ? 'on' : 'off'](this.onScroll);
            if (this.refs.zoomItem)
                (_a = this.refs) === null || _a === void 0 ? void 0 : _a.zoomItem.forEach(function (item, index) { return item[m]('click', _this.onOpenZoom(index)); });
            if (this.refs.thumbItems)
                (_b = this.refs) === null || _b === void 0 ? void 0 : _b.thumbItems[m]('click', this.onThumbClick);
            if (this.refs.shopLookButton)
                this.refs.shopLookButton[m]('click', this.onShopLookClick);
            if (this.refs.list)
                this.refs.list[m]('scroll', this.onScrollMobile);
            window[m]('keyup', this.onKeyPress);
        }
    });
    Object.defineProperty(ProductGallery.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            _super.prototype.resize.call(this);
            defer(function () {
                var _a, _b, _c;
                var inc = sizeStore.notificationHeight.get() + sizeStore.headerHeight.get();
                _this.bounds = _this.refs.galleryItems.map(function (item) { return inc + item.offsetTop + (item.offsetHeight / 2); });
                _this.bounds.pop();
                _this.bounds.unshift(0);
                var wrapperHeight = (_a = _this.refs.list) === null || _a === void 0 ? void 0 : _a.scrollHeight;
                var vh = window.innerHeight;
                var wrapperThumbHeight = (_b = _this.refs.thumbItems) === null || _b === void 0 ? void 0 : _b.scrollHeight;
                _this.thumbFollowHeight = (wrapperThumbHeight * vh) / wrapperHeight;
                (_c = _this.refs.track) === null || _c === void 0 ? void 0 : _c.style.setProperty('--zoom-thumb-height', "".concat(_this.thumbFollowHeight, "px"));
            });
        }
    });
    Object.defineProperty(ProductGallery.prototype, "flush", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.flush.call(this);
        }
    });
    return ProductGallery;
}(Component));
export default ProductGallery;
