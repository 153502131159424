var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Component from 'navigation/component/Component';
import resize from 'helpers/resize';
var HomeMedia = /** @class */ (function (_super) {
    __extends(HomeMedia, _super);
    function HomeMedia() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(HomeMedia.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _a, _b, _c;
            _super.prototype.resize.call(this);
            var portrait = resize.ratio() < 1;
            var media = this.refs.homeMedia[0];
            if (!media)
                return;
            var srcLandscape = media.getAttribute('data-landscape');
            var srcsetLandscape = media.getAttribute('data-landscape-srcset');
            if (!srcLandscape)
                return;
            var video = media.tagName == 'VIDEO';
            var srcPortrait = media.getAttribute('data-portrait');
            var srcsetPortrait = media.getAttribute('data-portrait-srcset');
            if (!srcPortrait) {
                srcPortrait = media.src;
                media.setAttribute('data-portrait', srcPortrait);
            }
            if (!video && !srcsetPortrait) {
                srcsetPortrait = media.srcset;
                media.setAttribute('data-portrait-srcset', srcsetPortrait);
            }
            var src = portrait && srcPortrait ? srcPortrait : srcLandscape;
            var srcset = portrait && srcsetPortrait ? srcsetPortrait : srcsetLandscape;
            if (media.src !== src) {
                media.src = src;
                if (!video)
                    media.srcset = srcset;
                (_c = (_b = (_a = media === null || media === void 0 ? void 0 : media.play) === null || _a === void 0 ? void 0 : _a.call(media)) === null || _b === void 0 ? void 0 : _b.catch) === null || _c === void 0 ? void 0 : _c.call(_b, function () { });
            }
        }
    });
    return HomeMedia;
}(Component));
export default HomeMedia;
