var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Drawer from 'components/drawer/Drawer';
import { bindMethod } from 'helpers/bind';
import { forceArray } from 'navigation/component/Component';
var ProductSizeSelector = /** @class */ (function (_super) {
    __extends(ProductSizeSelector, _super);
    function ProductSizeSelector(el, options) {
        var _this = _super.call(this, el, options) || this;
        Object.defineProperty(_this, "tab", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(_this, "onTabClick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (index, force) {
                if (force === void 0) { force = false; }
                if (_this.refs.sizeSwitchTab === undefined)
                    return;
                _this.refs.sizeSwitchTab.forEach(function (tab, i) {
                    tab.classList.toggle('active', index === i);
                });
                _this.refs.sizeTabs.classList.toggle('forced', force);
                _this.refs.sizeTabs.dataset.active = index.toString();
                _this.emit('tab:change', index);
                _this.tab = index;
            }
        });
        Object.defineProperty(_this, "onRadioChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (event) {
                var _a;
                var target = event.currentTarget;
                if (_this.refs.sizeTabLabels)
                    _this.refs.sizeTabLabels.find(function (label) { return label.dataset.inputName === target.name; }).textContent = target.dataset.label;
                _this.emit('change', (_a = {},
                    _a[target.name] = target.value,
                    _a.label = target.dataset.label,
                    _a));
            }
        });
        _this.name = 'size-selector';
        _this.params = {
            desktop: {
                axis: 'X',
                direction: 'reversed'
            },
            mobile: {
                axis: 'Y',
                direction: 'reversed'
            }
        };
        return _this;
    }
    Object.defineProperty(ProductSizeSelector.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
            if (this.refs.productSizeConfirmDiv)
                this.el.style.setProperty('--size-confirm-height', "".concat(this.refs.productSizeConfirmDiv.offsetHeight, "px"));
            this.el.style.setProperty('--size-header-height', "".concat(this.refs.productSizeHeader.offsetHeight, "px"));
        }
    });
    Object.defineProperty(ProductSizeSelector.prototype, "toggle", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (value) {
            _super.prototype.toggle.call(this, value);
            var lockMethod = value ? 'lock' : 'unlock';
            scroll[lockMethod](true);
        }
    });
    Object.defineProperty(ProductSizeSelector.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            if (add === void 0) { add = true; }
            _super.prototype.bindEvents.call(this, add);
            var method = bindMethod(add);
            forceArray(this.refs.productSizeRadios).forEach(function (radio) {
                if (radio !== undefined)
                    radio[method]('change', _this.onRadioChange);
            });
            if (this.refs.sizeSwitchTab) {
                forceArray(this.refs.sizeSwitchTab).forEach(function (tab, i) {
                    if (tab !== undefined)
                        tab[method]('click', function () { return _this.onTabClick(i); });
                });
            }
        }
    });
    Object.defineProperty(ProductSizeSelector.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.resize.call(this);
            this.el.style.setProperty('--size-header-height', "".concat(this.refs.productSizeHeader.offsetHeight, "px"));
            if (this.refs.productSizeConfirmDiv)
                this.el.style.setProperty('--size-confirm-height', "".concat(this.refs.productSizeConfirmDiv.offsetHeight, "px"));
        }
    });
    Object.defineProperty(ProductSizeSelector.prototype, "reset", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.refs.productSizeRadios.forEach(function (radio) {
                radio.checked = false;
            });
        }
    });
    return ProductSizeSelector;
}(Drawer));
export default ProductSizeSelector;
