var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import anime from 'animejs';
import { EASE_OUT } from 'helpers/easings';
import { bindMethod } from 'helpers/bind';
import store from 'store';
import MenuPanel from './MenuPanel';
import { durations } from './motion';
var getFirstPanelItemClass = function (item, category) {
    if (category !== null) {
        item.classList.add('inactive');
        if (item.dataset.category === category) {
            item.classList.remove('inactive');
            item.classList.add('active');
        }
        else {
            item.classList.remove('active');
        }
    }
    else {
        item.classList.remove('inactive', 'active');
    }
};
var MenuFirstPanel = /** @class */ (function (_super) {
    __extends(MenuFirstPanel, _super);
    function MenuFirstPanel() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        Object.defineProperty(_this, "_opened", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "onToggleStoreSelector", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                store.drawer.set('webstore-switcher');
            }
        });
        Object.defineProperty(_this, "onLinkClick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                store.drawer.set(null);
            }
        });
        Object.defineProperty(_this, "onItemClick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                var button = e.currentTarget;
                var category = button.dataset.category;
                if (_this.category === category)
                    return;
                _this.category = category;
                _this.onItemOut();
                _this.emit('category:change', category);
            }
        });
        Object.defineProperty(_this, "onSecondaryLinksOver", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (event) {
                _this.refs.secondaryLinks.forEach(function (item) {
                    item.classList.remove('active');
                    item.classList.add('inactive');
                });
                var item = event.currentTarget;
                item.classList.remove('inactive');
                item.classList.add('active');
            }
        });
        Object.defineProperty(_this, "onSecondaryLinksOut", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                _this.refs.secondaryLinks.forEach(function (item) {
                    item.classList.remove('inactive');
                    item.classList.remove('active');
                });
            }
        });
        return _this;
    }
    Object.defineProperty(MenuFirstPanel.prototype, "opened", {
        get: function () {
            return this._opened;
        },
        set: function (value) {
            this._opened = value;
            this.el.classList.toggle('visible', value);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MenuFirstPanel.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            var method = bindMethod(add);
            _super.prototype.bindEvents.call(this, add);
            this.refs.items.forEach(function (item) {
                if (item.tagName !== 'A')
                    item[method]('click', function (e) { return _this.onItemClick(e); });
                else
                    item[method]('click', _this.onLinkClick);
                item[method]('mouseover', function (e) { return _this.onItemOver(e); });
                item[method]('mouseleave', function (e) { return _this.onItemOut(); });
            });
            this.refs.secondaryLinks.forEach(function (item) {
                item[method]('mouseover', function (e) { return _this.onSecondaryLinksOver(e); });
                item[method]('mouseleave', _this.onSecondaryLinksOut);
            });
            this.refs.toggleStoreLocator.forEach(function (toggle) { return toggle[method]('click', function () { return _this.onToggleStoreSelector(); }); });
        }
    });
    Object.defineProperty(MenuFirstPanel.prototype, "onItemOver", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (event) {
            this.refs.items.forEach(function (item) {
                item.classList.remove('active');
                item.classList.add('inactive');
            });
            var item = event.currentTarget;
            item.classList.remove('inactive');
            item.classList.add('active');
        }
    });
    Object.defineProperty(MenuFirstPanel.prototype, "onItemOut", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            this.refs.items.forEach(function (item) {
                getFirstPanelItemClass(item, _this.category);
            });
        }
    });
    Object.defineProperty(MenuFirstPanel.prototype, "open", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (switching) {
            var _this = this;
            var _a;
            if (switching === void 0) { switching = false; }
            // if (!this.mobile) {
            /** D: Panel container -> enter */
            var m, m1;
            var timeout = {
                time: 0
            };
            if (!switching) {
                m = anime({
                    targets: this.el,
                    translateX: {
                        value: ['-50%', 0],
                        easing: EASE_OUT,
                        duration: durations.FIRST_PANEL_TRANSLATE_IN
                    },
                    opacity: {
                        value: [0, 1],
                        easing: 'linear',
                        duration: durations.FIRST_PANEL_OPACITY_IN
                    },
                    complete: function () {
                        _this.opened = true;
                    }
                });
                m1 = anime({
                    targets: timeout,
                    time: 1,
                    duration: durations.FIRST_PANEL_TRANSLATE_IN / 2,
                    begin: function () {
                        _this.emit('open:begin');
                    },
                    complete: function () {
                        _this.emit('open:complete');
                    }
                });
            }
            this.motions.push(m, m1);
            /** D: Panel lists -> staggered entrance on list and list children */
            (_a = this.refs.lists) === null || _a === void 0 ? void 0 : _a.forEach(function (list, index) {
                var mList = anime({
                    targets: list.children,
                    translateX: {
                        value: [-50, 0],
                        delay: anime.stagger(durations.FIRST_PANEL_ITEMS_STAGGER, { start: durations.FIRST_PANEL_ITEMS_TRANSLATE_IN_DELAY + index * durations.FIRST_PANEL_LIST_STAGGER_IN }),
                        duration: durations.FIRST_PANEL_TRANSLATE_IN,
                        easing: EASE_OUT
                    },
                    opacity: {
                        value: [0, 1],
                        delay: anime.stagger(durations.FIRST_PANEL_ITEMS_STAGGER, { start: durations.FIRST_PANEL_ITEMS_OPACITY_IN_DELAY + index * durations.FIRST_PANEL_LIST_STAGGER_IN }),
                        duration: durations.FIRST_PANEL_OPACITY_IN,
                        easing: 'linear'
                    }
                });
                _this.motions.push(mList);
            });
            // }
        }
    });
    Object.defineProperty(MenuFirstPanel.prototype, "close", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var m;
                var _this = this;
                var _a;
                return __generator(this, function (_b) {
                    /** D: Panel lists -> staggered entrance on list and list children */
                    (_a = this.refs.lists) === null || _a === void 0 ? void 0 : _a.forEach(function (list, index) {
                        var mList = anime({
                            targets: list.children,
                            opacity: {
                                value: [1, 0],
                                duration: durations.FIRST_PANEL_OPACITY_OUT / 2,
                                easing: 'linear'
                            }
                        });
                        _this.motions.push(mList);
                    });
                    m = anime({
                        targets: this.el,
                        translateX: {
                            value: '-50%',
                            easing: EASE_OUT,
                            duration: durations.FIRST_PANEL_TRANSLATE_OUT
                        },
                        opacity: {
                            value: 0,
                            easing: 'linear',
                            duration: durations.FIRST_PANEL_OPACITY_OUT
                        },
                        complete: function () {
                            _this.opened = false;
                            _this.reset();
                        }
                    });
                    this.motions.push(m);
                    return [2 /*return*/];
                });
            });
        }
    });
    Object.defineProperty(MenuFirstPanel.prototype, "forceOpen", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _a;
            anime.set(this.el, { translateX: 0, opacity: 1 });
            (_a = this.refs.lists) === null || _a === void 0 ? void 0 : _a.forEach(function (list) {
                anime.set(list.children, { translateX: 0, opacity: 1 });
            });
        }
    });
    Object.defineProperty(MenuFirstPanel.prototype, "forceClose", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _a;
            anime.set(this.el, { translateX: '-50%', opacity: 0 });
            (_a = this.refs.lists) === null || _a === void 0 ? void 0 : _a.forEach(function (list) {
                anime.set(list.children, { translateX: -50, opacity: 0 });
            });
        }
    });
    Object.defineProperty(MenuFirstPanel.prototype, "reset", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _a;
            _super.prototype.reset.call(this);
            this.onItemOut();
            this.el.style.opacity = '';
            this.el.style.transform = '';
            this.el.style.clipPath = '';
            this.opened = false;
            this.category = null;
            (_a = this.refs.lists) === null || _a === void 0 ? void 0 : _a.forEach(function (list) {
                list.style.opacity = '';
                list.style.transform = '';
            });
        }
    });
    Object.defineProperty(MenuFirstPanel.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.resize.call(this);
            if (this.opened)
                this.forceOpen();
        }
    });
    return MenuFirstPanel;
}(MenuPanel));
export default MenuFirstPanel;
