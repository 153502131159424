var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Drawer from 'components/drawer/Drawer';
import modulesMap from 'core/modulesMap';
import { bindMethod } from 'helpers/bind';
import { map } from 'lodash-es';
import { forceArray } from 'navigation/component/Component';
import store from 'store';
import mqStore from 'store/mqStore';
var Filters = /** @class */ (function (_super) {
    __extends(Filters, _super);
    function Filters(el, options) {
        var _this = _super.call(this, el, options) || this;
        Object.defineProperty(_this, "filterUrl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: _this.el.getAttribute('data-filter-url')
        });
        Object.defineProperty(_this, "checked", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {}
        });
        Object.defineProperty(_this, "eventsChecked", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {}
        });
        Object.defineProperty(_this, "reset", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                _this.refs.filterItems.forEach(function (checkbox) {
                    checkbox.checked = false;
                });
                _this.parseFilters();
                _this.submit();
            }
        });
        Object.defineProperty(_this, "onFilterUpdate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (_a) {
                var checkbox = _a.currentTarget;
                if (checkbox.checked && checkbox.getAttribute('data-exclude') === 'true') {
                    _this.refs.filterItems.forEach(function (_checkbox) {
                        if (_checkbox.name === checkbox.name && checkbox.value !== _checkbox.value)
                            _checkbox.checked = false;
                    });
                }
                _this.parseFilters();
            }
        });
        Object.defineProperty(_this, "sendEvent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                var data = {
                    event: 'click_filter'
                };
                for (var key in _this.eventsChecked)
                    data[key] = _this.eventsChecked[key].join('::');
                if (window.dataLayer)
                    window.dataLayer.push(data);
            }
        });
        _this.name = 'filters';
        _this.params = {
            desktop: {
                axis: 'X',
                direction: 'reversed'
            },
            mobile: {
                axis: 'X',
                direction: 'reversed'
            }
        };
        _this.initializeFilters();
        return _this;
    }
    Object.defineProperty(Filters.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
        }
    });
    Object.defineProperty(Filters.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __assign({}, modulesMap);
        }
    });
    Object.defineProperty(Filters.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            var method = bindMethod(add);
            if (this.refs.filterItems)
                this.refs.filterItems.forEach(function (el) { return el[method]('change', _this.onFilterUpdate); });
            this.refs.filterSubmit.forEach(function (submit) { return submit[method]('click', function () { return _this.submit(); }); });
            this.refs.filterReset.forEach(function (reset) { return reset[method]('click', _this.reset); });
            _super.prototype.bindEvents.call(this, add);
        }
    });
    Object.defineProperty(Filters.prototype, "initializeFilters", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (this.refs.filterCheckboxes === undefined)
                return;
            if (mqStore.tabletPortrait.get()) {
                forceArray(this.refs.filterCheckboxes).forEach(function (checkbox) {
                    checkbox.checked = false;
                });
            }
            else {
                forceArray(this.refs.filterCheckboxes).forEach(function (checkbox) {
                    checkbox.checked = true;
                });
            }
        }
    });
    Object.defineProperty(Filters.prototype, "parseFilters", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var checked = {};
            var eventsChecked = {};
            var empty = true;
            this.refs.filterItems.forEach(function (checkbox) {
                if (!checkbox.checked)
                    return;
                if (!checked[checkbox.name])
                    checked[checkbox.name] = [];
                if (!eventsChecked[checkbox.name])
                    eventsChecked[checkbox.name] = [];
                checked[checkbox.name].push(checkbox.value);
                eventsChecked[checkbox.name].push(checkbox.dataset.eventLabel);
                empty = false;
            });
            this.checked = checked;
            this.eventsChecked = eventsChecked;
            this.refs.filterReset.forEach(function (reset) { return reset[empty ? 'setAttribute' : 'removeAttribute']('disabled', 'true'); });
            var separator = ~this.filterUrl.indexOf('?') ? '&' : '?';
            var url = this.filterUrl + (empty ? '' : (separator + map(checked, function (values, key) { return "".concat(key, "=").concat(values.join(',')); }).join('&')));
            this.refs.filterSubmit.forEach(function (submit) { return submit.setAttribute('href', url); });
            this.sendEvent();
        }
    });
    Object.defineProperty(Filters.prototype, "submit", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.parseFilters();
            store.drawer.set(null);
        }
    });
    Object.defineProperty(Filters.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.resize.call(this);
            this.initializeFilters();
        }
    });
    return Filters;
}(Drawer));
export default Filters;
