var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { isArray, each, map, invokeMap, defer, mapValues } from 'lodash-es';
import { TinyEmitter } from 'tiny-emitter';
import { getRoot, updatePageLinks } from 'core/router';
import tag from 'core/tag';
import store from 'store/store';
export var getRouterData = function (string) {
    var routerData = (string === null || string === void 0 ? void 0 : string.split('|')) || [];
    var path, tagName;
    if (routerData.length === 1) {
        if (!path && ~routerData[0].indexOf('http'))
            path = routerData[0];
        else
            tagName = routerData[0];
    }
    else if (routerData.length === 2) {
        path = routerData[1];
        tagName = routerData[0];
    }
    return { path: path, tagName: tagName };
};
export var forceArray = function (a) { return (isArray(a) ? a : [a]); };
export var unforceArray = function (a) { return (a.length === 0 ? null : a.length === 1 ? a[0] : a); };
export var extractRefs = function (target) {
    var parseRefs = function (selector, forceArray, refs) {
        return Array.from(target.querySelectorAll("*[".concat(selector, "]"))).reduce(function (memo, el) {
            var keys = el.getAttribute(selector);
            if (keys) {
                keys.split(/[ ,]+/).forEach(function (key) {
                    if (memo[key] || forceArray) {
                        if (!memo[key])
                            memo[key] = [];
                        else if (!isArray(memo[key]))
                            memo[key] = [memo[key]];
                        memo[key].push(el);
                    }
                    else {
                        memo[key] = el;
                    }
                });
            }
            return memo;
        }, refs);
    };
    var refs = parseRefs('data-ref', false, {});
    refs = parseRefs('data-refs', true, refs);
    return refs;
};
var Component = /** @class */ (function (_super) {
    __extends(Component, _super);
    function Component(el, options) {
        if (options === void 0) { options = {}; }
        var _this = _super.call(this) || this;
        Object.defineProperty(_this, "el", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "options", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "refs", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "modules", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "binded", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "context", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "parent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(_this, "routerLinks", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(_this, "flushed", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "onLinkClicked", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (event) {
                var link = event.currentTarget;
                if ((event.ctrlKey || event.metaKey) && link.tagName.toLowerCase() === 'a')
                    return false;
                event.preventDefault();
                event.stopPropagation();
                var routerData = getRouterData(link.getAttribute('data-' + link.storeName));
                var path = routerData.path || link.getAttribute('href');
                var tagName = routerData.tagName || link.storeName;
                if (tagName)
                    tag.openPopin(tagName, link.storeName);
                if (path) {
                    if (link.store.get() && link.store.get().includes(path.replace(getRoot(), '')))
                        link.store.set(null);
                    else
                        link.store.set(path.replace(getRoot(), ''));
                }
                // if (path) link.store.set(path.replace((lastResolvedRoute() as Match).url, ''))
            }
        });
        _this.el = el;
        _this.refs = {};
        _this.modules = {};
        _this.options = options || {};
        _this.context = _this.options.context || {};
        _this.parent = _this.options.parent || null;
        _this.bindRefs();
        _this.bindModules();
        _this.bindInternalRouter();
        defer(function () {
            _this.initialized();
        });
        return _this;
    }
    Object.defineProperty(Component.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.bindEvents(true);
        }
    });
    Object.defineProperty(Component.prototype, "refresh", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.bindEvents(false);
            this.bindRefs();
            this.bindModules();
            this.bindEvents(true);
            updatePageLinks();
            this.resize();
        }
    });
    /* BINDINGS EVENTS */
    Object.defineProperty(Component.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            if (add === void 0) { add = true; }
        }
    });
    /* BINDINGS REFS */
    Object.defineProperty(Component.prototype, "bindRefs", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (target) {
            if (!target)
                target = this.el;
            this.refs = extractRefs(target);
        }
    });
    /* BINDINGS MODULES */
    Object.defineProperty(Component.prototype, "bindModules", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            if (!this.modules)
                this.modules = {};
            var modulesMap = this.getModulesMap();
            if (this.modules) {
                // Clean older
                this.modules = mapValues(this.modules, function (modules, key) {
                    var _a;
                    return (_a = forceArray(modules)) === null || _a === void 0 ? void 0 : _a.filter(function (module) {
                        if (!module.el ||
                            (module.el.parentElement &&
                                document.body.contains(module.el) &&
                                !!modulesMap[key]))
                            return true;
                        module.flush();
                        return false;
                    });
                });
                for (var module_1 in this.modules) {
                    if (this.modules[module_1].length !== undefined && this.modules[module_1].length === 0)
                        delete this.modules[module_1];
                }
            }
            each(modulesMap, function (_a, key) {
                var _b, _c;
                var _d = __read(_a, 4), selector = _d[0], Module = _d[1], events = _d[2], options = _d[3];
                if (!Module)
                    return;
                var array = map((selector === 'self' ? [_this.el] : _this.el.querySelectorAll(selector)), function (el) {
                    var _a, _b;
                    // if (el.binded && ~el.binded.indexOf(key)) return
                    var alreadyExist = !!((_b = forceArray((_a = _this.modules) === null || _a === void 0 ? void 0 : _a[key])) === null || _b === void 0 ? void 0 : _b.find(function (m) { return (m === null || m === void 0 ? void 0 : m.el) === el; }));
                    if (alreadyExist)
                        return;
                    var m = new Module(el, _this.getModuleParams(el, Module));
                    m.parent = _this;
                    // if (!el.binded) el.binded = []
                    // el.binded.push(key)
                    return m;
                }).filter(Boolean);
                if ((_b = _this.modules) === null || _b === void 0 ? void 0 : _b[key]) {
                    // const current = forceArray(this.modules?.[key])?.filter((m: Component) => {
                    //   if (m.el.parentElement && document.body.contains(m.el)) return true
                    //   m.flush()
                    //   return false
                    // })
                    array.unshift.apply(array, __spreadArray([], __read((_c = _this.modules) === null || _c === void 0 ? void 0 : _c[key]), false));
                }
                if (array === null || array === void 0 ? void 0 : array.length)
                    _this.modules[key] = unforceArray(array);
            });
        }
    });
    Object.defineProperty(Component.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {};
        }
    });
    Object.defineProperty(Component.prototype, "getModuleParams", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (el, componentConstructor) {
            return {
                parent: this,
                context: this.context
            };
        }
    });
    /* BINDINGS ROUTERS */
    Object.defineProperty(Component.prototype, "bindInternalRouter", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            var types = ['panel', 'menu', 'search', 'popin'];
            this.routerLinks = [];
            types.forEach(function (type) {
                var _a;
                var links = [].slice.call(_this.el.querySelectorAll("[data-".concat(type, "]")));
                links.forEach(function (link) {
                    if (link.hasListenerAttached)
                        return;
                    link.addEventListener('click', _this.onLinkClicked);
                    link.hasListenerAttached = true;
                    link.store = store[type];
                    link.storeName = type;
                });
                (_a = _this.routerLinks).push.apply(_a, __spreadArray([], __read(links), false));
            });
        }
    });
    Object.defineProperty(Component.prototype, "unbindInternalRouter", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            this.routerLinks && this.routerLinks.forEach(function (link) { return link.removeEventListener('click', _this.onLinkClicked); });
        }
    });
    Object.defineProperty(Component.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.invoke('resize');
        }
    });
    Object.defineProperty(Component.prototype, "invoke", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (method) {
            each(this.modules, function (module) {
                var _a, _b;
                if (isArray(module))
                    invokeMap(module, method);
                else if (module)
                    (_b = (_a = module)[method]) === null || _b === void 0 ? void 0 : _b.call(_a);
            });
        }
    });
    Object.defineProperty(Component.prototype, "flush", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            // (this.el as any).binded = []
            this.flushed = true;
            this.bindEvents(false);
            this.unbindInternalRouter();
            this.invoke('flush');
        }
    });
    return Component;
}(TinyEmitter));
export default Component;
