var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import anime from 'animejs';
import { EASE_IN, EASE_IN_OUT, EASE_OUT, frameToMs } from 'helpers/easings';
import Component from 'navigation/component/Component';
var LoadingBar = /** @class */ (function (_super) {
    __extends(LoadingBar, _super);
    function LoadingBar() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        Object.defineProperty(_this, "tl", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: anime.timeline()
        });
        Object.defineProperty(_this, "begin", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                anime.remove(_this.refs.bar);
                anime.set(_this.refs.bar, { scaleX: 0, transformOrigin: 'left' });
                _this.refs.bar.style.transformOrigin = 'left';
                anime({
                    targets: _this.refs.bar,
                    scaleX: [0, 0.3],
                    opacity: [1, 1],
                    duration: frameToMs(50),
                    easing: EASE_OUT
                });
            }
        });
        Object.defineProperty(_this, "complete", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                anime.remove(_this.refs.bar);
                anime({
                    targets: _this.refs.bar,
                    scaleX: 1,
                    duration: frameToMs(50),
                    easing: EASE_IN,
                    complete: function () {
                        _this.refs.bar.style.transformOrigin = 'right';
                        anime({
                            targets: _this.refs.bar,
                            scaleX: 0,
                            duration: frameToMs(50),
                            easing: EASE_IN_OUT
                        });
                    }
                });
            }
        });
        return _this;
    }
    return LoadingBar;
}(Component));
export default LoadingBar;
