import MainPage from 'navigation/pages/MainPage'
import modulesMap from 'core/modulesMap'
import Map from 'components/map/Map'

class Store extends MainPage { 
 static pageName = 'Store'; 
  getModulesMap () {
    return {
      ...modulesMap,
      map: ['.stores__map-inner', Map]
    }
  }

  bindModules () {
    super.bindModules()

    const points = (this.refs.storeAddress || []).map((el, i) => {
      const p = el.getAttribute('data-coords').split(',').map(a => +a)
      const text = this.refs.addressName[i].innerHTML
      return [p, text]
    })
    this.modules.map.setMarkers(points)
  }
}

export default Store
