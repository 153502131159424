var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import modulesMap from 'core/modulesMap';
import Component from 'navigation/component/Component';
var Tabs = /** @class */ (function (_super) {
    __extends(Tabs, _super);
    function Tabs() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        Object.defineProperty(_this, "activeTab", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(_this, "handleTabClick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (link) {
                _this.activeTab = link;
                _this.refs.tabsLinks.forEach(function (link) { return link.classList.remove('active'); });
                link.classList.add('active');
                _this.emit('tabChange', link.dataset.tab);
                _this.updateTrack(link);
            }
        });
        Object.defineProperty(_this, "updateTrack", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (link) {
                // resize track to fit the link
                _this.refs.tabsTrack.style.width = "".concat(link === null || link === void 0 ? void 0 : link.offsetWidth, "px");
                // move track to the position of the link
                _this.refs.tabsTrack.style.left = "".concat(link === null || link === void 0 ? void 0 : link.offsetLeft, "px");
            }
        });
        return _this;
    }
    Object.defineProperty(Tabs.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
            // this.refs.tabsLinks[0].classList.add('active')
            this.activeTab = this.el.querySelector('.active');
            this.updateTrack(this.activeTab);
        }
    });
    Object.defineProperty(Tabs.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __assign({}, modulesMap);
        }
    });
    Object.defineProperty(Tabs.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            this.refs.tabsLinks.forEach(function (link) {
                link.addEventListener('click', function () { return _this.handleTabClick(link); });
            });
        }
    });
    Object.defineProperty(Tabs.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (this.activeTab) {
                this.emit('tabChange', this.activeTab.dataset.tab);
                this.updateTrack(this.activeTab);
            }
        }
    });
    return Tabs;
}(Component));
export default Tabs;
