import { getFormParameters } from 'components/form/Form';
import { extractInfoFromXhr } from 'navigation/page-manager/CustomPageManager';
import tag from './tag';
export var sendProductForm = function (_a) {
    var form = _a.form, action = _a.action, submitter = _a.submitter, isWishlist = _a.isWishlist, callback = _a.callback;
    var formEl = form.el;
    tag.triggerItem(form.el);
    form.quickValid()
        .then(function (valid) {
        if (!valid && !isWishlist)
            return;
        var formData = getFormParameters({ currentTarget: formEl, submitter: submitter }).formData;
        if (formData.get('article_2')) {
            if (formData.get('article'))
                formData.append('articles[]', formData.get('article'));
            formData.append('articles[]', formData.get('article_2'));
            formData.delete('article');
            formData.delete('article_2');
        }
        formEl.classList.add('submitting');
        var xhr = new XMLHttpRequest();
        xhr.withCredentials = true;
        xhr.open(form.method, action || form.action, true);
        xhr.responseType = 'document';
        xhr.onload = onXhrComplete(form, callback);
        xhr.send(formData);
    })
        .catch(function (error) {
        console.log('error', error);
    });
};
export var sendAddToCartForm = function () {
};
var onXhrComplete = function (form, callback) { return function (event) {
    form.el.classList.remove('submitting');
    var xhr = event.currentTarget;
    xhr.onload = null;
    var data = extractInfoFromXhr(xhr);
    callback({ data: data, xhr: xhr });
}; };
