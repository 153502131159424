var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { each } from 'lodash-es';
import Component from 'navigation/component/Component';
import AddressField from 'components/address-field/AddressField';
import Form from './Form';
var AddressForm = /** @class */ (function (_super) {
    __extends(AddressForm, _super);
    function AddressForm(el, options) {
        var _this = _super.call(this, el, options) || this;
        Object.defineProperty(_this, "prefix", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "stateInput", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "countryInput", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "statesByCountry", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "onCountryUpdate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                if (!_this.stateInput)
                    return;
                var country = _this.countryInput.value;
                var states = _this.statesByCountry[country];
                _this.el.classList.toggle('with-states', !!states);
                _this.el.classList.toggle('without-zipcode', country === 'HK');
                _this.stateInput.innerHTML = '';
                if (!states)
                    return;
                each(states, function (value, key) {
                    var option = document.createElement('option');
                    option.value = key.toString();
                    option.textContent = value;
                    option.selected = _this.stateInput.getAttribute('value') === key.toString();
                    _this.stateInput.appendChild(option);
                });
            }
        });
        Object.defineProperty(_this, "onAddressSelected", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (address) {
                _this.fillAddress(address);
            }
        });
        Object.defineProperty(_this, "fillAddress", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (address) {
                each(address, function (value, key) {
                    var name = _this.prefix ? "".concat(_this.prefix, "[").concat(key, "]") : key;
                    var input = _this.el.querySelector("[name=\"".concat(name, "\"]"));
                    if (!input)
                        return;
                    input.value = value;
                    if (key === 'country')
                        _this.onCountryUpdate();
                });
            }
        });
        _this.prefix = el.getAttribute('data-prefix');
        _this.parseForm();
        return _this;
    }
    Object.defineProperty(AddressForm.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
        }
    });
    Object.defineProperty(AddressForm.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                addressInput: ['input[autocomplete="street-address"]', AddressField],
                form: ['form', Form]
            };
        }
    });
    Object.defineProperty(AddressForm.prototype, "parseForm", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.stateInput = this.el.querySelector('select[autocomplete="address-level1"]');
            if (!this.stateInput)
                return;
            this.statesByCountry = JSON.parse(this.stateInput.getAttribute('data-states'));
            this.countryInput = this.el.querySelector('select[autocomplete="country"]');
            this.onCountryUpdate();
        }
    });
    Object.defineProperty(AddressForm.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            if (add === void 0) { add = true; }
            if (!this.stateInput)
                return;
            var method = add ? 'addEventListener' : 'removeEventListener';
            this.countryInput[method]('change', this.onCountryUpdate);
            this.modules.addressInput[add ? 'on' : 'off']('select', this.onAddressSelected);
        }
    });
    Object.defineProperty(AddressForm.prototype, "flush", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.flush.call(this);
        }
    });
    return AddressForm;
}(Component));
export default AddressForm;
