import InternalRouter from 'navigation/pages/InternalRouter'
import scroll from 'core/scroll'
import modulesMap from 'core/modulesMap'
import Map from 'components/map/Map'
import Tabs from 'components/tabs/Tabs'
import mqStore from 'store/mqStore'
import { forceArray } from 'navigation/component/Component'
import { bindMethod } from 'helpers/bind'

class Stores extends InternalRouter { 
 static pageName = 'Stores'; 
  getModulesMap () {
    return {
      ...modulesMap,
      map: ['.stores__map-inner', Map],
      tabs: ['.tabs', Tabs]
    }
  }

  initialized () {
  }

  getPageSelector () {
    return '.stores__list'
  }

  bindEvents (add = true) {
    super.bindEvents(...arguments)
    const methodSignal = add ? 'on' : 'off'
    const method = bindMethod(add)
    if (this.modules.nativeSelect) {
      this.modules.nativeSelect.forEach((el) => {
        el[methodSignal]('change', this.onSelect)
      })
    }
    if (this.modules.content) this.modules.content[methodSignal]('show', this.onPageShown)
    this.modules.map[methodSignal]('clicked', this.onMapClicked)
    this.modules.tabs[methodSignal]('tabChange', this.onTabChange)
    forceArray(this.refs.storeAddress).forEach((el, i) => {
      el[method]('mouseenter', () => this.onStoreHover(i))
      el[method]('mouseleave', () => this.onStoreHover(null))
    })
  }

  onStoreHover (i) {
    this.modules.map && this.modules.map.setActiveMarker(i)
  }

  onMapClicked = (i) => {
    if (!this.items || !this.items[i] || mqStore.tabletPortrait.get()) return
    const scrollTop = this.items[i].offsetTop
    scroll.scrollTo(scrollTop)
  }

  onTabChange = (i) => {
    this.refs.mapWrapper.classList.toggle('is-hidden', i === 'list')
    this.modules.map && this.modules.map.resize()
  }

  onPageShown = (page) => {
    page.bindRefs()
    this.bindRefs()
    this.items = (page.refs.storeAddress || [])
    this.setCountStores(this.items)
    const points = this.items.map((el, i) => {
      const p = el.getAttribute('data-coords').split(',').map(a => +a)
      const text = page.refs.storeAddress[i].innerHTML
      return [p, text]
    })

    this.modules.map.setMarkers(points)
    this.bindEvents()
  }

  onSelect = () => {
    this.modules.form && this.modules.form.submit()
  }

  setCountStores (items) {
    this.refs.storesCount.innerHTML = items.length
  }

  internalRouting (pathName, xhr, requestOptions = {}) {
    const cities = xhr.response.getElementById('select__city')
    this.el.querySelector('#select__city').innerHTML = cities.innerHTML
    super.internalRouting(...arguments)
  }
}

export default Stores
