var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import anime from 'animejs';
import Component, { forceArray } from 'navigation/component/Component';
import { bindEmitterMethod, bindMethod } from 'helpers/bind';
import { frameToMs } from 'helpers/easings';
import scroll from 'core/scroll';
import RowProductCard from 'sections/product/partials/RowProductCard';
import ImageZoomItem from './ImageZoomItem';
var ImageZoom = /** @class */ (function (_super) {
    __extends(ImageZoom, _super);
    function ImageZoom() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        Object.defineProperty(_this, "_opened", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "_current", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(_this, "onToggleNav", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (value) {
                _this.el.classList.toggle('nav-hidden', value);
            }
        });
        Object.defineProperty(_this, "onKeydown", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                switch (e.key) {
                    case 'ArrowLeft':
                        _this.prev();
                        break;
                    case 'ArrowRight':
                        _this.next();
                        break;
                    case 'Escape':
                        _this.close();
                        break;
                }
            }
        });
        return _this;
    }
    Object.defineProperty(ImageZoom.prototype, "opened", {
        get: function () {
            return this._opened;
        },
        set: function (value) {
            this._opened = value;
            this.el.classList.toggle('opened', value);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ImageZoom.prototype, "current", {
        get: function () {
            return this._current;
        },
        set: function (value) {
            var _a, _b;
            forceArray(this.modules.items).forEach(function (item) {
                item.reset();
            });
            var direction;
            if (value && this._current)
                direction = value > this._current ? 1 : -1;
            (_a = this.modules.items[this._current]) === null || _a === void 0 ? void 0 : _a.leave(direction);
            this._current = value;
            (_b = this.modules.items[this._current]) === null || _b === void 0 ? void 0 : _b.enter(direction);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ImageZoom.prototype, "getModuleParams", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (el, componentConstructor) {
            return __assign(__assign({}, _super.prototype.getModuleParams.call(this, el, componentConstructor)), { parent: this.parent });
        }
    });
    Object.defineProperty(ImageZoom.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                items: ['.image-zoom__item', ImageZoomItem],
                cards: ['.row-product-card', RowProductCard]
            };
        }
    });
    Object.defineProperty(ImageZoom.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            var method = bindMethod(add);
            var emitterMethod = bindEmitterMethod(add);
            this.refs.close[method]('click', function () { return _this.close(); });
            forceArray(this.modules.items).forEach(function (item) {
                item[emitterMethod]('item:enter', function (header) { return _this.updateHeader(header); });
            });
            this.refs.prev[method]('click', function () { return _this.prev(); });
            this.refs.next[method]('click', function () { return _this.next(); });
            this.refs.close[method]('click', function () { return _this.close(); });
            this.modules.items.forEach(function (item, i) {
                item[emitterMethod]('item:toggle-nav', function (value) { return _this.onToggleNav(value); });
            });
            document[method]('keydown', this.onKeydown);
        }
    });
    Object.defineProperty(ImageZoom.prototype, "updateHeader", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (header) {
            this.refs.header.textContent = header;
        }
    });
    Object.defineProperty(ImageZoom.prototype, "open", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (index) {
            this.current = index;
            this.opened = true;
            scroll.lock();
            anime({
                targets: [this.el],
                opacity: [0, 1],
                duration: frameToMs(25),
                easing: 'linear'
            });
        }
    });
    Object.defineProperty(ImageZoom.prototype, "next", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.current = (this.current + 1) % this.modules.items.length;
        }
    });
    Object.defineProperty(ImageZoom.prototype, "prev", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.current = (this.current - 1 + this.modules.items.length) % this.modules.items.length;
        }
    });
    Object.defineProperty(ImageZoom.prototype, "close", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            anime({
                targets: [this.el],
                opacity: [1, 0],
                duration: frameToMs(10),
                easing: 'linear',
                begin: function () {
                    scroll.unlock();
                },
                complete: function () {
                    _this.opened = false;
                    _this.current = null;
                }
            });
        }
    });
    return ImageZoom;
}(Component));
export default ImageZoom;
