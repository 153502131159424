var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Component from 'navigation/component/Component';
import { updatePageLinks } from 'core/router';
import { dimensions } from 'helpers/resize';
import math from 'helpers/math';
var InfiniteLine = /** @class */ (function (_super) {
    __extends(InfiniteLine, _super);
    function InfiniteLine(el, _a) {
        var _b = _a === void 0 ? {} : _a, _c = _b.vertical, vertical = _c === void 0 ? false : _c;
        var _this = _super.call(this, el) || this;
        Object.defineProperty(_this, "items", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "originalItems", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "originalSize", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(_this, "clonedItems", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(_this, "itemSizes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(_this, "vertical", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "totalSize", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(_this, "screenSize", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(_this, "_offset", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(_this, "total", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "onUpdate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                var inc = 0;
                var current = _this.offset;
                _this.items.forEach(function (item, index) {
                    var size = _this.itemSizes[index];
                    var offset = math.wrap(current + inc, -size, _this.totalSize - size);
                    var isVisible = (offset + size) > 0 && offset < _this.screenSize;
                    if (isVisible) {
                        item.style.transform = _this.vertical ? "translate3d(0,".concat(offset, "px,0)") : "translate3d(".concat(offset, "px,0,0)");
                        item.style.visibility = 'inherit';
                    }
                    else {
                        item.style.visibility = 'hidden';
                    }
                    inc += size;
                });
            }
        });
        Object.defineProperty(_this, "getMinSize", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                var maxItem = _this.itemSizes.reduce(function (acc, item) { return Math.max(acc, item); }, -1);
                return _this.totalSize - maxItem;
            }
        });
        Object.defineProperty(_this, "reset", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                _this.resize();
                _this.restructure();
                _this.onUpdate();
                _this.offset = 0;
            }
        });
        Object.defineProperty(_this, "disable", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                _this.el.classList.remove('infinite-line');
                _this.reset();
                _this.items.forEach(function (item, index) {
                    item.style.transform = '';
                    item.style.visibility = '';
                });
            }
        });
        Object.defineProperty(_this, "enable", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                _this.el.classList.add('infinite-line');
                _this.resize();
                _this.restructure();
                _this.onUpdate();
            }
        });
        _this.vertical = vertical;
        _this.el.classList.add('infinite-line');
        if (vertical)
            _this.el.classList.add('infinite-line--vertical');
        _this.originalItems = Array.from(_this.el.children);
        _this.items = Array.from(_this.el.children);
        _this.total = _this.items.length;
        _this.resize();
        _this.onUpdate();
        return _this;
    }
    Object.defineProperty(InfiniteLine.prototype, "offset", {
        get: function () {
            return this._offset;
        },
        set: function (value) {
            if (isNaN(value))
                return;
            this._offset = value % this.totalSize;
            this.onUpdate();
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(InfiniteLine.prototype, "itemSize", {
        get: function () {
            return this.itemSizes;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(InfiniteLine.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            var _a;
            _super.prototype.resize.call(this);
            this.itemSizes = (_a = this.items) === null || _a === void 0 ? void 0 : _a.map(function (item) {
                var bbox = item.getBoundingClientRect();
                return _this.vertical ? bbox.height : bbox.width;
            });
            var multiplier = this.items.length / this.originalItems.length;
            this.totalSize = this.itemSizes.reduce(function (acc, size) { return acc + size; }, 0);
            this.originalSize = this.totalSize / multiplier;
            this.el.style.setProperty('--total-size', "".concat(this.totalSize, "px"));
            this.screenSize = this.vertical ? dimensions().height : dimensions().width;
            this.restructure();
            this.el.style.setProperty('--screen-size', "".concat(this.screenSize, "px"));
            this.el.style.setProperty('--min-size', "".concat(this.getMinSize(), "px"));
        }
    });
    Object.defineProperty(InfiniteLine.prototype, "restructure", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            var _a;
            if (!((_a = this.items) === null || _a === void 0 ? void 0 : _a.length))
                return;
            var restructure = false;
            while (this.getMinSize() < this.screenSize && this.items.length < 50) {
                // const inc = this.items.length
                // const id = inc % this.originalItems.length
                // const clone = this.originalItems[id].cloneNode(true) as HTMLElement
                // this.el.appendChild(clone)
                // this.items.push(clone)
                // this.totalSize += this.vertical ? clone.offsetHeight : clone.offsetWidth
                this.originalItems.forEach(function (item) {
                    var clone = item.cloneNode(true);
                    clone.classList.add('infinite-line__clone');
                    _this.el.appendChild(clone);
                    _this.items.push(clone);
                    _this.clonedItems.push(clone);
                    _this.totalSize += _this.vertical ? clone.offsetHeight : clone.offsetWidth;
                });
                restructure = true;
            }
            if (restructure) {
                this.resize();
                updatePageLinks();
                this.emit('restructure');
            }
        }
    });
    Object.defineProperty(InfiniteLine.prototype, "flush", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.disable();
            this.clonedItems.forEach(function (item) { return item.remove(); });
            _super.prototype.flush.call(this);
        }
    });
    return InfiniteLine;
}(Component));
export default InfiniteLine;
