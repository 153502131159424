var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import browser from 'helpers/browser';
import { supportObserver } from 'helpers/observer';
import Component from 'navigation/component/Component';
var RevealList = /** @class */ (function (_super) {
    __extends(RevealList, _super);
    function RevealList(el, options) {
        var _this = _super.call(this, el, options) || this;
        Object.defineProperty(_this, "incDelay", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "stagger", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "children", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(_this, "observer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "offsetTops", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(_this, "observe", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                if (_this.observer)
                    _this.children.forEach(function (child) { return _this.observer.observe(child); });
                else
                    _this.onObserverUpdate(_this.children.map(function (child) { return ({ target: child, isIntersecting: true }); }));
            }
        });
        Object.defineProperty(_this, "onObserverUpdate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (entries) {
                var previousTop = -1;
                entries.forEach(function (_a, index) {
                    var target = _a.target, isIntersecting = _a.isIntersecting;
                    if (isIntersecting) {
                        var top_1 = target.topLine;
                        if (previousTop !== top_1)
                            _this.incDelay = 0;
                        var delay = _this.stagger * Math.min(_this.incDelay, 10);
                        target.style.setProperty('--stagger-delay', delay + 's');
                        _this.incDelay++;
                        previousTop = top_1;
                    }
                    browser.waitRepaint(function () {
                        target.classList.toggle('reveal-show', isIntersecting);
                    });
                });
            }
        });
        _this.prepareChildren();
        return _this;
    }
    Object.defineProperty(RevealList.prototype, "prepareChildren", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.incDelay = 0;
            this.stagger = (+this.el.getAttribute('data-delay')) || 0.12;
            this.children = this.getChildren();
            this.observer = supportObserver && (new IntersectionObserver(this.onObserverUpdate, {
                rootMargin: '1000% 0px -5% 0px',
                threshold: 0
            }));
        }
    });
    Object.defineProperty(RevealList.prototype, "reset", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            if (this.children && this.observer) {
                this.children.forEach(function (child) {
                    _this.observer.unobserve(child);
                });
            }
            this.children = this.getChildren();
            this.observe();
        }
    });
    Object.defineProperty(RevealList.prototype, "getChildren", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return Array.from(this.el.children);
        }
    });
    Object.defineProperty(RevealList.prototype, "show", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            setTimeout(this.observe, 600);
        }
    });
    Object.defineProperty(RevealList.prototype, "hide", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (this.observer)
                this.observer.disconnect();
        }
    });
    Object.defineProperty(RevealList.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.resize.call(this);
            this.offsetTops = [];
            this.children.forEach(function (child, index) {
                child.topLine = child.offsetTop;
            });
        }
    });
    Object.defineProperty(RevealList.prototype, "flush", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (this.observer)
                this.observer.disconnect();
            this.children = null;
            this.observer = null;
            _super.prototype.flush.call(this);
        }
    });
    return RevealList;
}(Component));
export default RevealList;
