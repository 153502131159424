var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import anime from 'animejs';
import scroll from 'core/scroll';
import { bindEmitterMethod, bindMethod } from 'helpers/bind';
import { EASE_IN, EASE_OUT, frameToMs } from 'helpers/easings';
import Component from 'navigation/component/Component';
import store from 'store';
import mqStore from 'store/mqStore';
var PageHeader = /** @class */ (function (_super) {
    __extends(PageHeader, _super);
    function PageHeader() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        Object.defineProperty(_this, "_descriptionOpened", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "_selectOpened", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "drawerAnims", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(_this, "motions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(_this, "isEllipsisActive", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                var temp = e.cloneNode(true);
                temp.style.position = 'fixed';
                temp.style.overflow = 'visible';
                temp.style.visibility = 'hidden';
                temp.style.width = e.parentElement.offsetWidth + 'px';
                temp.style.maxHeight = 'none';
                temp.style.webkitBoxOrient = 'horizontal';
                e.parentElement.appendChild(temp);
                try {
                    var fullHeight = temp.getBoundingClientRect().height;
                    var displayHeight = e.getBoundingClientRect().height;
                    return fullHeight > displayHeight;
                }
                finally {
                    temp.remove();
                }
            }
        });
        Object.defineProperty(_this, "onScroll", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                if (_this.refs.select && _this.selectOpened) {
                    store.drawer.set(null);
                    _this.toggleSelect();
                }
            }
        });
        Object.defineProperty(_this, "onLinkMouseEnter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                _this.refs.selectLinks.forEach(function (link) {
                    link.className = 'inactive';
                    if (link === e.target)
                        link.className = 'active';
                });
            }
        });
        Object.defineProperty(_this, "onLinkMouseLeave", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                _this.refs.selectLinks.forEach(function (link) {
                    link.classList.remove('active');
                    link.classList.remove('inactive');
                });
            }
        });
        Object.defineProperty(_this, "toggleSelect", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                if (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    store.drawer.set(_this.selectOpened ? null : 'page-header-select');
                }
                var duration = 300;
                var m, m1;
                m = anime({
                    targets: _this.refs.chevronUp,
                    translateY: _this.selectOpened ? ['-20%', 0] : [0, '-20%'],
                    opacity: _this.selectOpened ? [0, 1] : [1, 0],
                    duration: duration,
                    easing: EASE_OUT
                });
                m1 = anime({
                    targets: _this.refs.chevronDown,
                    translateY: _this.selectOpened ? [0, '20%'] : ['20%', 0],
                    opacity: _this.selectOpened ? [1, 0] : [0, 1],
                    duration: duration,
                    easing: EASE_OUT
                });
                _this.motions.push(m, m1);
            }
        });
        Object.defineProperty(_this, "toggleDescription", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                e.preventDefault();
                e.stopPropagation();
                _this.descriptionOpened = !_this.descriptionOpened;
            }
        });
        Object.defineProperty(_this, "onDrawerUpdate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                if (_this.selectOpened !== (store.drawer.get() === 'page-header-select')) {
                    _this.cancelMotions();
                    _this.selectOpened = store.drawer.get() === 'page-header-select';
                    if (!_this.selectOpened)
                        _this.toggleSelect();
                }
            }
        });
        Object.defineProperty(_this, "translateDrawer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (value) {
                var isTabletPortrait = mqStore.tabletPortrait.get();
                var m, m1;
                if (value) {
                    _this.refs.select.classList.toggle('opened', value);
                    if (isTabletPortrait) {
                        scroll.lock(true);
                        m = anime({
                            targets: _this.refs.select.children[0],
                            translateY: ['100%', 0],
                            duration: 600,
                            easing: EASE_OUT
                        });
                    }
                    else {
                        /* WRAPPER */
                        m = anime({
                            targets: _this.refs.select.children[0],
                            translateY: {
                                value: [-70, 0],
                                easing: EASE_OUT,
                                duration: frameToMs(50)
                            },
                            opacity: {
                                value: [0, 1],
                                easing: 'linear',
                                duration: frameToMs(10),
                                delay: frameToMs(10)
                            }
                        });
                        /* LINKS */
                        m1 = anime({
                            targets: _this.refs.selectLinks,
                            translateY: {
                                value: [-25, 0],
                                easing: EASE_OUT,
                                duration: frameToMs(40),
                                delay: anime.stagger(frameToMs(1), { start: frameToMs(10) })
                            },
                            opacity: {
                                value: [0, 1],
                                easing: 'linear',
                                duration: frameToMs(20),
                                delay: anime.stagger(frameToMs(1), { start: frameToMs(10) })
                            }
                        });
                    }
                }
                else {
                    if (isTabletPortrait) {
                        scroll.unlock(true);
                        m = anime({
                            targets: _this.refs.select.children[0],
                            translateY: '100%',
                            duration: frameToMs(50),
                            easing: EASE_IN,
                            complete: function () {
                                _this.refs.select.classList.toggle('opened', value);
                            }
                        });
                    }
                    else {
                        /* WRAPPER */
                        m = anime({
                            targets: _this.refs.select.children[0],
                            translateY: {
                                value: [0, -70],
                                easing: EASE_IN,
                                duration: frameToMs(50),
                                delay: frameToMs(10)
                            },
                            opacity: {
                                value: [1, 0],
                                easing: 'linear',
                                duration: frameToMs(10),
                                delay: frameToMs(20)
                            },
                            complete: function () {
                                _this.refs.select.children[0].style.transform = '';
                                _this.refs.selectLinks.forEach(function (link) {
                                    link.style.opacity = '';
                                    link.style.transform = '';
                                });
                                _this.refs.select.classList.toggle('opened', value);
                            }
                        });
                        // /* LINKS */
                        m1 = anime({
                            targets: _this.refs.selectLinks,
                            translateY: {
                                value: [0, -25],
                                easing: EASE_IN,
                                duration: frameToMs(40),
                                delay: anime.stagger(frameToMs(1), { direction: 'reverse' })
                            },
                            opacity: {
                                value: [1, 0],
                                easing: 'linear',
                                duration: frameToMs(20),
                                delay: anime.stagger(frameToMs(1), { direction: 'reverse' })
                            }
                        });
                    }
                }
                _this.motions.push(m, m1);
            }
        });
        Object.defineProperty(_this, "onPageTitleScroll", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                if (!_this.refs.pageHeaderTitle)
                    return;
                var limit = _this.refs.pageHeaderTitle.scrollWidth - _this.refs.pageHeaderTitle.offsetWidth;
                _this.refs.pageHeaderTitle.parentElement.classList.toggle('left', _this.refs.pageHeaderTitle.scrollLeft > 0);
                _this.refs.pageHeaderTitle.parentElement.classList.toggle('right', _this.refs.pageHeaderTitle.scrollLeft < limit);
            }
        });
        return _this;
    }
    Object.defineProperty(PageHeader.prototype, "descriptionOpened", {
        get: function () {
            return this._descriptionOpened;
        },
        set: function (value) {
            this._descriptionOpened = value;
            this.refs.description.classList.toggle('opened', value);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PageHeader.prototype, "selectOpened", {
        get: function () {
            return this._selectOpened;
        },
        set: function (value) {
            this._selectOpened = value;
            this.translateDrawer(value);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PageHeader.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
            this.updateTitleScrollPosition();
        }
    });
    Object.defineProperty(PageHeader.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            var _a;
            var method = bindMethod(add);
            var emitterMethod = bindEmitterMethod(add);
            window[method]('click', this.handleDocumentInteraction.bind(this));
            if (this.refs.selectToggle)
                this.refs.selectToggle[method]('click', this.toggleSelect);
            store.drawer.toggleListener(add, this.onDrawerUpdate);
            (_a = this.refs.selectLinks) === null || _a === void 0 ? void 0 : _a.forEach(function (link) {
                link[method]('mouseenter', _this.onLinkMouseEnter);
                link[method]('mouseleave', _this.onLinkMouseLeave);
            });
            if (this.refs.viewMore)
                this.refs.viewMore[method]('click', this.toggleDescription);
            if (this.refs.viewLess)
                this.refs.viewLess[method]('click', this.toggleDescription);
            if (this.refs.selectClose)
                this.refs.selectClose[method]('click', this.toggleSelect);
            if (this.refs.pageHeaderTitle)
                this.refs.pageHeaderTitle[method]('scroll', this.onPageTitleScroll);
            if (this.refs.pageHeaderTitle)
                this.refs.pageHeaderTitle[method]('touchstart', this.onPageTitleScroll);
            if (this.refs.pageHeaderTitle)
                this.refs.pageHeaderTitle[method]('touchmove', this.onPageTitleScroll);
            if (this.refs.pageHeaderTitle)
                this.refs.pageHeaderTitle[method]('touchend', this.onPageTitleScroll);
            scroll[emitterMethod](this.onScroll);
        }
    });
    Object.defineProperty(PageHeader.prototype, "handleDocumentInteraction", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (e) {
            if (!this.selectOpened)
                return;
            var target = e.target;
            if (!this.refs.select.contains(target) && this.selectOpened) {
                if (target.closest('.header-menu')) {
                    this.selectOpened = false;
                    store.drawer.set('menu');
                    this.toggleSelect();
                }
                else {
                    this.selectOpened = false;
                    store.drawer.set(null);
                    this.toggleSelect();
                }
            }
        }
    });
    Object.defineProperty(PageHeader.prototype, "updateSelectPosition", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (!this.refs.select || !this.refs.selectTitle)
                return;
            var selectTitleWidth = this.refs.selectTitle.offsetWidth;
            var gap = 8;
            var left = selectTitleWidth + gap;
            if (this.refs.select)
                this.refs.select.style.left = "".concat(left, "px");
        }
    });
    Object.defineProperty(PageHeader.prototype, "updateTitleScrollPosition", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (!this.refs.pageHeaderTitle)
                return;
            var limit = this.refs.pageHeaderTitle.scrollWidth - this.refs.pageHeaderTitle.offsetWidth;
            this.refs.pageHeaderTitle.scrollBy({ left: limit });
        }
    });
    Object.defineProperty(PageHeader.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.resize.call(this);
            // if mobile, we don't need to update the position
            var isTabletPortrait = mqStore.tabletPortrait.get();
            if (isTabletPortrait) {
                if (this.refs.select)
                    this.refs.select.style.left = '0';
                return;
            }
            this.updateSelectPosition();
            this.updateTitleScrollPosition();
            if (!isTabletPortrait && this.refs.descriptionInner) {
                if (this.isEllipsisActive(this.refs.descriptionInner))
                    this.refs.actions.classList.add('visible');
                else
                    this.refs.actions.classList.remove('visible');
            }
        }
    });
    Object.defineProperty(PageHeader.prototype, "cancelMotions", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.motions.forEach(function (m) {
                if (m)
                    m.pause();
            });
            this.motions = [];
        }
    });
    Object.defineProperty(PageHeader.prototype, "flush", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.flush.call(this);
        }
    });
    return PageHeader;
}(Component));
export default PageHeader;
