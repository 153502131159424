var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Drawer from 'components/drawer/Drawer';
import RowProductCard from './RowProductCard';
var ProductShopLook = /** @class */ (function (_super) {
    __extends(ProductShopLook, _super);
    function ProductShopLook(el, options) {
        var _this = _super.call(this, el, options) || this;
        _this.name = 'shop-the-look';
        _this.params = {
            desktop: {
                axis: 'X',
                direction: 'reversed'
            },
            mobile: {
                axis: 'Y',
                direction: 'reversed'
            }
        };
        return _this;
    }
    Object.defineProperty(ProductShopLook.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                rowProductCards: ['.row-product-card', RowProductCard]
            };
        }
    });
    return ProductShopLook;
}(Drawer));
export default ProductShopLook;
