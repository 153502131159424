var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Form from 'components/form/Form';
import { bindEmitterMethod } from 'helpers/bind';
import Category from 'sections/category/Category';
var SearchResults = /** @class */ (function (_super) {
    __extends(SearchResults, _super);
    function SearchResults(el, options, pageManager) {
        var _this = _super.call(this, el, options, pageManager) || this;
        Object.defineProperty(_this, "onFormSubmitted", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (query) {
                _this.modules.form.setSubmitted(false);
            }
        });
        _this.isSearch = true;
        return _this;
    }
    Object.defineProperty(SearchResults.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __assign(__assign({}, _super.prototype.getModulesMap.call(this)), { 
                // pageHeader: ['.page-header__bottom', PageHeader],
                form: ['.search-form', Form] });
        }
    });
    Object.defineProperty(SearchResults.prototype, "shouldRouteInternally", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (el) {
            // add offset
            var a = el.querySelector('.category__list');
            var b = this.modules.content.currentPage.el.querySelector('.category__list');
            if (!a || !b)
                return false;
            var searchA = (a.getAttribute('data-search') || '').split('?')[0];
            var searchB = (b.getAttribute('data-search') || '').split('?')[0];
            return searchA === searchB;
        }
    });
    Object.defineProperty(SearchResults.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            _super.prototype.bindEvents.call(this, add);
            var emitterMethod = bindEmitterMethod(add);
            this.modules.form[emitterMethod]('submitted', this.onFormSubmitted);
        }
    });
    return SearchResults;
}(Category));
export default SearchResults;
