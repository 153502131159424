import Bowser from 'bowser';
// const defaultUA = 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_2) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/79.0.3945.117 Safari/537.36'
var detect = {};
var test = function (ua) {
    var _bowser = Bowser.getParser(ua);
    var platform = _bowser.getPlatformType();
    var browser = _bowser.getBrowserName();
    var os = _bowser.getOSName();
    var model = (_bowser.getPlatform().model || '').toLowerCase();
    var hiddenIpad = os === 'macOS' && navigator.maxTouchPoints > 1;
    return {
        desktop: platform === 'desktop' && !hiddenIpad,
        mobile: platform === 'mobile',
        tablet: platform === 'tablet' || platform === 'tv' || hiddenIpad,
        opera: browser === 'Opera' || browser === 'Opera Coast',
        safari: browser === 'Safari',
        edge: browser === 'Microsoft Edge',
        ie: browser === 'Internet Explorer',
        chrome: browser === 'Chrome' || browser === 'Chromium',
        firefox: browser === 'Firefox',
        uc_browser: browser === 'UC Browser',
        android: os === 'Android',
        ios: os === 'iOS',
        windows: os === 'Windows',
        linux: os === 'Linux',
        macos: os === 'macOS',
        iphone: model === 'iphone',
        ipad: model === 'ipad',
        touch: platform !== 'desktop' && ('ontouchstart' in window || (navigator.maxTouchPoints > 0)),
        result: _bowser.parsedResult
    };
};
var initDetect = function (ua) {
    Object.assign(detect, test(ua));
    var addClass = function (valid, key) { return document.documentElement.classList.add((valid ? '' : 'no-') + key); };
    addClass(detect.touch, 'touch');
    addClass(detect.firefox, 'firefox');
    addClass(detect.safari, 'safari');
    addClass(detect.mobile, 'mobile');
    addClass(detect.tablet, 'tablet');
    addClass(detect.desktop, 'desktop');
};
initDetect(navigator.userAgent);
export default detect;
export { initDetect, test };
