var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import anime from 'animejs';
import Component from 'navigation/component/Component';
import mqStore from 'store/mqStore';
var CartReinsurance = /** @class */ (function (_super) {
    __extends(CartReinsurance, _super);
    function CartReinsurance() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        Object.defineProperty(_this, "previousPrevious", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(_this, "previous", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(_this, "_current", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 1
        });
        Object.defineProperty(_this, "next", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 2
        });
        Object.defineProperty(_this, "width", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(_this, "itemWidths", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        return _this;
    }
    Object.defineProperty(CartReinsurance.prototype, "current", {
        get: function () {
            return this._current;
        },
        set: function (value) {
            this.previousPrevious = this.previous;
            this.previous = this._current;
            this._current = value % this.refs.reinsuranceItem.length;
            this.next = (value + 1) % this.refs.reinsuranceItem.length;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CartReinsurance.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            _super.prototype.initialized.call(this);
            this.resize();
            var offset = mqStore.tabletPortrait.get() ? 0 : this.width * 0.1;
            this.refs.reinsuranceItem[0].classList.add('active');
            this.refs.reinsuranceItem[0].style.transform = "translateX(".concat(-this.itemWidths[0] + offset, "px)");
            this.refs.reinsuranceItem[1].classList.add('active');
            this.refs.reinsuranceItem[1].style.transform = "translateX(".concat(0.5 * this.width - this.itemWidths[1] / 2, "px)");
            this.refs.reinsuranceItem[2].classList.add('active');
            this.refs.reinsuranceItem[2].style.transform = "translateX(".concat(this.width - offset, "px)");
            setInterval(function () {
                _this.update();
            }, 2500);
        }
    });
    Object.defineProperty(CartReinsurance.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {};
        }
    });
    Object.defineProperty(CartReinsurance.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.resize.call(this);
            this.width = this.el.getBoundingClientRect().width;
            this.itemWidths = this.refs.reinsuranceItem.map(function (item) {
                return item.getBoundingClientRect().width;
            });
        }
    });
    Object.defineProperty(CartReinsurance.prototype, "update", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            this.current++;
            var offset = mqStore.tabletPortrait.get() ? 0 : this.width * 0.1;
            this.refs.reinsuranceItem[this.previous].classList.add('active');
            this.refs.reinsuranceItem[this.current].classList.add('active');
            this.refs.reinsuranceItem[this.next].classList.add('active');
            anime({
                targets: this.refs.reinsuranceItem[this.previousPrevious],
                translateX: -this.width,
                duration: 1000,
                easing: 'easeInOutQuad',
                complete: function (anim) {
                    _this.refs.reinsuranceItem[_this.previousPrevious].classList.remove('active');
                }
            });
            anime({
                targets: this.refs.reinsuranceItem[this.previous],
                translateX: -this.itemWidths[this.previous] + offset,
                duration: 1000,
                easing: 'easeInOutQuad'
            });
            anime({
                targets: this.refs.reinsuranceItem[this.current],
                translateX: 0.5 * this.width - this.itemWidths[this.current] / 2,
                duration: 1000,
                easing: 'easeInOutQuad'
            });
            var dist = this.width - offset + (0.5 * this.width - this.itemWidths[this.current] / 2);
            anime({
                targets: this.refs.reinsuranceItem[this.next],
                translateX: [dist, this.width - offset],
                duration: 1000,
                easing: 'easeInOutQuad'
            });
        }
    });
    return CartReinsurance;
}(Component));
export default CartReinsurance;
