var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Drawer from 'components/drawer/Drawer';
import { bindMethod } from 'helpers/bind';
import ProductCard from './ProductCard';
var ProductColorSelector = /** @class */ (function (_super) {
    __extends(ProductColorSelector, _super);
    function ProductColorSelector(el, options) {
        var _this = _super.call(this, el, options) || this;
        _this.name = 'color-selector';
        _this.params = {
            desktop: {
                axis: 'X',
                direction: 'reversed'
            },
            mobile: {
                axis: 'Y',
                direction: 'reversed'
            }
        };
        return _this;
    }
    Object.defineProperty(ProductColorSelector.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __assign(__assign({}, _super.prototype.getModulesMap.call(this)), { card: ['.product-card', ProductCard] });
        }
    });
    Object.defineProperty(ProductColorSelector.prototype, "onMouseEnter", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (event) {
            var target = event.currentTarget;
            for (var i = 0; i < this.refs.list.children.length; i++)
                this.refs.list.children[i].classList.remove('hovered');
            target.classList.add('hovered');
        }
    });
    Object.defineProperty(ProductColorSelector.prototype, "onMouseLeave", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (event) {
            for (var i = 0; i < this.refs.list.children.length; i++)
                this.refs.list.children[i].classList.remove('hovered');
        }
    });
    Object.defineProperty(ProductColorSelector.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
            this.el.style.setProperty('--color-header-height', "".concat(this.refs.colorSelectorHeader.offsetHeight, "px"));
        }
    });
    Object.defineProperty(ProductColorSelector.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            _super.prototype.bindEvents.call(this, add);
            var method = bindMethod(add);
            for (var i = 0; i < this.refs.list.children.length; i++) {
                this.refs.list.children[i][method]('mouseenter', function (e) { return _this.onMouseEnter(e); });
                this.refs.list.children[i][method]('mouseleave', function (e) { return _this.onMouseLeave(e); });
            }
        }
    });
    return ProductColorSelector;
}(Drawer));
export default ProductColorSelector;
