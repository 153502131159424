var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { getPath, getRoot } from 'core/router';
import { bindEmitterMethod } from 'helpers/bind';
import InternalPage from 'navigation/pages/InternalPage';
import ProductCard from 'sections/product/partials/ProductCard';
import { forceArray } from 'navigation/component/Component';
var Alerts = /** @class */ (function (_super) {
    __extends(Alerts, _super);
    function Alerts() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        Object.defineProperty(_this, "onToggleWishlist", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                var parent = _this.parent;
                var link = getPath();
                link = link.replace(getRoot(), '');
                if (e.xhr.response.querySelector('.page'))
                    parent.modules.content.inject(link, e.xhr);
                else
                    parent.modules.content.load(link);
            }
        });
        return _this;
    }
    Object.defineProperty(Alerts.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
        }
    });
    Object.defineProperty(Alerts.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                productCards: ['.product-card', ProductCard]
            };
        }
    });
    Object.defineProperty(Alerts.prototype, "getModuleParams", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (el, componentConstructor) {
            return {
                parent: this.parent,
                isWishlist: true
            };
        }
    });
    Object.defineProperty(Alerts.prototype, "bindModules", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.bindModules.call(this);
        }
    });
    Object.defineProperty(Alerts.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            var emitterMethod = bindEmitterMethod(add);
            forceArray(this.modules.productCards).forEach(function (card) {
                if (card)
                    card[emitterMethod]('wishlist:toggle', _this.onToggleWishlist);
            });
        }
    });
    return Alerts;
}(InternalPage));
export default Alerts;
