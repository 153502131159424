var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Component from 'navigation/component/Component';
import './AdaptableWrapper.scss';
var AdaptableWrapper = /** @class */ (function (_super) {
    __extends(AdaptableWrapper, _super);
    function AdaptableWrapper(el, params) {
        var _this = _super.call(this, el, params) || this;
        Object.defineProperty(_this, "resizeObserver", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "onResize", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (entries) {
                _this.el.style.setProperty('--adaptable-wrapper-height', "".concat(entries[0].contentRect.height, "px"));
            }
        });
        _this.resizeObserver = new ResizeObserver(_this.onResize);
        return _this;
    }
    Object.defineProperty(AdaptableWrapper.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _a, _b;
            if (add)
                this.resizeObserver.observe((_a = this.el) === null || _a === void 0 ? void 0 : _a.firstElementChild);
            else
                this.resizeObserver.unobserve((_b = this.el) === null || _b === void 0 ? void 0 : _b.firstElementChild);
        }
    });
    return AdaptableWrapper;
}(Component));
export default AdaptableWrapper;
