var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import anime from 'animejs';
import { durations } from 'components/menu/motion';
import scroll from 'core/scroll';
import { bindMethod } from 'helpers/bind';
import { EASE_OUT, frameToMs } from 'helpers/easings';
import Component from 'navigation/component/Component';
import mqStore from 'store/mqStore';
import store from 'store/store';
var Drawer = /** @class */ (function (_super) {
    __extends(Drawer, _super);
    function Drawer(el, options) {
        var _this = _super.call(this, el, options) || this;
        Object.defineProperty(_this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "params", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "previousIsTabletPortrait", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "motions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(_this, "opened", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "_translating", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "onKeyDown", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                if (store.drawer.get() === _this.name) {
                    if (e.key === 'Escape')
                        store.drawer.set(null);
                }
            }
        });
        Object.defineProperty(_this, "onDrawerChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (state) {
                if (state !== null) {
                    store.search.set(null);
                    store.panel.set(null);
                }
                if (state === _this.name) {
                    scroll.lock();
                    _this.toggle(true);
                }
                else if (store.previousDrawer.get() === _this.name || _this.opened) {
                    scroll.unlock();
                    _this.toggle(false);
                }
            }
        });
        Object.defineProperty(_this, "onClose", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                if (!store.persistantOverlay.get())
                    store.drawer.set(null);
            }
        });
        Object.defineProperty(_this, "onSwitchMq", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                var isTabletPortrait = mqStore.tabletPortrait.get();
                _this.el.classList.toggle('drawer__classic', _this.params[isTabletPortrait ? 'mobile' : 'desktop'].direction === 'classic');
                _this.el.classList.toggle('drawer__reversed', _this.params[isTabletPortrait ? 'mobile' : 'desktop'].direction === 'reversed');
                if (isTabletPortrait !== _this.previousIsTabletPortrait) {
                    var currentState = store.drawer.get() === _this.name;
                    _this.resetNonActiveAxis();
                    _this.previousIsTabletPortrait = isTabletPortrait;
                    _this.updatePanelTranslation(_this.getDirection(currentState)[1]);
                }
            }
        });
        _this.previousIsTabletPortrait = mqStore.tabletPortrait.get();
        return _this;
    }
    Object.defineProperty(Drawer.prototype, "translateAxis", {
        get: function () {
            var isTabletPortrait = mqStore.tabletPortrait.get();
            return isTabletPortrait ? "translate".concat(this.params.mobile.axis) : "translate".concat(this.params.desktop.axis);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Drawer.prototype, "translating", {
        get: function () {
            return this._translating;
        },
        set: function (value) {
            this._translating = value;
            this.el.classList.toggle('translating', value);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Drawer.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
            this.setInitialPanelState();
            this.onSwitchMq();
        }
    });
    Object.defineProperty(Drawer.prototype, "setInitialPanelState", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.updatePanelTranslation(this.getDirection(false)[1]);
        }
    });
    Object.defineProperty(Drawer.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var method = bindMethod(add);
            if (this.refs.close)
                this.refs.close[method]('click', this.onClose);
            store.drawer.toggleListener(add, this.onDrawerChange);
            mqStore.tabletPortrait.listen(this.onSwitchMq);
            window[method]('keydown', this.onKeyDown);
        }
    });
    Object.defineProperty(Drawer.prototype, "toggle", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (value, force) {
            var _a, _b;
            var _this = this;
            if (force === void 0) { force = false; }
            this.cancelMotions();
            var isTabletPortrait = mqStore.tabletPortrait.get();
            var axis = isTabletPortrait ? this.params.mobile.axis : this.params.desktop.axis;
            var resetAxis = isTabletPortrait ? "translate".concat(axis) : "translate".concat(axis);
            anime.set(this.el, (_a = {}, _a[resetAxis] = 0, _a));
            var m = anime((_b = {
                    targets: this.el
                },
                _b[this.translateAxis] = {
                    value: this.getDirection(value),
                    duration: value ? durations.FIRST_PANEL_TRANSLATE_IN : durations.FIRST_PANEL_TRANSLATE_OUT,
                    easing: EASE_OUT
                },
                _b.opacity = {
                    value: value ? [0, 1] : [1, 0],
                    duration: value ? durations.FIRST_PANEL_OPACITY_IN : durations.FIRST_PANEL_OPACITY_OUT,
                    easing: 'linear',
                    delay: value ? 0 : frameToMs(10)
                },
                _b.begin = function () {
                    _this.translating = true;
                    _this.onBeginToggle(value);
                },
                _b.complete = function () {
                    _this.translating = false;
                    _this.opened = value;
                    _this.onEndToggle(value);
                },
                _b));
            this.motions.push(m);
            if (axis === 'X') {
                var m1 = anime({
                    targets: this.el.children[0],
                    // [this.translateAxis]: {
                    //   value: value ? ['15%', '0'] : ['0', '15%'],
                    //   duration: value ? durations.FIRST_PANEL_TRANSLATE_IN : durations.FIRST_PANEL_TRANSLATE_OUT,
                    //   easing: EASE_OUT
                    // },
                    opacity: {
                        value: value ? [0, 1] : [1, 0],
                        duration: value ? durations.FIRST_PANEL_OPACITY_IN : durations.FIRST_PANEL_OPACITY_OUT,
                        easing: 'linear'
                        // delay: frameToMs(1) + frameToMs(15)
                    }
                });
                this.motions.push(m1);
            }
        }
    });
    Object.defineProperty(Drawer.prototype, "onBeginToggle", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (value) { }
    });
    Object.defineProperty(Drawer.prototype, "onEndToggle", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (value) { }
    });
    Object.defineProperty(Drawer.prototype, "updatePanelTranslation", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (translateValue) {
            var _a;
            anime.set(this.el, (_a = {}, _a[this.translateAxis] = translateValue, _a));
        }
    });
    Object.defineProperty(Drawer.prototype, "resetNonActiveAxis", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _a;
            anime.set(this.el, (_a = {}, _a[this.translateAxis] = 0, _a));
        }
    });
    Object.defineProperty(Drawer.prototype, "getDirection", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (opened) {
            var isTabletPortrait = mqStore.tabletPortrait.get();
            var direction = isTabletPortrait ? this.params.mobile.direction : this.params.desktop.direction;
            if (direction === 'classic')
                return opened ? ['-100%', '0'] : ['0', '-100%'];
            else
                return opened ? ['50%', '0'] : ['0', '100%'];
        }
    });
    Object.defineProperty(Drawer.prototype, "cancelMotions", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.motions.forEach(function (m) { return m.pause(); });
            this.motions = [];
        }
    });
    Object.defineProperty(Drawer.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.resize.call(this);
        }
    });
    return Drawer;
}(Component));
export default Drawer;
