import memoize from 'lodash-es/memoize';
var requestFullscreen = function (el) {
    if (el.requestFullscreen)
        el.requestFullscreen();
    // @ts-ignore
    else if (el.webkitRequestFullscreen)
        el.webkitRequestFullscreen();
    // @ts-ignore
    else if (el.mozRequestFullScreen)
        el.mozRequestFullScreen();
    // @ts-ignore
    else if (el.msRequestFullscreen)
        el.msRequestFullscreen();
    // @ts-ignore
    else if (el.webkitEnterFullScreen)
        el.webkitEnterFullScreen();
};
var exitFullscreen = function () {
    if (document.exitFullscreen)
        document.exitFullscreen();
    // @ts-ignore
    else if (document.webkitExitFullscreen)
        document.webkitExitFullscreen();
    // @ts-ignore
    else if (document.mozCancelFullScreen)
        document.mozCancelFullScreen();
    // @ts-ignore
    else if (document.msExitFullscreen)
        document.msExitFullscreen();
};
var mouseEvent = function (event) {
    if (event.originalEvent)
        event = event.originalEvent;
    if (event && event.touches && event.touches.length > 0)
        return event.touches[0];
    else if (event && event.changedTouches && event.changedTouches.length)
        return event.changedTouches[0];
    return event;
};
var webp = memoize(function () {
    var elem = document.createElement('canvas');
    if (elem.getContext && elem.getContext('2d')) {
        // was able or not to get WebP representation
        return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    }
    // very old browser like IE 8, canvas not supported
    return false;
});
var getParent = function (elem, selector) {
    for (; elem && elem !== document; elem = elem.parentNode)
        if (elem.matches(selector))
            return elem;
};
var isParent = function (elem, parent) {
    for (; elem && elem !== document; elem = elem.parentNode)
        if (elem === parent)
            return true;
    return false;
};
var resetElement = function (element) {
    var _a, _b;
    var clone = element.cloneNode(true);
    (_a = element.parentNode) === null || _a === void 0 ? void 0 : _a.insertBefore(clone, element);
    (_b = element.parentNode) === null || _b === void 0 ? void 0 : _b.removeChild(element);
    return clone;
};
var offsetTop = function (el) {
    var offset = el.offsetTop;
    if (el.offsetParent)
        offset += offsetTop(el.offsetParent);
    return offset;
};
var local = function () { return window && (parseInt(window.location.port) >= 8000); };
var waitRepaint = function (cb) {
    var o = {};
    o.raf = window.requestAnimationFrame(function () {
        o.raf = window.requestAnimationFrame(cb);
    });
    return o;
};
export default {
    requestFullscreen: requestFullscreen,
    exitFullscreen: exitFullscreen,
    offsetTop: offsetTop,
    resetElement: resetElement,
    mouseEvent: mouseEvent,
    getParent: getParent,
    isParent: isParent,
    webp: webp,
    local: local,
    waitRepaint: waitRepaint
};
