var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import Component from 'navigation/component/Component';
import browser from 'helpers/browser';
import mqStore from 'store/mqStore';
var Player = /** @class */ (function (_super) {
    __extends(Player, _super);
    function Player() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        Object.defineProperty(_this, "raf", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(_this, "leftOffset", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(_this, "onFullscreenClicked", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (event) {
                if (!document.fullscreenElement) {
                    // Mobile fullscreen not working correctly now
                    browser.requestFullscreen(mqStore.tabletPortrait.value ? _this.refs.video : _this.refs.videoWrapper);
                    // browser.requestFullscreen(this.refs.video)
                }
                else {
                    browser.exitFullscreen();
                }
            }
        });
        Object.defineProperty(_this, "onMuteClicked", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (event) {
                if (_this.refs.video.muted) {
                    _this.refs.video.muted = false;
                    _this.refs.muteVideo.innerHTML = 'SOUND OFF';
                }
                else {
                    _this.refs.video.muted = true;
                    _this.refs.muteVideo.innerHTML = 'SOUND ON';
                }
            }
        });
        Object.defineProperty(_this, "onPlayClicked", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (event) {
                if (_this.refs.video.paused)
                    _this.refs.video.play();
                else
                    _this.refs.video.pause();
            }
        });
        Object.defineProperty(_this, "onProgressClicked", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (event) {
                var pageX = event.pageX;
                var posX = pageX - _this.leftOffset, max = _this.refs.progressVideo.offsetWidth, nextTime = (posX / max);
                _this.refs.video.currentTime = nextTime * _this.refs.video.duration;
            }
        });
        Object.defineProperty(_this, "onVideoPlay", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                _this.el.classList.add('playing');
            }
        });
        Object.defineProperty(_this, "onVideoPause", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                _this.el.classList.remove('playing');
            }
        });
        Object.defineProperty(_this, "onVideoEnded", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (event) {
                _this.el.classList.remove('playing');
            }
        });
        Object.defineProperty(_this, "updateProgressBar", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                if (!_this.refs.video)
                    return;
                var ratio = (_this.refs.video.currentTime / _this.refs.video.duration);
                _this.refs.progressVideo.style.transform = "translateX(".concat((ratio - 1) * 100, "%)");
            }
        });
        Object.defineProperty(_this, "tick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                _this.raf = window.requestAnimationFrame(_this.tick);
                _this.updateProgressBar();
            }
        });
        return _this;
    }
    Object.defineProperty(Player.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.resize();
            _super.prototype.initialized.call(this);
        }
    });
    Object.defineProperty(Player.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            if (add === void 0) { add = true; }
            var m = add ? 'addEventListener' : 'removeEventListener';
            this.refs.fullscreenVideo[m]('click', this.onFullscreenClicked);
            this.refs.muteVideo[m]('click', this.onMuteClicked);
            this.refs.playVideo[m]('click', this.onPlayClicked);
            this.refs.progressVideoWrapper[m]('click', this.onProgressClicked);
            this.refs.video[m]('play', this.onVideoPlay);
            this.refs.video[m]('pause', this.onVideoPause);
            this.refs.video[m]('ended', this.onVideoEnded);
            if (add)
                this.tick();
            else
                window.cancelAnimationFrame(this.raf);
        }
    });
    Object.defineProperty(Player.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.resize.call(this);
            if (!this.refs)
                return;
            this.leftOffset = this.refs.progressVideoWrapper.getBoundingClientRect().left;
        }
    });
    return Player;
}(Component));
export default Player;
