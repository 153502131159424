var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Footer from 'components/footer/Footer';
import PageHeader from 'components/page-header/PageHeader';
import InternalRouter from 'navigation/pages/InternalRouter';
var Advices = /** @class */ (function (_super) {
    __extends(Advices, _super);
    function Advices() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Advices.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __assign(__assign({}, _super.prototype.getModulesMap.call(this)), { pageHeader: ['.page-header', PageHeader], footer: ['footer', Footer] });
        }
    });
    Object.defineProperty(Advices.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
        }
    });
    Object.defineProperty(Advices.prototype, "getPageSelector", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return '.advices__inner';
        }
    });
    Object.defineProperty(Advices, "pageName", {
        enumerable: true,
        configurable: true,
        writable: true,
        value: 'Advices'
    });
    return Advices;
}(InternalRouter));
export default Advices;
