var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import store from 'store';
import Component from '../component/Component';
var Page = /** @class */ (function (_super) {
    __extends(Page, _super);
    function Page(el, options, pageManager) {
        var _this = _super.call(this, el, options) || this;
        Object.defineProperty(_this, "pageManager", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: { shown: false, prepared: false, hidden: false }
        });
        _this.pageManager = pageManager;
        return _this;
    }
    Object.defineProperty(Page.prototype, "pageName", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () { return this.constructor.pageName || this.constructor.name || true; }
    });
    Object.defineProperty(Page.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
            if (this.pageManager) {
                var _a = this.el.dataset, category = _a.category, subcategory = _a.subcategory;
                store.category.set(category || null);
                store.subcategory.set(subcategory || null);
            }
        }
    });
    Object.defineProperty(Page.prototype, "injectScripts", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var scripts = __spreadArray([], __read(this.el.querySelectorAll('script[data-inject]')), false);
            scripts.forEach(function (script) {
                if (!script.src) {
                    var s2 = document.createElement('script');
                    s2.type = 'text/javascript';
                    if (script.innerHTML)
                        s2.appendChild(document.createTextNode(script.innerHTML));
                    script.parentNode.replaceChild(s2, script);
                }
            });
        }
    });
    Object.defineProperty(Page.prototype, "bindModules", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.bindModules.call(this);
        }
    });
    Object.defineProperty(Page.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {};
        }
    });
    Object.defineProperty(Page.prototype, "getModuleParams", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (el, componentConstructor) {
            var _a, _b;
            return __assign(__assign({}, _super.prototype.getModuleParams.call(this, el, componentConstructor)), { pageManager: this.pageManager || ((_b = (_a = this.options) === null || _a === void 0 ? void 0 : _a.parent) === null || _b === void 0 ? void 0 : _b.pageManager) });
        }
    });
    /* PREPARE */
    Object.defineProperty(Page.prototype, "askPreload", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (previousPage) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (previousPage)
                                this.injectScripts();
                            this.el.classList.add('preloading');
                            return [4 /*yield*/, this.preload(previousPage)];
                        case 1:
                            _a.sent();
                            this.state.prepared = true;
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(Page.prototype, "preload", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (previousPage) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    return [2 /*return*/];
                });
            });
        }
    });
    /* LOAD IMAGES */
    Object.defineProperty(Page.prototype, "preloadImages", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __awaiter(this, void 0, void 0, function () {
                var imagesToLoad;
                var _this = this;
                return __generator(this, function (_a) {
                    imagesToLoad = this.getImagesToLoad();
                    if (!imagesToLoad || !imagesToLoad.length)
                        return [2 /*return*/];
                    return [2 /*return*/, Promise.all(imagesToLoad.map(function (v, k) { return _this.loadImage(v, k, imagesToLoad.length); }))];
                });
            });
        }
    });
    Object.defineProperty(Page.prototype, "getImagesToLoad", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return Array.from(this.el.querySelectorAll('img:not([loading=lazy])[src]'));
        }
    });
    Object.defineProperty(Page.prototype, "loadImage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (img, i, total) {
            if (~img.src.indexOf('svg'))
                return Promise.resolve();
            return new Promise(function (resolve) {
                if (img.naturalWidth !== 0 || img.complete)
                    return resolve(img);
                img.onerror = img.onload = function () {
                    img.onerror = img.onload = null;
                    resolve(img);
                };
                img.src = img.currentSrc || img.src; // eslint-disable-line
            });
        }
    });
    /* SHOW */
    Object.defineProperty(Page.prototype, "askShow", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (previousPage, previousEl) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.el.classList.remove('preloading');
                            return [4 /*yield*/, this.show(previousPage, previousEl)];
                        case 1:
                            _a.sent();
                            this.state.shown = true;
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(Page.prototype, "show", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (previousPage, previousEl) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.el.style.opacity = '1';
                    this.invoke('show');
                    return [2 /*return*/];
                });
            });
        }
    });
    Object.defineProperty(Page.prototype, "transitionComplete", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.invoke('transitionComplete');
        }
    });
    /* HIDE */
    Object.defineProperty(Page.prototype, "askHide", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (nextPage, nextEl) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.invoke('hide');
                            return [4 /*yield*/, this.hide(nextPage, nextEl)];
                        case 1:
                            _a.sent();
                            this.state.hidden = true;
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    Object.defineProperty(Page.prototype, "hide", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (nextPage, nextEl) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    this.el.style.opacity = '0';
                    return [2 /*return*/];
                });
            });
        }
    });
    Object.defineProperty(Page.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.invoke('resize');
        }
    });
    Object.defineProperty(Page.prototype, "flush", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.invoke('flush');
            _super.prototype.flush.call(this);
        }
    });
    Object.defineProperty(Page, "pageName", {
        enumerable: true,
        configurable: true,
        writable: true,
        value: 'Page'
    });
    return Page;
}(Component));
export default Page;
