var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Component from 'navigation/component/Component';
import { bindEmitterMethod, bindMethod } from 'helpers/bind';
import InfiniteLine from 'components/infinite/InfiniteLine';
import detect from 'helpers/detect';
import browser from 'helpers/browser';
import Tempus from '@darkroom.engineering/tempus';
import scroll from 'core/scroll';
import math from 'helpers/math';
import { Lethargy } from 'lethargy-ts';
import anime from 'animejs';
var decay = 0.09;
var STATES = {
    INIT: 0,
    SCROLLING: 1,
    SWIPPING: 2
};
var getFrame = function (event) {
    if ((event === null || event === void 0 ? void 0 : event.touches) && (event === null || event === void 0 ? void 0 : event.touches[0]))
        event = event.touches[0];
    if ((event === null || event === void 0 ? void 0 : event.changedTouches) && (event === null || event === void 0 ? void 0 : event.changedTouches[0]))
        event = event.changedTouches[0];
    return { x: event.clientX, y: event.clientY, time: Date.now() };
};
var detectState = function (frame, first) {
    var distanceX = frame.x - first.x;
    var distanceY = frame.y - first.y;
    var angle = Math.atan2(distanceY, distanceX) * (180 / Math.PI);
    var distance = Math.sqrt(distanceX * distanceX + distanceY * distanceY);
    var range = 45;
    var gap = Math.abs(90 - Math.abs(angle));
    var horizontal = gap >= 90 - range;
    if (horizontal && distance > 20)
        return STATES.SWIPPING;
    else if (!horizontal && distance > 50)
        return STATES.SCROLLING;
    return STATES.INIT;
};
var lethargy = new Lethargy();
var SteppedManualInfiniteCarousel = /** @class */ (function (_super) {
    __extends(SteppedManualInfiniteCarousel, _super);
    function SteppedManualInfiniteCarousel(el, options) {
        var _this = _super.call(this, el) || this;
        Object.defineProperty(_this, "x", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(_this, "targetX", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(_this, "mouse", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(_this, "_enabled", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: true
        });
        Object.defineProperty(_this, "_canDrag", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: true
        });
        Object.defineProperty(_this, "stepInterval", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(_this, "_canClick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "trackAnim", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(_this, "wheeling", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "wheelTimeout", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(_this, "onRestructuration", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                _this.emit('restructure');
            }
        });
        Object.defineProperty(_this, "onClick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                var target = e === null || e === void 0 ? void 0 : e.target;
                // if (e && target.tagName !== 'A') e.preventDefault()
                if (!_this._canClick)
                    return;
                var position = _this.modules.infiniteLine.items.indexOf(target) % _this.modules.infiniteLine.originalItems.length;
                if (target.tagName === 'VIDEO')
                    return;
                _this.emit('click', position);
            }
        });
        Object.defineProperty(_this, "setInterval", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                _this.stepInterval = setInterval(function () {
                    _this.targetX -= _this.modules.infiniteLine.itemSizes[0];
                }, 6000);
            }
        });
        Object.defineProperty(_this, "onMouseWheel", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                if (lethargy.check(e) === false || _this.wheeling)
                    return;
                clearTimeout(_this.wheelTimeout);
                clearInterval(_this.stepInterval);
                _this.trackAnim.pause();
                _this.wheelTimeout = setTimeout(function () {
                    var rawProgress = _this.targetX / _this.modules.infiniteLine.itemSizes[0];
                    var progress = math.modulo(-rawProgress, _this.modules.infiniteLine.originalItems.length) / _this.modules.infiniteLine.originalItems.length;
                    _this.setInterval();
                    _this.setDurationLoop(progress);
                    _this.wheeling = false;
                }, 250);
                var isDraggingHorizontally = Math.abs(e.deltaX) > Math.abs(e.deltaY);
                if (!isDraggingHorizontally)
                    return;
                _this.wheeling = true;
                _this.targetX += Math.sign(-e.deltaX) * _this.modules.infiniteLine.itemSizes[0];
            }
        });
        Object.defineProperty(_this, "onMouseDown", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                var _a;
                e = browser.mouseEvent(e);
                (_a = e === null || e === void 0 ? void 0 : e.preventDefault) === null || _a === void 0 ? void 0 : _a.call(e);
                _this._canClick = false;
                _this.mouse = {
                    x: e.clientX,
                    y: e.clientY,
                    time: Date.now(),
                    start: _this.targetX
                };
                _this.el.classList.add('is-dragging');
                clearInterval(_this.stepInterval);
            }
        });
        Object.defineProperty(_this, "onMouseMove", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                var _a;
                if (!_this.mouse)
                    return;
                if (!_this.canDrag)
                    return;
                e = browser.mouseEvent(e);
                (_a = e === null || e === void 0 ? void 0 : e.preventDefault) === null || _a === void 0 ? void 0 : _a.call(e);
                if (!_this.mouse)
                    return;
                var frame = getFrame(e);
                var state = detectState(frame, _this.mouse);
                var multiplier = detect.touch ? 1.3 : 1;
                var delta = e.clientX - _this.mouse.x;
                _this.targetX = _this.mouse.start + delta * multiplier;
                if (state === STATES.SWIPPING)
                    scroll.lock();
                else
                    scroll.unlock();
            }
        });
        Object.defineProperty(_this, "onMouseUp", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                var _a, _b, _c, _d;
                if (!_this.mouse)
                    return;
                e = browser.mouseEvent(e);
                (_a = e === null || e === void 0 ? void 0 : e.preventDefault) === null || _a === void 0 ? void 0 : _a.call(e);
                _this.onMouseMove(e);
                if (_this.mouse) {
                    var distance = Math.abs(((_b = _this.mouse) === null || _b === void 0 ? void 0 : _b.x) - e.clientX) + Math.abs(((_c = _this.mouse) === null || _c === void 0 ? void 0 : _c.y) - e.clientY);
                    var duration = Date.now() - ((_d = _this.mouse) === null || _d === void 0 ? void 0 : _d.time);
                    var durationThreshold = detect.touch ? 200 : 400;
                    var distanceThreshold = 20;
                    if ((distance < distanceThreshold && duration < durationThreshold) || !_this.canDrag)
                        _this._canClick = true;
                    scroll.unlock();
                }
                _this.targetX = Math.round(_this.targetX / _this.modules.infiniteLine.itemSizes[0]) * _this.modules.infiniteLine.itemSizes[0];
                _this.mouse = null;
                _this.el.classList.remove('is-dragging');
                _this.setInterval();
            }
        });
        Object.defineProperty(_this, "onUpdate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                if (!_this.enabled)
                    return;
                var previousX = _this.x;
                _this.x += (_this.targetX - _this.x) * decay;
                if (_this.modules.infiniteLine)
                    _this.modules.infiniteLine.offset = _this.x;
                if (previousX !== _this.x)
                    _this.emit('update', _this.x);
            }
        });
        _this.bindModules();
        _this.el.classList.add('infinite-carousel');
        return _this;
    }
    Object.defineProperty(SteppedManualInfiniteCarousel.prototype, "enabled", {
        get: function () {
            return this._enabled;
        },
        set: function (value) {
            this._enabled = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SteppedManualInfiniteCarousel.prototype, "originalSize", {
        get: function () {
            var _a;
            return ((_a = this.modules.infiniteLine) === null || _a === void 0 ? void 0 : _a.originalSize) || 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SteppedManualInfiniteCarousel.prototype, "totalSize", {
        get: function () {
            var _a;
            return ((_a = this.modules.infiniteLine) === null || _a === void 0 ? void 0 : _a.totalSize) || 1;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SteppedManualInfiniteCarousel.prototype, "canDrag", {
        get: function () {
            return this._canDrag;
        },
        set: function (value) {
            this._canDrag = value;
            this.el.classList.toggle('drag-disabled', !value);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SteppedManualInfiniteCarousel.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                infiniteLine: ['self', InfiniteLine]
            };
        }
    });
    Object.defineProperty(SteppedManualInfiniteCarousel.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
            this.setInterval();
            this.setDurationLoop();
        }
    });
    Object.defineProperty(SteppedManualInfiniteCarousel.prototype, "setDurationLoop", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (start) {
            var _this = this;
            if (start === void 0) { start = 0; }
            var options = {
                currentTime: 0
            };
            this.trackAnim = anime({
                targets: options,
                currentTime: [start, 1],
                duration: (this.modules.infiniteLine.originalItems.length * 6000) * (start !== 0 ? 1 - start : 1),
                easing: 'linear',
                loop: true,
                loopComplete: function () {
                    _this.trackAnim.pause();
                    _this.setDurationLoop();
                },
                change: function () {
                    _this.emit('progress', options.currentTime);
                }
            });
        }
    });
    Object.defineProperty(SteppedManualInfiniteCarousel.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _a, _b, _c;
            var method = bindMethod(add);
            var emitterMethod = bindEmitterMethod(add);
            if (add)
                Tempus.add(this.onUpdate);
            else
                Tempus.remove(this.onUpdate);
            (_a = this.el) === null || _a === void 0 ? void 0 : _a[method]('wheel', this.onMouseWheel, { passive: true });
            (_b = this.el) === null || _b === void 0 ? void 0 : _b[method](detect.touch ? 'touchstart' : 'mousedown', this.onMouseDown);
            (_c = this.el) === null || _c === void 0 ? void 0 : _c[method](detect.touch ? 'touchmove' : 'mousemove', this.onMouseMove);
            window[method](detect.touch ? 'touchend' : 'mouseup', this.onMouseUp);
            this.el[method]('click', this.onClick);
            this.modules.infiniteLine[emitterMethod]('restructure', this.onRestructuration);
        }
    });
    Object.defineProperty(SteppedManualInfiniteCarousel.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.resize.call(this);
        }
    });
    Object.defineProperty(SteppedManualInfiniteCarousel.prototype, "flush", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.el.classList.remove('is-dragging');
            this.el.classList.remove('infinite-carousel');
            _super.prototype.flush.call(this);
        }
    });
    return SteppedManualInfiniteCarousel;
}(Component));
export default SteppedManualInfiniteCarousel;
