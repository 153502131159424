var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import modulesMap from 'core/modulesMap';
import MainPage from './MainPage';
var InternalPage = /** @class */ (function (_super) {
    __extends(InternalPage, _super);
    function InternalPage(el, options) {
        var _this = _super.call(this, el, options) || this;
        el.classList.add('internal-page');
        return _this;
    }
    Object.defineProperty(InternalPage.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return Object.assign({}, modulesMap);
        }
    });
    /* SHOW */
    Object.defineProperty(InternalPage.prototype, "askPreload", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (previousPage) {
            if (previousPage)
                this.el.style.opacity = '0';
            return _super.prototype.askPreload.apply(this, __spreadArray([], __read(arguments), false));
        }
    });
    Object.defineProperty(InternalPage.prototype, "preload", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (previousPage) {
            if (previousPage)
                return _super.prototype.preload.apply(this, __spreadArray([], __read(arguments), false));
            else
                return Promise.resolve();
        }
    });
    Object.defineProperty(InternalPage.prototype, "getChildrenShowDelay", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _a = this.options, previousMainPage = _a.previousMainPage, previousPage = _a.previousPage;
            return _super.prototype.getChildrenShowDelay.call(this) + ((!previousPage && !previousMainPage) ? 800 : 0);
        }
    });
    Object.defineProperty(InternalPage.prototype, "show", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (previousPage) {
            if (!previousPage) {
                this.el.style.opacity = '1';
                this.showChildren();
                return Promise.resolve();
            }
            else {
                return _super.prototype.show.call(this, previousPage);
            }
        }
    });
    return InternalPage;
}(MainPage));
export default InternalPage;
