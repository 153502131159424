var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import anime from 'animejs';
import { bindMethod } from 'helpers/bind';
import { EASE_IN, EASE_OUT } from 'helpers/easings';
import Component from 'navigation/component/Component';
import store from 'store';
import sizeStore from 'store/sizeStore';
var Notification = /** @class */ (function (_super) {
    __extends(Notification, _super);
    function Notification(el, options) {
        var _this = _super.call(this, el, options) || this;
        Object.defineProperty(_this, "animation", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(_this, "onToggleStoreLocatorClick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                store.drawer.set('webstore-switcher');
            }
        });
        Object.defineProperty(_this, "onMouseEnter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                if (_this.animation)
                    _this.animation.pause();
            }
        });
        Object.defineProperty(_this, "onMouseLeave", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                if (_this.animation)
                    _this.animation.play();
            }
        });
        _this.bindEvents();
        return _this;
    }
    Object.defineProperty(Notification.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
            this.resize();
            if (this.refs.notificationLinks.length > 1) {
                this.initAnimation();
            }
            else {
                anime.set(this.refs.notificationLinks[0], {
                    translateY: '-50%',
                    translateX: 0,
                    opacity: 1
                });
                this.refs.notificationLinks[0].classList.add('active');
            }
        }
    });
    Object.defineProperty(Notification.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            if (add === void 0) { add = true; }
            var method = bindMethod(add);
            this.refs.toggleStoreLocator.forEach(function (button) {
                button[method]('click', function (e) { return _this.onToggleStoreLocatorClick(e); });
            });
            this.el[method]('mouseenter', this.onMouseEnter);
            this.el[method]('mouseleave', this.onMouseLeave);
        }
    });
    Object.defineProperty(Notification.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var height = this.el.offsetHeight;
            sizeStore.notificationHeight.set(height);
        }
    });
    Object.defineProperty(Notification.prototype, "initAnimation", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var tl = anime.timeline({ loop: true, duration: this.refs.notificationLinks.length * 2000 });
            var duration = 800;
            var _loop_1 = function (i) {
                var link = this_1.refs.notificationLinks[i];
                tl.add({
                    targets: link,
                    translateY: ['-50%', '-50%'],
                    translateX: [-10, 0],
                    opacity: [0, 1],
                    duration: duration,
                    easing: EASE_OUT,
                    changeBegin: function () {
                        link.classList.add('active');
                    }
                }).add({
                    targets: link,
                    translateY: ['-50%', '-50%'],
                    translateX: [0, 10],
                    opacity: [1, 0],
                    duration: duration,
                    easing: EASE_IN,
                    pointerEvents: 'none',
                    changeBegin: function () {
                        setTimeout(function () {
                            link.classList.remove('active');
                        }, 500);
                    }
                }, '+=1500');
            };
            var this_1 = this;
            for (var i = 0; i < this.refs.notificationLinks.length; i++) {
                _loop_1(i);
            }
        }
    });
    return Notification;
}(Component));
export default Notification;
