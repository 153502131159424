import VirtualPageManager from 'navigation/page-manager/VirtualPageManager'
import Component from 'navigation/component/Component'
import scroll from 'core/scroll'
import PanelPage from 'navigation/pages/PanelPage'

import store from 'store/store'

class Popin extends Component {
  constructor (el) {
    super(...arguments)
    this.el = el
    this.pageManager = new VirtualPageManager(store.popin, el.querySelector('.popin__inner'), '.page', PanelPage)
  }

  bindEvents () {
    store.popin.listenAndStart(this.onUpdate)
    this.refs.closePopin.addEventListener('click', this.onClose)
  }

  onClose = () => {
    store.popin.set(null)
  }

  onUpdate = (path) => {
    const notEmpty = !!path || path === ''
    this.el.classList.toggle('opened', notEmpty)
    document.documentElement.classList.toggle('no-header', notEmpty)
    if (notEmpty) scroll.lock(true)
    else scroll.unlock(true)
  }

  resize () {
    this.pageManager.resize()
    super.resize()
  }
}

export default Popin
