import { zipObject } from 'lodash-es';
var _if = function (condition, params) { return new Promise(function (resolve, reject) {
    if (condition)
        resolve(params);
    else
        reject();
}); };
var wait = function (time) {
    if (time === void 0) { time = 0; }
    return new Promise(function (resolve) { return setTimeout(resolve, time); });
};
var pending = function (time) { return new Promise(function (resolve) { }); };
var nextTick = function () { return new Promise(function (resolve) { return requestAnimationFrame(function () { resolve(); }); }); };
var defer = function () {
    var object = {};
    object.promise = new Promise(function (resolve, reject) {
        object.resolve = resolve;
        object.reject = reject;
    });
    return object;
};
var object = function (data) {
    var keys = Object.keys(data);
    return Promise.all(Object.values(data))
        .then(function (values) {
        return zipObject(keys, values);
    });
};
export default {
    if: _if,
    pending: pending,
    wait: wait,
    defer: defer,
    object: object,
    nextTick: nextTick
};
