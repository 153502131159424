var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import DragController from 'components/DragController/DragController';
import { bindEmitterMethod, bindMethod } from 'helpers/bind';
import Component from 'navigation/component/Component';
var HomeProducts = /** @class */ (function (_super) {
    __extends(HomeProducts, _super);
    function HomeProducts() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        Object.defineProperty(_this, "delta", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(_this, "mouse", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                x: 0,
                prevX: 0,
                startTime: null
            }
        });
        Object.defineProperty(_this, "_grabbing", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "_moving", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "_native", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "_currentItem", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(_this, "_switching", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "fraction", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(_this, "animation", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        return _this;
    }
    Object.defineProperty(HomeProducts.prototype, "switching", {
        get: function () {
            return this._switching;
        },
        set: function (value) {
            this._switching = value;
            this.refs.slider.classList.toggle('switching', value);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(HomeProducts.prototype, "grabbing", {
        get: function () {
            return this._grabbing;
        },
        set: function (value) {
            this._grabbing = value;
            if (value)
                this.refs.slider.classList.add('grabbing');
            else
                this.refs.slider.classList.remove('grabbing');
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(HomeProducts.prototype, "currentItem", {
        get: function () {
            return this._currentItem;
        },
        set: function (value) {
            this._currentItem = value;
            if (this.refs.prev)
                this.refs.prev.classList.toggle('disabled', value === 0);
            if (this.refs.next)
                this.refs.next.classList.toggle('disabled', value === this.refs.items.length - 3);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(HomeProducts.prototype, "moving", {
        get: function () {
            return this._moving;
        },
        set: function (value) {
            this._moving = value;
            if (value) {
                this.refs.slider.classList.add('moving');
            }
            else {
                this.refs.slider.classList.remove('moving');
                this._grabbing = false;
                this._moving = false;
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(HomeProducts.prototype, "native", {
        get: function () {
            return this._native;
        },
        set: function (value) {
            if (value) {
                this.delta = 0;
                this.refs.items.forEach(function (item) {
                    item.style.transform = '';
                });
                this.el.classList.add('native');
                this._native = true;
            }
            else {
                this.el.classList.remove('native');
                this._native = false;
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(HomeProducts.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                dragController: ['.home-products__inner', DragController]
            };
        }
    });
    Object.defineProperty(HomeProducts.prototype, "getModuleParams", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (el, componentConstructor) {
            return {
                itemsVisible: 3
            };
        }
    });
    Object.defineProperty(HomeProducts.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
        }
    });
    Object.defineProperty(HomeProducts.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            var emitterMethod = bindEmitterMethod(add);
            var method = bindMethod(add);
            this.modules.dragController[emitterMethod]('move', function () { return _this.onMove(); });
            this.refs.prev[method]('click', function () { return _this.modules.dragController.navigate(-1); });
            this.refs.next[method]('click', function () { return _this.modules.dragController.navigate(1); });
        }
    });
    Object.defineProperty(HomeProducts.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.resize.call(this);
        }
    });
    Object.defineProperty(HomeProducts.prototype, "onMove", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.refs.prev.classList.toggle('disabled', this.modules.dragController.progress === 0);
            this.refs.next.classList.toggle('disabled', this.modules.dragController.progress === 1);
            this.refs.track.style.transform = "translateX(".concat(this.modules.dragController.progress * (100) * (this.modules.dragController.refs.draggableItems.length - 1), "%)");
        }
    });
    Object.defineProperty(HomeProducts.prototype, "navigate", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (direction) { }
    });
    return HomeProducts;
}(Component));
export default HomeProducts;
