var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import anime from 'animejs';
import Form from 'components/form/Form';
import { sendProductForm } from 'core/form';
import { bindMethod } from 'helpers/bind';
import { EASE_IN_OUT } from 'helpers/easings';
import PanelPage from 'navigation/pages/PanelPage';
import store from 'store';
var Quickbuy = /** @class */ (function (_super) {
    __extends(Quickbuy, _super);
    function Quickbuy() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        Object.defineProperty(_this, "onAddToCart", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) { return __awaiter(_this, void 0, void 0, function () {
                var valid, target, cssVariables, waiter, btns;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, this.modules.quickbuyForm.quickValid()];
                        case 1:
                            valid = _a.sent();
                            if (!valid)
                                return [2 /*return*/];
                            target = e.currentTarget;
                            cssVariables = {
                                loadingProgress: 0
                            };
                            waiter = [];
                            btns = [this.refs.addToCart];
                            btns.forEach(function (el) {
                                el.classList.add('loading');
                                var span = el.querySelector('.button__text-inner').children[0];
                                span.innerText = el.dataset.loading;
                                waiter.push(anime({
                                    targets: cssVariables,
                                    loadingProgress: [0, 1],
                                    duration: 2000,
                                    easing: EASE_IN_OUT,
                                    begin: function () {
                                        el.setAttribute('disabled', 'true');
                                    },
                                    update: function () {
                                        el.style.setProperty('--loading-progress', "".concat(cssVariables.loadingProgress));
                                    },
                                    complete: function () {
                                        el.removeAttribute('disabled');
                                    }
                                }).finished);
                            });
                            return [4 /*yield*/, Promise.all(waiter)];
                        case 2:
                            _a.sent();
                            sendProductForm({
                                form: this.modules.quickbuyForm,
                                submitter: target,
                                callback: function (_a) {
                                    var xhr = _a.xhr, data = _a.data;
                                    btns.forEach(function (el) {
                                        var span = el.querySelector('.button__text-inner').children[0];
                                        var hoverText = span.parentElement.dataset.hover;
                                        span.innerText = el.dataset.loading;
                                        el.classList.remove('loading');
                                        if (data.error === null)
                                            span.parentElement.dataset.hover = el.dataset.complete;
                                        else
                                            span.parentElement.dataset.hover = el.dataset.error;
                                        el.classList.add('complete');
                                        setTimeout(function () {
                                            // el.classList.add('no-transition')
                                            el.classList.remove('complete');
                                            // el.classList.remove('no-transition')
                                            span.parentElement.dataset.hover = hoverText;
                                            span.innerText = el.dataset.text;
                                        }, 1000);
                                    });
                                    setTimeout(function () {
                                        store.panel.set(null);
                                    }, 500);
                                }
                            });
                            return [2 /*return*/];
                    }
                });
            }); }
        });
        return _this;
    }
    Object.defineProperty(Quickbuy.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
        }
    });
    Object.defineProperty(Quickbuy.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __assign(__assign({}, _super.prototype.getModulesMap.call(this)), { quickbuyForm: ['.form.quickbuy_form', Form] });
        }
    });
    Object.defineProperty(Quickbuy.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.resize.call(this);
            this.el.style.setProperty('--size-header-height', "".concat(this.refs.quickBuyHeader.offsetHeight, "px"));
            this.el.style.setProperty('--size-confirm-height', "".concat(this.refs.quickBuyConfirm.offsetHeight, "px"));
        }
    });
    Object.defineProperty(Quickbuy.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            _super.prototype.bindEvents.call(this, add);
            var method = bindMethod(add);
            this.refs.addToCart[method]('click', this.onAddToCart);
        }
    });
    return Quickbuy;
}(PanelPage));
export default Quickbuy;
