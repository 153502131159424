var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { bindMethod } from 'helpers/bind';
import { clamp } from 'lodash-es';
import Component from 'navigation/component/Component';
var SizeGuideTable = /** @class */ (function (_super) {
    __extends(SizeGuideTable, _super);
    function SizeGuideTable() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        Object.defineProperty(_this, "track", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "width", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "delta", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(_this, "mouse", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                x: 0,
                prevX: 0,
                startTime: null
            }
        });
        Object.defineProperty(_this, "_grabbing", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "_moving", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "_scrollable", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "buildScrollbar", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                if (_this.el.scrollWidth < _this.el.clientWidth)
                    return;
                var wrapper = document.createElement('div');
                wrapper.classList.add('sizes__scrollbar');
                _this.track = document.createElement('button');
                _this.track.setAttribute('type', 'button');
                _this.track.classList.add('sizes__track');
                _this.track.style.width = _this.el.clientWidth / _this.el.scrollWidth * 100 + '%';
                _this.width = _this.el.clientWidth / _this.el.scrollWidth * 100;
                wrapper.appendChild(_this.track);
                _this.el.after(wrapper);
                _this.checkScrollable();
            }
        });
        Object.defineProperty(_this, "checkScrollable", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                _this.scrollable = _this.el.scrollWidth > _this.el.clientWidth;
            }
        });
        Object.defineProperty(_this, "onWheel", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                if (Math.abs(e.deltaX) > Math.abs(e.deltaY)) {
                    e.preventDefault();
                    e.stopPropagation();
                }
                _this.move(e.deltaX);
            }
        });
        Object.defineProperty(_this, "onScroll", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                _this.width = _this.el.clientWidth / _this.el.scrollWidth * 100;
                var progress = _this.el.scrollLeft / (_this.el.scrollWidth - _this.el.clientWidth);
                _this.track.style.left = "".concat(progress * (100 - _this.width), "%");
            }
        });
        Object.defineProperty(_this, "onTouchStart", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                _this.mouse = {
                    x: e.touches[0].clientX,
                    prevX: e.touches[0].clientX,
                    startTime: Date.now()
                };
                _this.grabbing = true;
            }
        });
        Object.defineProperty(_this, "onTouchMove", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                if (_this.grabbing) {
                    if (!_this.moving)
                        _this.moving = true;
                    var target = e.target;
                    var direction = -1;
                    if (target.classList.contains('sizes__track'))
                        direction = 1;
                    var delta = e.touches[0].clientX - _this.mouse.x;
                    _this.move(delta * direction);
                    _this.mouse.prevX = _this.mouse.x;
                    _this.mouse.x = e.touches[0].clientX;
                }
            }
        });
        Object.defineProperty(_this, "onTouchEnd", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                _this.grabbing = false;
                _this.moving = false;
            }
        });
        Object.defineProperty(_this, "onTrackMouseDown", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                _this.mouse = {
                    x: e.clientX,
                    prevX: e.clientX,
                    startTime: Date.now()
                };
                _this.grabbing = true;
            }
        });
        Object.defineProperty(_this, "onTrackMouseMove", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                if (_this.grabbing) {
                    if (!_this.moving)
                        _this.moving = true;
                    var delta = e.clientX - _this.mouse.x;
                    _this.move(delta);
                    _this.mouse.prevX = _this.mouse.x;
                    _this.mouse.x = e.clientX;
                }
            }
        });
        Object.defineProperty(_this, "onTrackMouseUp", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                if (_this.mouse.startTime) {
                    if (_this.mouse.startTime + 100 < Date.now())
                        e.preventDefault();
                    _this.grabbing = false;
                    _this.moving = false;
                }
            }
        });
        Object.defineProperty(_this, "move", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (delta) {
                var w = _this.el.scrollWidth - _this.el.clientWidth;
                _this.delta = clamp(_this.delta - delta, -w, 0);
                _this.el.scrollTo({ left: -_this.delta });
                _this.track.style.left = "".concat(-_this.delta / w * (100 - _this.width), "%");
            }
        });
        return _this;
    }
    Object.defineProperty(SizeGuideTable.prototype, "scrollable", {
        get: function () {
            return this._scrollable;
        },
        set: function (value) {
            this._scrollable = value;
            this.track.parentElement.classList.toggle('scrollable', value);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SizeGuideTable.prototype, "grabbing", {
        get: function () {
            return this._grabbing;
        },
        set: function (value) {
            this._grabbing = value;
            if (value) {
                document.body.style.cursor = 'grabbing';
                this.track.classList.add('grabbing');
            }
            else {
                document.body.style.cursor = 'default';
                this.track.classList.remove('grabbing');
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SizeGuideTable.prototype, "moving", {
        get: function () {
            return this._moving;
        },
        set: function (value) {
            this._moving = value;
            if (value) {
                this.el.classList.add('moving');
            }
            else {
                this.el.classList.remove('moving');
                this._grabbing = false;
                this._moving = false;
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SizeGuideTable.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.buildScrollbar();
            _super.prototype.initialized.call(this);
        }
    });
    Object.defineProperty(SizeGuideTable.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var method = bindMethod(add);
            this.track[method]('mousedown', this.onTrackMouseDown);
            window[method]('mousemove', this.onTrackMouseMove);
            window[method]('mouseup', this.onTrackMouseUp);
            this.track[method]('touchstart', this.onTouchStart);
            window[method]('touchmove', this.onTouchMove);
            window[method]('touchend', this.onTouchEnd);
            this.el[method]('touchstart', this.onTouchStart);
            this.el[method]('wheel', this.onWheel);
        }
    });
    Object.defineProperty(SizeGuideTable.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.checkScrollable();
            this.track.style.width = this.el.clientWidth / this.el.scrollWidth * 100 + '%';
            this.onScroll();
            _super.prototype.resize.call(this);
        }
    });
    return SizeGuideTable;
}(Component));
export default SizeGuideTable;
