var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Drawer from 'components/drawer/Drawer';
import { frameToMs } from 'helpers/easings';
import store from 'store';
import mqStore from 'store/mqStore';
var ProductInfos = /** @class */ (function (_super) {
    __extends(ProductInfos, _super);
    function ProductInfos(el, options) {
        var _this = _super.call(this, el, options) || this;
        Object.defineProperty(_this, "onOpenInfosPanel", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (isOpen) {
                _this.emit('open:info');
                var accordion = store.infosAccordion.get();
                if (accordion) {
                    var input = _this.refs[accordion][0];
                    if (isOpen)
                        input.checked = isOpen;
                    setTimeout(function () {
                        if (!isOpen) {
                            store.infosAccordion.set(null);
                            _this.el.querySelectorAll('.accordion__checkbox').forEach(function (input) { input.checked = false; });
                        }
                    }, frameToMs(50));
                }
            }
        });
        _this.name = 'infos';
        _this.params = {
            desktop: {
                axis: 'X',
                direction: 'reversed'
            },
            mobile: {
                axis: 'Y',
                direction: 'reversed'
            }
        };
        return _this;
    }
    Object.defineProperty(ProductInfos.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            if (add === void 0) { add = true; }
            _super.prototype.bindEvents.call(this, add);
        }
    });
    Object.defineProperty(ProductInfos.prototype, "onBeginToggle", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (value) {
            if (value) {
                var accordion = store.infosAccordion.get();
                var input = this.refs[accordion][0];
                input.checked = value;
            }
        }
    });
    Object.defineProperty(ProductInfos.prototype, "onEndToggle", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (value) {
            if (!value) {
                var input = this.el.querySelectorAll('input');
                input.forEach(function (input) {
                    input.checked = false;
                });
            }
        }
    });
    Object.defineProperty(ProductInfos.prototype, "toggle", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (value) {
            _super.prototype.toggle.call(this, value);
        }
    });
    Object.defineProperty(ProductInfos.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.resize.call(this);
            this.el.style.setProperty('--product-infos-right-height', "".concat(this.refs.productInfosRight.clientHeight, "px"));
            if (mqStore.tabletPortrait.get())
                this.refs.informations[0].checked = true;
        }
    });
    return ProductInfos;
}(Drawer));
export default ProductInfos;
