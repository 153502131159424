var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { defer } from 'lodash-es';
import anime from 'animejs';
import scroll from 'core/scroll';
import { frameToMs } from 'helpers/easings';
import Page from './Page';
var MainPage = /** @class */ (function (_super) {
    __extends(MainPage, _super);
    function MainPage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /* LOAD */
    Object.defineProperty(MainPage.prototype, "askPreload", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (previousPage) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            this.el.classList.add('appearing');
                            return [4 /*yield*/, _super.prototype.askPreload.call(this, previousPage)];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            });
        }
    });
    /* SHOW */
    Object.defineProperty(MainPage.prototype, "show", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (previousPage) {
            var _this = this;
            this.resize();
            scroll.lock();
            var delay = previousPage ? 10 : 300;
            return Promise.resolve()
                .then(function () { return anime({
                targets: _this.el,
                opacity: {
                    value: [0, 1],
                    duration: frameToMs(15),
                    delay: delay,
                    easing: 'linear'
                },
                delay: delay,
                changeBegin: function () { return _this.showChildren(); }
            }).finished; })
                .then(function () {
                scroll.unlock();
                _this.el.style.transform = '';
                defer(function () { return _this.resize(); });
            });
        }
    });
    Object.defineProperty(MainPage.prototype, "navigateToHash", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _a;
            if (window.location.hash)
                (_a = document.querySelector(window.location.hash)) === null || _a === void 0 ? void 0 : _a.scrollIntoView({ behavior: 'smooth' });
        }
    });
    Object.defineProperty(MainPage.prototype, "getChildrenShowDelay", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return 1;
        }
    });
    Object.defineProperty(MainPage.prototype, "showChildren", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            setTimeout(function () { return _this.invoke('show'); }, this.getChildrenShowDelay());
        }
    });
    Object.defineProperty(MainPage.prototype, "transitionComplete", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.el.classList.remove('appearing');
            _super.prototype.transitionComplete.call(this);
        }
    });
    /* HIDE */
    Object.defineProperty(MainPage.prototype, "hide", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (nextPage) {
            this.el.classList.add('disappearing');
            return anime({
                targets: this.el,
                opacity: {
                    value: [1, 0],
                    easing: 'linear',
                    duration: frameToMs(15)
                }
                // delay: 100
            }).finished.then(function () {
            });
        }
    });
    return MainPage;
}(Page));
export default MainPage;
