var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import TabsPage from 'navigation/pages/TabsPage';
var Legals = /** @class */ (function (_super) {
    __extends(Legals, _super);
    function Legals() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Legals.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
        }
    });
    Object.defineProperty(Legals, "pageName", {
        enumerable: true,
        configurable: true,
        writable: true,
        value: 'Legals'
    });
    return Legals;
}(TabsPage));
export default Legals;
