var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import Navigo from 'navigo';
import Config from 'core/config';
import store from 'store/store';
import tag from './tag';
var buildRoot = function (lang) { return '/'; };
// const buildRoot = (lang: string) => [Config.shopUrl, lang].join('/')
var router = new Navigo(buildRoot(Config.locale), {
    hash: false
});
if ('scrollRestoration' in window.history)
    window.history.scrollRestoration = 'manual';
var history = [];
router.hooks({
    before: function (resolve) {
        var _a = __read(getUri().split('#'), 2), path = _a[0], hash = _a[1];
        var _b = __read((getCurrentPage() || '').split('#'), 2), currentPath = _b[0], currentHash = _b[1];
        var isSamePage = currentPath === path && hash !== currentHash;
        history.push(getUri());
        if (isSamePage)
            return resolve(false);
        if (history.length > 1) {
            store.search.set(null);
            store.panel.set(null);
            store.drawer.set(null);
        }
        store.path.set(getPath().replace(getRoot(), ''));
        var mainRouter = store.routers.get().main;
        if (mainRouter.state.transitioning || mainRouter.state.loading)
            return resolve(false);
        resolve();
    },
    after: function () {
        // history.push(router.path())
    }
});
export var getRoot = function () { return window.location.origin; };
export var getPath = function () { return window.location.href; };
export var getUri = function () { return window.location.pathname + window.location.search; };
export var getHistory = function () { return history; };
export var getLastPage = function () { return history.length > 1 ? history[history.length - 2] : false; };
export var getCurrentPage = function () { return history.length > 0 ? history[history.length - 1] : false; };
export var lastResolvedRoute = function () { var _a; return (_a = router.lastResolved()) === null || _a === void 0 ? void 0 : _a[0]; };
export var updateLastRoute = function (route) {
    var lastResolved = lastResolvedRoute();
    if (lastResolved)
        lastResolved.url = route;
};
export var getLinkPath = function (link) { return link === null || link === void 0 ? void 0 : link.getAttribute('href').replace(getRoot(), ''); };
// export const updateLang = (lang: string) => {
//   if (lang === Config.lang) return
//   Config.lang = lang
//   getRoot() = buildRoot(Config.lang)
//   const lastResolved = router.lastResolved()
//   if (lastResolved) lastResolved.url = ''
// }
// router._findLinks = () => [].slice.call(document.querySelectorAll('[data-navigo], .data-navigo'))
router.getLinkPath = function (link) {
    var _a;
    return ((_a = link.getAttribute('href')) === null || _a === void 0 ? void 0 : _a.replace(getRoot(), '')) || '';
};
//   router._findLinks().forEach(link => {
//     if (!link.hasListenerAttached) {
//       link.addEventListener('click', (e) => {
//         if ((e.ctrlKey || e.metaKey) && e.currentTarget.tagName.toLowerCase() === 'a') return false
//         // if ((e.ctrlKey || e.metaKey) && e.target.tagName.toLowerCase() === 'a') return false
//         const location = router.getLinkPath(link)
//         if (!router._destroyed) {
//           e.preventDefault()
//           router.navigate(location.replace(/\/+$/, '').replace(/^\/+/, '/'))
//         }
//       })
//       link.hasListenerAttached = true
//     }
//   })
// }
export var updatePageLinks = function () {
    tag.updatePageEvents();
    router.updatePageLinks();
};
export default router;
