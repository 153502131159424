import { throttle } from 'lodash-es';
import detect from './detect';
var cache = {};
var history = {};
var root;
var ignoreScrollResize = true;
var dimensions = function () {
    if (cache.dimensions)
        return cache.dimensions;
    var dimensions = {
        width: !root ? window.innerWidth : root.offsetWidth,
        innerWidth: document.body.offsetWidth,
        // height: Math.min(document.documentElement.offsetHeight, window.innerHeight)
        height: window.innerHeight
    };
    dimensions.innerHeight = dimensions.height;
    dimensions.scrollWidth = dimensions.width - dimensions.innerWidth;
    // Ignore ios resize on scroll (wheen bottom bar disappear)
    if (ignoreScrollResize &&
        detect.iphone &&
        history.dimensions &&
        history.dimensions.width === dimensions.width)
        dimensions.innerHeight = history.dimensions.innerHeight;
    dimensions.ratio = dimensions.width / dimensions.height;
    history.dimensions = Object.assign({}, dimensions);
    return (cache.dimensions = dimensions);
};
var width = function () { return dimensions().width; };
var height = function () { return dimensions().height; };
var ratio = function () { return dimensions().ratio; };
var scrollWidth = function () { return dimensions().scrollWidth; };
var testMQ = function (mq) { return function () { return cache[mq] || (cache[mq] = window.matchMedia(mq).matches); }; };
var clear = function () {
    history = cache;
    cache = {};
};
var listeners = [];
var resize = function (event) {
    _resize(event);
};
var _resize = throttle(function (event) {
    clear();
    listeners.forEach(function (listener) { return listener && listener.resize && listener.resize(); });
}, 50);
window.addEventListener('resize', resize);
window.addEventListener('orientationchange', resize);
var add = function (listener) { return listeners.push(listener); };
var remove = function (listener) { return (listeners = listeners.filter(function (item) { return item !== listener; })); };
var setRoot = function (r) {
    root = r;
    clear();
    resize();
};
var setIgnoreScrollResize = function (i) { return (ignoreScrollResize = !!i); };
export { dimensions };
export default {
    dimensions: dimensions,
    scrollWidth: scrollWidth,
    outerWidth: outerWidth,
    ratio: ratio,
    width: width,
    height: height,
    clear: clear,
    add: add,
    remove: remove,
    setRoot: setRoot,
    setIgnoreScrollResize: setIgnoreScrollResize,
    resize: resize,
    testMQ: testMQ
};
