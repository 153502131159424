var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import PanelPage from 'navigation/pages/PanelPage';
var EditAddress = /** @class */ (function (_super) {
    __extends(EditAddress, _super);
    function EditAddress() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(EditAddress.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
            var isCheckout = document.querySelector('.page.checkout');
            if (isCheckout) {
                this.modules.form.el.action += '?from=checkout';
                this.modules.form.action += '?from=checkout';
            }
        }
    });
    Object.defineProperty(EditAddress.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.resize.call(this);
        }
    });
    Object.defineProperty(EditAddress.prototype, "flush", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.flush.call(this);
        }
    });
    return EditAddress;
}(PanelPage));
export default EditAddress;
