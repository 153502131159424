var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import PanelPage from 'navigation/pages/PanelPage';
import { bindMethod } from 'helpers/bind';
import store from 'store';
import { forceArray } from 'navigation/component/Component';
import SizeGuideTable from './partials/SizeGuideTable';
var SizeGuide = /** @class */ (function (_super) {
    __extends(SizeGuide, _super);
    function SizeGuide() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        Object.defineProperty(_this, "toggleUnits", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                var _a;
                var button = e.currentTarget;
                var unit = button === null || button === void 0 ? void 0 : button.getAttribute('data-unit');
                (_a = _this.refs.toggleUnits) === null || _a === void 0 ? void 0 : _a.forEach(function (el) {
                    el.classList.toggle('active', el.dataset.unit === unit);
                });
                _this.el.setAttribute('data-unit', unit);
                forceArray(_this.modules.sizeGuideTable).forEach(function (table) {
                    table.resize();
                });
            }
        });
        Object.defineProperty(_this, "onPreviousButtonClick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                var _a;
                var page = document.querySelector('.page.product');
                (_a = page === null || page === void 0 ? void 0 : page.dispatchEvent) === null || _a === void 0 ? void 0 : _a.call(page, new CustomEvent('show-size'));
                store.panel.set(null);
            }
        });
        return _this;
    }
    Object.defineProperty(SizeGuide.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                sizeGuideTable: ['.sizes__item-content', SizeGuideTable]
            };
        }
    });
    Object.defineProperty(SizeGuide.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
            forceArray(this.refs.toggleUnits).forEach(function (el) {
                if (el.getAttribute('data-unit') === 'cm')
                    el.classList.add('active');
            });
        }
    });
    Object.defineProperty(SizeGuide.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            var _a;
            _super.prototype.bindEvents.call(this, add);
            var method = bindMethod(add);
            this.refs.previousButton[method]('click', this.onPreviousButtonClick);
            (_a = this.refs.toggleUnits) === null || _a === void 0 ? void 0 : _a.forEach(function (el) {
                el[method]('click', _this.toggleUnits);
            });
        }
    });
    Object.defineProperty(SizeGuide.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.refs.sizesInner.style.height = "calc(var(--vh) - ".concat(this.refs.sizesHeader.clientHeight, "px)");
            _super.prototype.resize.call(this);
        }
    });
    return SizeGuide;
}(PanelPage));
export default SizeGuide;
