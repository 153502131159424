var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Component, { forceArray } from 'navigation/component/Component';
import ImageZoom from 'sections/editorial/ImageZoom';
import { bindMethod } from 'helpers/bind';
var ArticleGallery = /** @class */ (function (_super) {
    __extends(ArticleGallery, _super);
    function ArticleGallery() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(ArticleGallery.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            if (add === void 0) { add = true; }
            var method = bindMethod(add);
            forceArray(this.refs.items).forEach(function (item, i) {
                item[method]('click', function () { return _this.modules.zoom.open(i); });
            });
        }
    });
    Object.defineProperty(ArticleGallery.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                zoom: ['.image-zoom', ImageZoom]
            };
        }
    });
    return ArticleGallery;
}(Component));
export default ArticleGallery;
