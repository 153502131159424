var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import anime from 'animejs';
var staggerItems = function (targets, _a) {
    if (_a === void 0) { _a = {}; }
    var _b = _a.initialDelay, initialDelay = _b === void 0 ? 0 : _b, _c = _a.stagger, stagger = _c === void 0 ? 50 : _c, _d = _a.hide, hide = _d === void 0 ? false : _d, _e = _a.duration, duration = _e === void 0 ? 500 : _e, props = __rest(_a, ["initialDelay", "stagger", "hide", "duration"]);
    return anime(__assign({ targets: targets, opacity: {
            value: hide ? [1, 0] : [0, 1],
            easing: 'linear'
        }, duration: duration, delay: function (el, i) {
            return i * stagger + initialDelay;
        }, translateY: {
            value: hide ? [0, -10] : [10, 0],
            easing: hide ? 'easeInCubic' : 'easeOutCubic'
        } }, props)).finished;
};
export { staggerItems };
