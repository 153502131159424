var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import DiptychImageText from 'components/diptychImageText/DiptychImageText';
import PageHeader from 'components/page-header/PageHeader';
import modulesMap from 'core/modulesMap';
import MainPage from 'navigation/pages/MainPage';
var Brand = /** @class */ (function (_super) {
    __extends(Brand, _super);
    function Brand() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Brand.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __assign(__assign({}, modulesMap), { pageHeader: ['.page-header', PageHeader], brandDiptychImageText: ['.brand-diptych-image-text', DiptychImageText] });
        }
    });
    Object.defineProperty(Brand, "pageName", {
        enumerable: true,
        configurable: true,
        writable: true,
        value: 'Brand'
    });
    return Brand;
}(MainPage));
export default Brand;
