var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import Component from 'navigation/component/Component';
import { bindMethod } from 'helpers/bind';
import scroll from 'core/scroll';
import { clamp } from 'lodash-es';
var HomeDiptych = /** @class */ (function (_super) {
    __extends(HomeDiptych, _super);
    function HomeDiptych() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        Object.defineProperty(_this, "_fraction", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(_this, "bindEvents", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (add) {
                var method = bindMethod(add);
                window[method]('scroll', _this.onPageScroll);
                _this.refs.slider[method]('scroll', _this.onScroll);
            }
        });
        Object.defineProperty(_this, "onScroll", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                _this._fraction = _this.refs.slider.scrollWidth / 2;
                // const currentImage = Math.round(this.refs.slider.scrollLeft / this._fraction)
                var progress = _this.refs.slider.scrollLeft / (_this.refs.slider.scrollWidth - _this.refs.slider.clientWidth);
                _this.refs.track.style.transform = "translateX(".concat(progress * (100) * 1, "%)");
            }
        });
        Object.defineProperty(_this, "onPageScroll", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                _this.slides.forEach(function (slide) {
                    var v = clamp((scroll.scrollTop() + window.innerHeight) - _this._bounds.top, slide.clientHeight, _this._bounds.height);
                    slide.style.setProperty('--scroll', v + 'px');
                });
                var trackBottom = _this.refs.trackWrapper.getBoundingClientRect().bottom;
                var elBottom = _this.el.getBoundingClientRect().bottom;
                var diff = elBottom - trackBottom;
                if (diff < 300)
                    _this.refs.trackWrapper.classList.add('is-hidden');
                else
                    _this.refs.trackWrapper.classList.remove('is-hidden');
            }
        });
        return _this;
    }
    Object.defineProperty(HomeDiptych.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
            this.slides = Array.from(this.el.querySelectorAll('.home-block-content__inner'));
            var b = this.el.getBoundingClientRect();
            this._bounds = {
                top: b.top,
                height: b.height
            };
        }
    });
    Object.defineProperty(HomeDiptych.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.resize.call(this);
        }
    });
    return HomeDiptych;
}(Component));
export default HomeDiptych;
