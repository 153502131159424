var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import Component from 'navigation/component/Component';
import mqStore from 'store/mqStore';
var ProductBar = /** @class */ (function (_super) {
    __extends(ProductBar, _super);
    function ProductBar() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        Object.defineProperty(_this, "_visible", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "onSwitchMq", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (isTabletPortrait) {
                if (isTabletPortrait)
                    _this.visible = true;
                else
                    _this.visible = false;
            }
        });
        return _this;
    }
    Object.defineProperty(ProductBar.prototype, "visible", {
        get: function () {
            return this._visible;
        },
        set: function (value) {
            if (value === this._visible)
                return;
            this._visible = value;
            this.refs.productBar.parentElement.classList.toggle('hidden', !value);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProductBar.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
            this.onSwitchMq(mqStore.tabletPortrait.get());
        }
    });
    Object.defineProperty(ProductBar.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            if (add === void 0) { add = true; }
            mqStore.tabletPortrait.toggleListener(add, this.onSwitchMq);
        }
    });
    Object.defineProperty(ProductBar.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.resize.call(this);
        }
    });
    return ProductBar;
}(Component));
export default ProductBar;
