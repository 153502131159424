import * as yup from 'yup';
import config from 'core/config';
var codeRegexp = /^[\w]{2,}$/;
var phoneRegexp = /^[0-9- _()]+$/;
var expirationRegexp = /^((0[1-9])|(1[0-2]))\/[0-9]{2}$/;
var nameRegexp = /^[A-z\u00C0-\u00ff\s'\-()]*$/;
var latinRegexp = /^[0-9A-z\u00C0-\u00ff\s'.,-/#!$%^&*;:{}=\-_`~()]*$/;
var passwordRegexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9])(?=.{8,})/;
var _a = config.errors, errors = _a === void 0 ? {} : _a;
yup.setLocale({
    mixed: {
        default: errors.invalid,
        notType: errors.invalid,
        required: errors.required
    },
    number: {
        min: errors.invalid,
        max: errors.invalid
    },
    string: {
        email: errors.email,
        min: errors.invalid,
        max: errors.invalid,
        matches: errors.invalid
    }
});
var validators = {
    address: yup.string().matches(latinRegexp, errors.latin),
    name: yup.string().matches(nameRegexp, errors.latin),
    email: yup.string().email(),
    expirationcard: yup.string().matches(expirationRegexp),
    code: yup.string().matches(codeRegexp),
    password: yup.string().matches(passwordRegexp, errors.password),
    // day: yup.lazy((v) => (v === '' ? yup.string() : )),
    // month: yup.lazy((v) => (v === '' ? yup.string() : yup.number().min(1).max(12))),
    // year: yup.lazy((v) => (v === '' ? yup.string() : yup.number().min(1900).max(2030))),
    city: yup.string().matches(latinRegexp, errors.latin),
    street: yup.string().matches(latinRegexp, errors.latin),
    string: yup.string(),
    phone: yup.string().min(5).matches(phoneRegexp),
    required: function (v) { return (v || yup.string()).required(); },
    bool: (function () {
        var bool = yup.bool();
        bool.required = function () { return bool.oneOf([true]); };
        return bool;
    })()
};
validators.passwordConfirm = validators.password.oneOf([yup.ref('password'), yup.ref('new-password'), null], errors.confirmPassword);
validators.day = yup.mixed().when(['$month', '$year'], {
    is: function (month, year) { return (!!month || !!year); },
    then: function () { return yup.number().min(1).max(31); }
});
validators.month = yup.mixed().when(['$day', '$year'], {
    is: function (day, year) { return (!!day || !!year); },
    then: function () { return yup.number().min(1).max(12); }
});
validators.year = yup.mixed().when(['$month', '$day'], {
    is: function (month, day) { return (!!month || !!day); },
    then: function () { return yup.number().min(1900).max(2030); }
});
export default validators;
