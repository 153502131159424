import { each } from 'lodash-es';
import resize from '../helpers/resize';
import { createStore } from '../helpers/state';
var mqSizes = {
    tabletMaxWidth: '1024',
    tabletPortraitMaxWidth: '900',
    phoneMaxWidth: '740',
    phonePortraitMaxWidth: '420'
};
// const prefix = 'mq'
var initialState = {};
var match = {};
each(mqSizes, function (size, k) {
    var key = k.replace('MaxWidth', '');
    var _size = parseInt(size);
    initialState[key] = resize.width() < _size;
    match[key] = _size;
});
var mqStore = createStore(initialState);
resize.add({
    resize: function () {
        each(mqStore, function (state, key) {
            state.set(resize.width() < match[key]);
        });
    }
});
export default mqStore;
