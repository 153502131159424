var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import PanelPage from 'navigation/pages/PanelPage';
import ProductCard from 'sections/product/partials/ProductCard';
import RowProductCard from 'sections/product/partials/RowProductCard';
import Form from 'components/form/Form';
import modulesMap from 'core/modulesMap';
import { bindMethod } from 'helpers/bind';
import CartReinsurance from './CartReinsurance';
var Cart = /** @class */ (function (_super) {
    __extends(Cart, _super);
    function Cart() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        Object.defineProperty(_this, "updateSizes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                var cartHeaderHeight = _this.refs.cartHeader.getBoundingClientRect().height;
                _this.el.style.setProperty('--cart-header-height', "".concat(cartHeaderHeight, "px"));
                if (_this.refs.cartActions) {
                    var cartActionsHeight = _this.refs.cartActions.getBoundingClientRect().height;
                    _this.el.style.setProperty('--cart-actions-height', "".concat(cartActionsHeight, "px"));
                }
                if (_this.refs.cartReinsurance) {
                    var cartReinsuranceHeight = _this.refs.cartReinsurance.getBoundingClientRect().height;
                    _this.el.style.setProperty('--cart-reinsurance-height', "".concat(cartReinsuranceHeight, "px"));
                }
            }
        });
        Object.defineProperty(_this, "clearCoupon", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                _this.modules.couponForm.clear();
                _this.modules.couponForm.submit().then(function () {
                    _this.modules.couponForm.setSubmitted(false);
                });
            }
        });
        return _this;
    }
    Object.defineProperty(Cart.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __assign(__assign({}, modulesMap), { products: ['.row-product-card', RowProductCard], productsRelated: ['.product-card', ProductCard], couponForm: ['.checkout-promo__form', Form], cartReinsurance: ['.cart__reinsurance', CartReinsurance] });
        }
    });
    Object.defineProperty(Cart.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
            this.updateSizes();
            if (this.refs.cartActions)
                new ResizeObserver(this.resize.bind(this)).observe(this.refs.cartActions);
        }
    });
    Object.defineProperty(Cart.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            _super.prototype.bindEvents.call(this, add);
            var method = bindMethod(add);
            if (this.refs.clearCoupon)
                this.refs.clearCoupon[method]('click', this.clearCoupon);
        }
    });
    Object.defineProperty(Cart.prototype, "onUpdate", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
        }
    });
    Object.defineProperty(Cart.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.updateSizes();
            _super.prototype.resize.call(this);
        }
    });
    Object.defineProperty(Cart.prototype, "flush", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.flush.call(this);
        }
    });
    Object.defineProperty(Cart, "pageName", {
        enumerable: true,
        configurable: true,
        writable: true,
        value: 'cart'
    });
    return Cart;
}(PanelPage));
export default Cart;
