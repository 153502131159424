import VirtualPageManager from 'navigation/page-manager/VirtualPageManager'
import Component from 'navigation/component/Component'
import { State } from 'helpers/state'

import scroll from 'core/scroll'
import InternalPage from 'navigation/pages/InternalPage'

class CheckoutPopin extends Component {
  constructor (el) {
    super(...arguments)
    this.store = new State(null)
    this.pageManager = new VirtualPageManager(this.store, el.querySelector('.checkout-popin__inner'), '.page', InternalPage)
  }

  bindEvents (add = true) {
    const m1 = add ? 'addEventListener' : 'removeEventListener'
    document[m1]('keydown', this.escape)
    this.store[add ? 'listenAndStart' : 'unlisten'](this.onPanelUpdate)
    this.refs.closeCheckoutPopin[m1]('click', this.onClose)
    this.refs.overlayCheckoutPopin[m1]('click', this.onClose)
  }

  escape = event => {
    if (event.key === 'Escape')
      this.onClose()
  }

  onClose = () => {
    this.store.set(null)
  }

  resize () {
    this.pageManager.resize()
    super.resize()
  }

  onPanelUpdate = (panel) => {
    const notEmpty = !!panel || panel === ''
    this.el.classList.toggle('opened', notEmpty)
    if (notEmpty) scroll.lock(true)
    else scroll.unlock(true)
  }
}

export default CheckoutPopin
