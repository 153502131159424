var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import MainPage from 'navigation/pages/MainPage';
import modulesMap from 'core/modulesMap';
import PageHeader from 'components/page-header/PageHeader';
import Pagination from 'components/pagination/Pagination';
import { extractInfoFromXhr } from 'navigation/page-manager/CustomPageManager';
import { forceArray } from 'navigation/component/Component';
import { updatePageLinks } from 'core/router';
import browser from 'helpers/browser';
import { findMostPopularNumber } from 'sections/category/partials/CategoryProducts';
import { bindEmitterMethod } from 'helpers/bind';
import Footer from 'components/footer/Footer';
var Journal = /** @class */ (function (_super) {
    __extends(Journal, _super);
    function Journal() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        Object.defineProperty(_this, "loading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "endpoint", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "io", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "visibleEntries", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(_this, "currentPage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 1
        });
        Object.defineProperty(_this, "onPageLoaded", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (_a, pageIndex, direction) {
                var _b, _c;
                var _d, _e, _f, _g;
                var xhr = _a.currentTarget;
                _this.loading = false;
                _this.pageManager.state.loading = false;
                var page = xhr.response;
                extractInfoFromXhr(xhr);
                xhr.onload = null;
                var items = Array.from(page.querySelectorAll('.editorial__list .editorial__item'));
                if (direction === 1)
                    (_b = _this.refs.journalList).append.apply(_b, __spreadArray([], __read(items), false));
                else
                    (_c = _this.refs.journalList).prepend.apply(_c, __spreadArray([], __read(items), false));
                // this.el.querySelector('.pagination.next').outerHTML = page.querySelector('.pagination.next').outerHTML
                (_d = _this.refs.loadMore) === null || _d === void 0 ? void 0 : _d.setAttribute('href', (_e = page.querySelector('.pagination.next a')) === null || _e === void 0 ? void 0 : _e.getAttribute('href'));
                if (page.querySelector('.pagination.prev a'))
                    (_f = _this.refs.loadLess) === null || _f === void 0 ? void 0 : _f.setAttribute('href', (_g = page.querySelector('.pagination.prev a')) === null || _g === void 0 ? void 0 : _g.getAttribute('href'));
                forceArray(_this.modules.pagination).forEach(function (p) {
                    p.onPageLoaded(pageIndex, items.length);
                });
                _this.modules.revealList.reset();
                updatePageLinks();
                browser.waitRepaint(function () {
                    _this.refresh();
                    _this.io.disconnect();
                    for (var i = 0; i < _this.refs.editorialItem.length; i++)
                        _this.io.observe(_this.refs.editorialItem[i]);
                });
            }
        });
        return _this;
    }
    Object.defineProperty(Journal.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __assign(__assign({}, modulesMap), { pageHeader: ['.page-header', PageHeader], pagination: ['.pagination', Pagination], footer: ['footer', Footer] });
        }
    });
    Object.defineProperty(Journal.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _a;
            _super.prototype.initialized.call(this);
            this.endpoint = ((_a = this.refs.journalList) === null || _a === void 0 ? void 0 : _a.getAttribute('data-src')) || '';
            this.io = new IntersectionObserver(this.watchPageScrollChange.bind(this), { threshold: [1] });
            if (this.refs.editorialItem) {
                for (var i = 0; i < this.refs.editorialItem.length; i++)
                    this.io.observe(this.refs.editorialItem[i]);
            }
        }
    });
    Object.defineProperty(Journal.prototype, "getModuleParams", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (el, componentConstructor) {
            var _a, _b, _c;
            return __assign(__assign({}, _super.prototype.getModuleParams.call(this, el, componentConstructor)), { seen: (_a = Array.from(this.el.querySelectorAll('.editorial__list a'))) === null || _a === void 0 ? void 0 : _a.length, pages: ((_b = this.refs.journalList) === null || _b === void 0 ? void 0 : _b.getAttribute('data-nb-pages')) || 0, current: ((_c = this.refs.journalList) === null || _c === void 0 ? void 0 : _c.getAttribute('data-page')) || 0 });
        }
    });
    Object.defineProperty(Journal.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            var emitterMethod = bindEmitterMethod(add);
            forceArray(this.modules.pagination || []).forEach(function (p) {
                p[emitterMethod]('load', function (page, direction) { return _this.loadNewPage(page, direction); });
            });
        }
    });
    Object.defineProperty(Journal.prototype, "watchPageScrollChange", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (entries) {
            for (var i = 0; i < entries.length; i++) {
                if (entries[i].isIntersecting) {
                    this.visibleEntries.push(entries[i].target);
                }
                else {
                    var index = this.visibleEntries.indexOf(entries[i].target);
                    if (index > -1)
                        this.visibleEntries.splice(index, 1);
                }
            }
            // determine the current page by getting the most popular page in data-page on visible products
            var pages = this.visibleEntries.map(function (p) { return p.getAttribute('data-page'); });
            // in the pages array, determine which values is the most present and output it
            if (pages.length) {
                var current = findMostPopularNumber(pages);
                if (current !== this.currentPage) {
                    this.currentPage = current;
                    if (this.currentPage > 1)
                        window.history.replaceState({}, '', this.endpoint + (~this.endpoint.indexOf('?') ? '&' : '?') + 'page=' + this.currentPage);
                    else
                        window.history.replaceState({}, '', this.endpoint);
                }
            }
        }
    });
    Object.defineProperty(Journal.prototype, "loadNewPage", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (page, direction) {
            var _this = this;
            if (direction === void 0) { direction = 1; }
            if (this.loading)
                return;
            this.loading = true;
            this.pageManager.state.loading = true;
            var link = this.endpoint + (~this.endpoint.indexOf('?') ? '&' : '?') + 'page=' + page;
            if (link) {
                return new Promise(function (resolve) {
                    var xhr = new XMLHttpRequest();
                    xhr.withCredentials = true;
                    xhr.responseType = 'document';
                    xhr.open('GET', link, true);
                    xhr.setRequestHeader('X-Fursac-Async', 'true');
                    xhr.onload = function (event) {
                        window.history.pushState({}, '', link);
                        _this.onPageLoaded(event, page, direction);
                        resolve();
                    };
                    xhr.send();
                });
            }
            else {
                return new Promise(function (resolve) {
                    console.warn('No endpoint set to load new page'); // eslint-disable-line
                    resolve();
                });
            }
        }
    });
    return Journal;
}(MainPage));
export default Journal;
