var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import mapboxgl from 'mapbox-gl';
import config from 'core/config';
import 'mapbox-gl/dist/mapbox-gl.css';
import Component from 'navigation/component/Component';
import { bindMethod } from 'helpers/bind';
import scroll from 'core/scroll';
mapboxgl.accessToken = config.mapbox;
var Map = /** @class */ (function (_super) {
    __extends(Map, _super);
    function Map(el, options) {
        var _this = _super.call(this, el, options) || this;
        Object.defineProperty(_this, "map", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "markers", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "popups", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "zoomIn", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () { return _this.map && _this.map.zoomIn(); }
        });
        Object.defineProperty(_this, "zoomOut", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () { return _this.map && _this.map.zoomOut(); }
        });
        Object.defineProperty(_this, "onScroll", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                _this.map && _this.map.resize();
            }
        });
        Object.defineProperty(_this, "deleteMarkersAndPopUp", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (markers, popups) {
                setTimeout(function () {
                    for (var i = 0; i < markers.length; i++) {
                        var marker = markers[i].getElement();
                        marker.onclick = null;
                        popups[i].remove();
                        markers[i].remove();
                    }
                }, 1000);
            }
        });
        Object.defineProperty(_this, "setActiveMarker", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (index) {
                var _a;
                if (index !== null) {
                    _this.markers.forEach(function (marker, i) {
                        marker.getElement().classList.toggle('map__marker--active', i === index);
                        marker.getElement().classList.toggle('map__marker--inactive', i !== index);
                    });
                    var current = (_a = _this.markers[index]) === null || _a === void 0 ? void 0 : _a.getLngLat();
                    _this.map.flyTo({ center: current, zoom: 13, animate: true });
                }
                else {
                    _this.markers.forEach(function (marker) {
                        marker.getElement().classList.remove('map__marker--active');
                        marker.getElement().classList.remove('map__marker--inactive');
                    });
                    var bounds_1 = new mapboxgl.LngLatBounds();
                    _this.markers.forEach(function (marker) { return bounds_1.extend(marker.getLngLat()); });
                    _this.map.fitBounds(bounds_1, { maxZoom: 12, animate: true });
                }
            }
        });
        Object.defineProperty(_this, "createMarkersAndPopups", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (points) {
                if (_this.markers)
                    _this.deleteMarkersAndPopUp(_this.markers, _this.popups);
                _this.markers = [];
                _this.popups = [];
                points.forEach(function (_a, i) {
                    var _b = __read(_a, 3), point = _b[0], text = _b[1], button = _b[2];
                    var wrapper = document.createElement('div');
                    wrapper.innerHTML = text;
                    if (button)
                        wrapper.appendChild(button);
                    var popup = new mapboxgl.Popup({ offset: 25 }).setDOMContent(wrapper);
                    _this.popups.push(popup);
                    var el = document.createElement('div');
                    el.className = 'map__marker';
                    el.onclick = _this.onMarkerClicked(i);
                    var marker = new mapboxgl.Marker(el)
                        .setLngLat(point)
                        .addTo(_this.map)
                        .setPopup(popup);
                    _this.markers.push(marker);
                });
            }
        });
        Object.defineProperty(_this, "onMarkerClicked", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (i) { return function (event) {
                _this.emit('clicked', i);
            }; }
        });
        _this.map = new mapboxgl.Map({
            container: _this.el,
            minZoom: 6,
            maxZoom: 18,
            language: _this.el.dataset.lang || 'fr'
        });
        return _this;
    }
    Object.defineProperty(Map.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
            this.resize();
        }
    });
    Object.defineProperty(Map.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            if (add === void 0) { add = true; }
            _super.prototype.bindEvents.apply(this, __spreadArray([], __read(arguments), false));
            var method = bindMethod(add);
            var methodSignal = add ? 'on' : 'off';
            this.refs.zoomIn[method]('click', this.zoomIn);
            this.refs.zoomOut[method]('click', this.zoomOut);
            scroll[methodSignal](this.onScroll);
        }
    });
    Object.defineProperty(Map.prototype, "setMarkers", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (points) {
            var bounds = new mapboxgl.LngLatBounds();
            points.forEach(function (p) { return bounds.extend(p[0]); });
            var options = { maxZoom: 12, animate: true };
            if (points.length) {
                if (!this.markers)
                    options.animate = false;
                this.map.fitBounds(bounds, options);
            }
            this.createMarkersAndPopups(points);
        }
    });
    Object.defineProperty(Map.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.map.resize();
        }
    });
    return Map;
}(Component));
export default Map;
