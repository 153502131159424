var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Component from 'navigation/component/Component';
import mqStore from 'store/mqStore';
import store from 'store';
import resize from 'helpers/resize';
import AddToWishlist from 'components/add-to-wishlist/AddToWishlist';
import { bindMethod } from 'helpers/bind';
import sizeStore from 'store/sizeStore';
var ProductDetails = /** @class */ (function (_super) {
    __extends(ProductDetails, _super);
    function ProductDetails(el, options) {
        var _this = _super.call(this, el, options) || this;
        Object.defineProperty(_this, "isOneSize", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "resizeObserver", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "alignBottom", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "observerResize", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                _this.computeAlignement();
            }
        });
        // onClickReservation = event => {
        //   event.preventDefault()
        //   this.onClickReservationCallback()
        // }
        // onClickReservationCallback = () => {
        //   const size = this.refs.socloz?.getAttribute('data-socloz-product-attributes-clothes_size')
        //   if (!this.refs.socloz || (!size && !this.isOneSize)) return
        //   this.refs.socloz.click()
        // }
        Object.defineProperty(_this, "onInfosClick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (button) {
                var open = button.getAttribute('data-open');
                store.infosAccordion.set(open);
                store.drawer.set('infos');
            }
        });
        Object.defineProperty(_this, "updateSizes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (_a) {
                var label = _a.label, article = _a.article;
                if (article)
                    _this.refs.sizeLabel.find(function (label) { return label.dataset.inputName === 'article'; }).textContent = label;
                else
                    _this.refs.sizeLabel.find(function (label) { return label.dataset.inputName === 'article_2'; }).textContent = label;
            }
        });
        _this.el = el;
        _this.bindRefs(_this.el.parentElement);
        if (window.ResizeObserver) {
            _this.resizeObserver = new ResizeObserver(_this.observerResize);
            _this.resizeObserver.observe(_this.el);
        }
        return _this;
    }
    Object.defineProperty(ProductDetails.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return __assign(__assign({}, _super.prototype.getModulesMap.call(this)), { addToWishlist: ['.add-to-wishlist__small', AddToWishlist] });
        }
    });
    Object.defineProperty(ProductDetails.prototype, "getModuleParams", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (el, componentConstructor) {
            return {
                parent: this.parent
            };
        }
    });
    Object.defineProperty(ProductDetails.prototype, "bindModules", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.bindModules.call(this);
        }
    });
    Object.defineProperty(ProductDetails.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            if (add === void 0) { add = true; }
            var method = bindMethod(add);
            // if (this.refs.storeReservation) this.refs.storeReservation[method]('click', this.onClickReservation)
            if (this.refs.infosToggle)
                this.refs.infosToggle.forEach(function (button) { return button[method]('click', function () { return _this.onInfosClick(button); }); });
        }
    });
    Object.defineProperty(ProductDetails.prototype, "computeAlignement", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (mqStore.tabletPortrait.get())
                return;
            // Compute product details alignement
            var top = (sizeStore.headerHeight.get() + this.el.parentElement.offsetTop);
            var availableHeight = resize.height() - top;
            var alignBottom = this.el.offsetHeight > availableHeight;
            if (this.alignBottom === alignBottom)
                return;
            this.alignBottom = alignBottom;
            this.el.parentElement.classList.toggle('alignBottom', alignBottom);
        }
    });
    Object.defineProperty(ProductDetails.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.resize.call(this);
            this.computeAlignement();
        }
    });
    Object.defineProperty(ProductDetails.prototype, "flush", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (this.resizeObserver)
                this.resizeObserver.disconnect();
            this.resizeObserver = null;
            _super.prototype.flush.call(this);
        }
    });
    return ProductDetails;
}(Component));
export default ProductDetails;
