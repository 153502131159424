var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Component from 'navigation/component/Component';
import Form from 'components/form/Form';
import { bindMethod } from 'helpers/bind';
import AddToWishlist from 'components/add-to-wishlist/AddToWishlist';
var RowProductCard = /** @class */ (function (_super) {
    __extends(RowProductCard, _super);
    function RowProductCard(el, options) {
        var _this = _super.call(this, el, options) || this;
        Object.defineProperty(_this, "onQuantityUpdate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (event) {
                event.preventDefault();
                event.stopImmediatePropagation();
                event.stopPropagation();
                var quantity = _this.refs.quantity.value;
                var newQuantity = +quantity + parseInt(event.currentTarget.dataset.delta);
                _this.refs.quantity.value = newQuantity.toString();
                _this.modules.form.submit();
            }
        });
        Object.defineProperty(_this, "onSelectChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                _this.modules.form.submit();
            }
        });
        Object.defineProperty(_this, "onSelectClick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (event) {
                event.preventDefault();
                event.stopImmediatePropagation();
                event.stopPropagation();
            }
        });
        _this.parent = options.parent;
        return _this;
    }
    Object.defineProperty(RowProductCard.prototype, "getModuleParams", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                parent: this.parent
            };
        }
    });
    Object.defineProperty(RowProductCard.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                form: ['.row-product-card__form', Form],
                addToWishlist: ['.add-to-wishlist', AddToWishlist]
            };
        }
    });
    Object.defineProperty(RowProductCard.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            if (add === void 0) { add = true; }
            var method = bindMethod(add);
            if (this.refs.selects) {
                this.refs.selects.forEach(function (select) {
                    select[method]('click', _this.onSelectClick);
                    select[method]('change', _this.onSelectChange);
                });
            }
            if (this.refs.quantityLess)
                this.refs.quantityLess[method]('click', this.onQuantityUpdate);
            if (this.refs.quantityMore)
                this.refs.quantityMore[method]('click', this.onQuantityUpdate);
        }
    });
    return RowProductCard;
}(Component));
export default RowProductCard;
