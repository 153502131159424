var _a, _b;
import 'scrolling-element';
import anime from 'animejs';
import { TinyEmitter } from 'tiny-emitter';
import store from 'store';
import detect from 'helpers/detect';
var scrollTop = ((_a = document.scrollingElement) === null || _a === void 0 ? void 0 : _a.scrollTop) || 0;
var preventScroll = false;
var disableOverflow = false;
var emitter = new TinyEmitter();
(_b = document.scrollingElement) === null || _b === void 0 ? void 0 : _b.classList.add('scrolling-element');
var init = function () {
    window.addEventListener('scroll', pageScroll, { passive: false });
    document.body.addEventListener('touchmove', preventDefault, { passive: false });
    document.body.addEventListener('mousewheel', preventDefault, { passive: false });
    document.body.addEventListener('wheel', preventDefault, { passive: false });
    document.body.addEventListener('DOMMouseScroll', preventDefault, { passive: true });
};
var preventDefault = function (event) {
    if (preventScroll) {
        var preventParent = event.target.closest('.scrolling-wrapper');
        if (!preventParent) {
            event.preventDefault();
        }
        else {
            // console.log(preventParent.scrollHeight, preventParent.offsetHeight, preventParent.scrollTop)
            // if (preventParent.scrollTop >= preventParent.scrollHeight - preventParent.offsetHeight)
            //   event.preventDefault()
        }
    }
};
var lock = function (overflow) {
    if (overflow === void 0) { overflow = false; }
    if (overflow) {
        if (document.documentElement.classList.contains('no-scroll'))
            return;
        var scrollTop_1 = document.documentElement.scrollTop;
        document.documentElement.classList.add('no-scroll');
        if (detect.desktop)
            document.body.scrollTop = scrollTop_1;
        disableOverflow = true;
    }
    else {
        preventScroll = true;
    }
};
var unlock = function (overflow) {
    if (overflow === void 0) { overflow = false; }
    emitter.emit('unlock');
    if (overflow) {
        if (!document.documentElement.classList.contains('no-scroll'))
            return;
        var scrollTop_2 = document.body.scrollTop;
        document.documentElement.classList.remove('no-scroll');
        if (detect.desktop)
            document.documentElement.scrollTop = scrollTop_2;
        disableOverflow = false;
    }
    else {
        preventScroll = false;
    }
};
var pageScroll = function (event) {
    var _a;
    var _scrollTop = ((_a = document.scrollingElement) === null || _a === void 0 ? void 0 : _a.scrollTop) || 0;
    if (_scrollTop === scrollTop)
        return;
    testScroll(_scrollTop);
    scrollTop = _scrollTop;
    emitter.emit('scroll', event);
};
var startScrollDown = false;
var hasScrollDown = false;
var lastScrollTop = Infinity;
var scrollClassname = 'scroll-down';
var topOffset = 100;
var offset = 50;
var testScroll = function (newScrollTop) {
    if (newScrollTop === scrollTop)
        return;
    if (newScrollTop > Math.max(0, scrollTop))
        scrollDown(newScrollTop);
    else
        scrollUp(newScrollTop);
};
var scrollDown = function (newScrollTop) {
    if (!startScrollDown) {
        startScrollDown = true;
        lastScrollTop = newScrollTop;
    }
    if (hasScrollDown || Math.abs(lastScrollTop - newScrollTop) < offset)
        return;
    document.body.classList.add(scrollClassname);
    store.scrollDown.set(true);
    lastScrollTop = newScrollTop;
    hasScrollDown = true;
};
var scrollUp = function (newScrollTop) {
    if (startScrollDown) {
        startScrollDown = false;
        lastScrollTop = newScrollTop;
    }
    if (!hasScrollDown || (Math.abs(lastScrollTop - newScrollTop) < offset && newScrollTop > topOffset))
        return;
    document.body.classList.remove(scrollClassname);
    store.scrollDown.set(false);
    hasScrollDown = false;
};
var scrollTo = function (_scrollTop, _a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.duration, duration = _c === void 0 ? 800 : _c, _d = _b.easing, easing = _d === void 0 ? 'easeOutQuad' : _d, target = _b.target;
    // if (animating) return Promise.resolve()
    return anime({
        targets: target || document.scrollingElement,
        scrollTop: [target ? target.scrollTop : scrollTop, _scrollTop],
        // scrollTop: _scrollTop,
        duration: duration,
        easing: easing,
        // complete: () => (animating = false),
        update: function () { return pageScroll(); }
    }).finished;
};
var getScrollTop = function () {
    if (detect.desktop && disableOverflow)
        return document.body.scrollTop;
    return scrollTop;
};
var scroll = {
    init: init,
    lock: lock,
    unlock: unlock,
    locked: function () { return (preventScroll || disableOverflow); },
    scrollTo: scrollTo,
    on: function (cb) { return emitter.on('scroll', cb); },
    off: function (cb) { return emitter.off('scroll', cb); },
    emitter: emitter,
    scrollTop: getScrollTop,
    reset: function () { return unlock(); }
};
export default scroll;
