var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import VirtualPageManager from 'navigation/page-manager/VirtualPageManager';
import Component from 'navigation/component/Component';
import PanelPage from 'navigation/pages/PanelPage';
import scroll from 'core/scroll';
import { bindMethod } from 'helpers/bind';
import anime from 'animejs';
import { EASE_OUT } from 'helpers/easings';
import { durations } from 'components/menu/motion';
import store from 'store/store';
var Panel = /** @class */ (function (_super) {
    __extends(Panel, _super);
    function Panel(el, options) {
        var _this = _super.call(this, el, options) || this;
        Object.defineProperty(_this, "pageManager", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "opened", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "onLoading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                _this.el.classList.add('loading');
            }
        });
        Object.defineProperty(_this, "onLoaded", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                setTimeout(function () {
                    _this.el.classList.remove('loading');
                }, 200);
                if (!_this.opened)
                    _this.toggle(true, null);
            }
        });
        Object.defineProperty(_this, "onClose", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                store.panel.set(null);
            }
        });
        Object.defineProperty(_this, "onPanelUpdate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (panel, previous) {
                var notEmpty = !!panel || panel === '';
                if (notEmpty)
                    scroll.lock(true);
                else
                    scroll.unlock(true);
                if (notEmpty === false)
                    _this.toggle(notEmpty, previous);
            }
        });
        _this.pageManager = new VirtualPageManager(store.panel, el.querySelector('.panel__inner'), '.page', PanelPage);
        store.routers.get().panel = _this.pageManager;
        return _this;
    }
    Object.defineProperty(Panel.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            if (add === void 0) { add = true; }
            var method = bindMethod(add);
            store.panel.listenAndStart(this.onPanelUpdate);
            this.pageManager.on('loading', this.onLoading);
            this.pageManager.on('loaded', this.onLoaded);
            if (this.refs.panelClose)
                this.refs.panelClose[method]('click', this.onClose);
        }
    });
    Object.defineProperty(Panel.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.pageManager.resize();
            _super.prototype.resize.call(this);
        }
    });
    Object.defineProperty(Panel.prototype, "toggle", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (state, previous) {
            var _this = this;
            if (previous === null && state === false)
                return;
            var wrapper = this.el.children[0];
            anime({
                targets: wrapper,
                translateX: {
                    value: state ? ['50%', '0'] : ['0', '50%'],
                    duration: state ? durations.FIRST_PANEL_TRANSLATE_IN : durations.FIRST_PANEL_TRANSLATE_OUT,
                    easing: EASE_OUT
                },
                opacity: {
                    value: state ? [0, 1] : [1, 0],
                    duration: state ? durations.FIRST_PANEL_OPACITY_IN : durations.FIRST_PANEL_OPACITY_OUT,
                    // delay: state ? durations.FIRST_PANEL : frameToMs(15),
                    easing: 'linear'
                },
                begin: function () {
                    if (state) {
                        wrapper.style.visibility = 'visible';
                        _this.opened = true;
                    }
                },
                complete: function () {
                    if (!state) {
                        wrapper.style.visibility = 'hidden';
                        _this.opened = false;
                        wrapper.children[0].removeAttribute('style');
                    }
                }
            });
        }
    });
    return Panel;
}(Component));
export default Panel;
