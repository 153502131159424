var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import anime from 'animejs';
import AddToWishlist from 'components/add-to-wishlist/AddToWishlist';
import Form from 'components/form/Form';
import { bindEmitterMethod, bindMethod } from 'helpers/bind';
import Component from 'navigation/component/Component';
import { sendProductForm } from 'core/form';
import store from 'store';
var isAnchorElement = function (el) { return el instanceof HTMLAnchorElement; };
var ProductCard = /** @class */ (function (_super) {
    __extends(ProductCard, _super);
    function ProductCard() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        Object.defineProperty(_this, "url", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "_currentImage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(_this, "fraction", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(_this, "animation", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "_switching", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "eventSent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "io", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "bindEvents", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (add) {
                var method = bindMethod(add);
                var emitterMethod = bindEmitterMethod(add);
                if (_this.refs.prevImage && _this.refs.nextImage) {
                    _this.refs.prevImage[method]('click', function (e) { return _this.navigateImage(e, -1); });
                    _this.refs.nextImage[method]('click', function (e) { return _this.navigateImage(e, 1); });
                }
                if (_this.refs.slider)
                    _this.refs.slider[method]('scroll', _this.onScroll);
                if (_this.modules.addToWishlist)
                    _this.modules.addToWishlist[emitterMethod]('toggle', _this.onToggleWishlist);
                if (_this.refs.addToCartButton)
                    _this.refs.addToCartButton[method]('click', _this.addToCart);
                if (_this.refs.deleteAlertButton)
                    _this.refs.deleteAlertButton[method]('click', _this.onDeleteAlert);
                _this.el[method]('click', function (e) { return _this.onClick(e); });
            }
        });
        Object.defineProperty(_this, "onClick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                var event = __assign({}, e);
                event.currentTarget = _this.el;
                _this.emit('click', event);
                var id = _this.el.getAttribute('data-id');
                var page = _this.el.getAttribute('data-page');
                store.lastProduct.set({ id: id, page: page });
            }
        });
        Object.defineProperty(_this, "onToggleWishlist", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                _this.emit('wishlist:toggle', e);
            }
        });
        Object.defineProperty(_this, "onDeleteAlert", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                e.preventDefault();
                e.stopPropagation();
                _this.modules.addToCartForm.submit();
            }
        });
        Object.defineProperty(_this, "navigateImage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (event, direction) { return __awaiter(_this, void 0, void 0, function () {
                var nextImageIndex, options;
                var _this = this;
                return __generator(this, function (_a) {
                    event.preventDefault();
                    event.stopPropagation();
                    nextImageIndex = this.currentImage + direction;
                    if (nextImageIndex < 0 || nextImageIndex >= this.refs.images.length)
                        return [2 /*return*/];
                    options = {
                        scrollLeft: this.refs.slider.scrollLeft
                    };
                    this.switching = true;
                    if (this.animation)
                        this.animation.pause();
                    this.animation = anime({
                        targets: options,
                        scrollLeft: nextImageIndex * this.fraction,
                        easing: 'easeOutQuad',
                        update: function () {
                            _this.refs.slider.scrollLeft = options.scrollLeft;
                            var progress = options.scrollLeft / (_this.refs.slider.scrollWidth - _this.refs.slider.clientWidth);
                            _this.refs.track.style.transform = "translateX(".concat(progress * (100) * (_this.refs.images.length - 1), "%)");
                        },
                        duration: 600
                    });
                    this.animation.finished.then(function () {
                        _this.switching = false;
                    });
                    this.currentImage = nextImageIndex;
                    return [2 /*return*/];
                });
            }); }
        });
        Object.defineProperty(_this, "onScroll", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                if (_this.switching)
                    return;
                _this.fraction = _this.refs.slider.scrollWidth / _this.refs.images.length;
                var currentImage = Math.round(_this.refs.slider.scrollLeft / _this.fraction);
                var progress = _this.refs.slider.scrollLeft / (_this.refs.slider.scrollWidth - _this.refs.slider.clientWidth);
                _this.currentImage = currentImage;
                _this.refs.track.style.transform = "translateX(".concat(progress * (100) * (_this.refs.images.length - 1), "%)");
            }
        });
        Object.defineProperty(_this, "addToCart", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                e.preventDefault();
                e.stopPropagation();
                e.stopImmediatePropagation();
                sendProductForm({
                    submitter: _this.refs.addToCartButton,
                    form: _this.modules.addToCartForm,
                    callback: _this.onAddToCart
                });
            }
        });
        Object.defineProperty(_this, "onAddToCart", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (_a) {
                var data = _a.data, xhr = _a.xhr;
                if (!data.error) {
                    _this.emit('add-to-cart', {
                        xhr: xhr
                    });
                }
            }
        });
        return _this;
    }
    Object.defineProperty(ProductCard.prototype, "switching", {
        get: function () {
            return this._switching;
        },
        set: function (value) {
            this._switching = value;
            this.refs.slider.classList.toggle('switching', value);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProductCard.prototype, "currentImage", {
        get: function () {
            return this._currentImage;
        },
        set: function (value) {
            this._currentImage = value;
            if (this.refs.prevImage)
                this.refs.prevImage.classList.toggle('disabled', value === 0);
            if (this.refs.nextImage)
                this.refs.nextImage.classList.toggle('disabled', value === this.refs.images.length - 1);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ProductCard.prototype, "getModuleParams", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (el, componentConstructor) {
            var _a;
            return {
                parent: ((_a = this.parent) === null || _a === void 0 ? void 0 : _a.parent) || this.parent
            };
        }
    });
    Object.defineProperty(ProductCard.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                addToWishlist: ['.add-to-wishlist', AddToWishlist],
                addToCartForm: ['.add-to-cart__form', Form]
            };
        }
    });
    Object.defineProperty(ProductCard.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
            if (this.refs.slider)
                this.fraction = this.refs.slider.scrollWidth / this.refs.images.length;
            if (this.el.querySelector('video')) {
                this.io = new IntersectionObserver(this.inViewUpdate.bind(this), { rootMargin: '-50px 0px 0px 0px' });
                this.io.observe(this.el);
            }
        }
    });
    Object.defineProperty(ProductCard.prototype, "inViewUpdate", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (entries) {
            for (var i = 0; i < entries.length; i++) {
                var video = entries[i].target.querySelector('video');
                try {
                    if (entries[i].isIntersecting) {
                        if (video)
                            video.play();
                    }
                    else {
                        if (video)
                            video.pause();
                    }
                }
                catch (e) {
                    console.warn('Error playing video', e);
                }
            }
        }
    });
    Object.defineProperty(ProductCard.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            if (this.refs.slider)
                this.fraction = this.refs.slider.scrollWidth / this.refs.images.length;
            _super.prototype.resize.call(this);
        }
    });
    return ProductCard;
}(Component));
export default ProductCard;
