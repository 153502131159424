var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import DragController from 'components/DragController/DragController';
import { bindEmitterMethod, bindMethod } from 'helpers/bind';
import Component from 'navigation/component/Component';
var EditorialRelatedContent = /** @class */ (function (_super) {
    __extends(EditorialRelatedContent, _super);
    function EditorialRelatedContent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(EditorialRelatedContent.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
        }
    });
    Object.defineProperty(EditorialRelatedContent.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                dragController: ['.editorial-related-content__list-wrapper', DragController]
            };
        }
    });
    Object.defineProperty(EditorialRelatedContent.prototype, "getModuleParams", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (el, componentConstructor) {
            return {
                itemsVisible: 3
            };
        }
    });
    Object.defineProperty(EditorialRelatedContent.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            var emitterMethod = bindEmitterMethod(add);
            var method = bindMethod(add);
            this.modules.dragController[emitterMethod]('move', function () { return _this.onMove(); });
            if (this.refs.prev && this.refs.next) {
                this.refs.prev[method]('click', function () { return _this.modules.dragController.navigate(-1); });
                this.refs.next[method]('click', function () { return _this.modules.dragController.navigate(1); });
            }
        }
    });
    Object.defineProperty(EditorialRelatedContent.prototype, "onMove", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _a, _b;
            if (!this.refs.next && !this.refs.prev)
                return;
            (_a = this.refs) === null || _a === void 0 ? void 0 : _a.prev.classList.toggle('disabled', this.modules.dragController.progress === 0);
            var isAtBeginning = this.modules.dragController.progress === 0;
            (_b = this.refs) === null || _b === void 0 ? void 0 : _b.prev[isAtBeginning ? 'setAttribute' : 'removeAttribute']('disabled', '');
            var isAtEnd = this.modules.dragController.progress === 1;
            this.refs.next.classList.toggle('disabled', this.modules.dragController.progress === 1);
            this.refs.next[isAtEnd ? 'setAttribute' : 'removeAttribute']('disabled', '');
        }
    });
    return EditorialRelatedContent;
}(Component));
export default EditorialRelatedContent;
