var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { bindMethod } from 'helpers/bind';
import Component from 'navigation/component/Component';
import mqStore from 'store/mqStore';
var MenuPanel = /** @class */ (function (_super) {
    __extends(MenuPanel, _super);
    function MenuPanel() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        Object.defineProperty(_this, "mobile", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(_this, "category", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(_this, "motions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(_this, "onMqChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (mobile) {
                _this.mobile = mobile;
            }
        });
        return _this;
    }
    Object.defineProperty(MenuPanel.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            var _a, _b;
            var method = bindMethod(add);
            (_a = this.refs.links) === null || _a === void 0 ? void 0 : _a.forEach(function (link) {
                link[method]('mouseover', function (e) { return _this.onLinkOver(e); });
                link[method]('mouseout', function () { return _this.onLinkOut(); });
            });
            (_b = this.refs.lists) === null || _b === void 0 ? void 0 : _b.forEach(function (list) {
                list[method]('mouseout', function () { return _this.onListOut(); });
            });
            mqStore.tabletPortrait.listenAndStart(this.onMqChange);
        }
    });
    Object.defineProperty(MenuPanel.prototype, "onLinkOver", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (event) {
            var _a;
            (_a = this.refs.links) === null || _a === void 0 ? void 0 : _a.forEach(function (link) {
                link.classList.remove('active');
                link.classList.add('inactive');
            });
            var link = event.currentTarget;
            link.classList.add('active');
        }
    });
    Object.defineProperty(MenuPanel.prototype, "onLinkOut", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _this = this;
            var _a, _b;
            if (this.category) {
                (_a = this.refs.links) === null || _a === void 0 ? void 0 : _a.forEach(function (link) {
                    if (link.dataset.slug === _this.category) {
                        link.classList.remove('inactive');
                        link.classList.add('active');
                    }
                    else {
                        link.classList.add('inactive');
                        link.classList.remove('active');
                    }
                });
            }
            else {
                (_b = this.refs.links) === null || _b === void 0 ? void 0 : _b.forEach(function (link) {
                    link.classList.remove('active');
                    link.classList.remove('inactive');
                });
            }
        }
    });
    Object.defineProperty(MenuPanel.prototype, "onListOut", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            var _a, _b;
            if (this.category)
                return;
            (_a = this.refs.links) === null || _a === void 0 ? void 0 : _a.forEach(function (link) {
                // if (link.dataset.slug === this.category) return
                link.classList.remove('active');
                link.classList.remove('inactive');
            });
            (_b = this.refs.items) === null || _b === void 0 ? void 0 : _b.forEach(function (item) {
                // if (item.dataset.slug === this.category) return
                item.classList.remove('active');
                item.classList.remove('inactive');
            });
        }
    });
    Object.defineProperty(MenuPanel.prototype, "open", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () { }
    });
    Object.defineProperty(MenuPanel.prototype, "forceOpen", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () { }
    });
    Object.defineProperty(MenuPanel.prototype, "forceClose", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () { }
    });
    Object.defineProperty(MenuPanel.prototype, "close", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () { }
    });
    Object.defineProperty(MenuPanel.prototype, "cancelMotions", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.motions.forEach(function (motion) {
                if (motion)
                    motion.pause();
            });
            this.motions = [];
        }
    });
    Object.defineProperty(MenuPanel.prototype, "reset", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.onListOut();
        }
    });
    return MenuPanel;
}(Component));
export default MenuPanel;
