import { pull } from 'lodash-es';
var supportObserver = ('IntersectionObserver' in window);
var createObserver = supportObserver && (function (_a) {
    var _b = _a === void 0 ? {} : _a, _c = _b.threshold, threshold = _c === void 0 ? [0] : _c, rootMargin = _b.rootMargin;
    var callbacks = [];
    var callback = function (entries) { return entries.forEach(function (entry) {
        callbacks.forEach(function (cb) { return cb(entries); });
        entry.target && entry.target.callback && entry.target.callback(entry);
    }); };
    var observer = new IntersectionObserver(callback, { rootMargin: rootMargin, threshold: threshold });
    return {
        add: function (item, callback) {
            item.callback = callback;
            observer.observe(item);
        },
        listen: function (cb) { return callbacks.push(cb); },
        unlisten: function (cb) { return pull(callbacks, cb); },
        remove: function (item, callback) {
            observer.unobserve(item);
            item.callback = null;
        }
    };
});
export { createObserver, supportObserver };
