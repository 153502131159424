var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import Component from 'navigation/component/Component';
import { extractInfoFromXhr } from 'navigation/page-manager/CustomPageManager';
import store from 'store';
import NewsletterForm from 'components/newsletter-form/NewsletterForm';
import DrawerPanel from 'components/drawer-panel/DrawerPanel';
var Footer = /** @class */ (function (_super) {
    __extends(Footer, _super);
    function Footer(el, options) {
        var _this = _super.call(this, el, options) || this;
        Object.defineProperty(_this, "pageManager", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "loading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "onLinkMouseEnter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                _this.refs.selectLinks.forEach(function (link) {
                    link.className = 'inactive';
                    if (link === e.target)
                        link.className = 'active';
                });
            }
        });
        Object.defineProperty(_this, "onLinkMouseLeave", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (e) {
                _this.refs.selectLinks.forEach(function (link) {
                    link.classList.remove('active');
                    link.classList.remove('inactive');
                });
            }
        });
        Object.defineProperty(_this, "onFormSubmit", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (formData, urlParameters) {
                var form = _this.modules.newsletterForm;
                var xhr = new XMLHttpRequest();
                _this.loading = true;
                xhr.withCredentials = true;
                xhr.open(form.method, form.action, true);
                xhr.responseType = 'document';
                xhr.onload = _this.onXhrComplete;
                xhr.send(formData);
                return false;
            }
        });
        Object.defineProperty(_this, "onXhrComplete", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (event) {
                var form = _this.modules.newsletterForm;
                form.submitted = false;
                _this.loading = false;
                var xhr = event.currentTarget;
                extractInfoFromXhr(xhr);
                xhr.onload = null;
                _this.modules.newsletterForm.clear();
            }
        });
        Object.defineProperty(_this, "onAccordionClick", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function (_a) {
                var currentTarget = _a.currentTarget;
                currentTarget.classList.toggle('opened');
            }
        });
        _this.pageManager = options.parent.pageManager;
        return _this;
    }
    Object.defineProperty(Footer.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.refs.selectLinks.push(this.refs.webstoreSwitcher);
            _super.prototype.initialized.call(this);
            this.modules.newsletterForm.submitCallback = this.onFormSubmit;
        }
    });
    Object.defineProperty(Footer.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var _this = this;
            var _a;
            if (add === void 0) { add = true; }
            var m = add ? 'addEventListener' : 'removeEventListener';
            if (this.refs.footerAccordion) {
                this.refs.footerAccordion.forEach(function (a) {
                    a[m]('click', _this.onAccordionClick);
                });
            }
            if (this.refs.webstoreSwitcher)
                this.refs.webstoreSwitcher[m]('click', this.onWebstoreSwitcherClick);
            (_a = this.refs.selectLinks) === null || _a === void 0 ? void 0 : _a.forEach(function (link) {
                link[m]('mouseenter', function (e) { return _this.onLinkMouseEnter(e); });
                link[m]('mouseleave', function (e) { return _this.onLinkMouseLeave(e); });
            });
        }
    });
    Object.defineProperty(Footer.prototype, "bindModules", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.bindModules.call(this);
        }
    });
    Object.defineProperty(Footer.prototype, "onWebstoreSwitcherClick", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (event) {
            store.drawer.set('webstore-switcher');
        }
    });
    Object.defineProperty(Footer.prototype, "getModulesMap", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            return {
                newsletterForm: ['.footer__form', NewsletterForm],
                footerAccordion: ['.footer__accordion', DrawerPanel]
            };
        }
    });
    return Footer;
}(Component));
export default Footer;
