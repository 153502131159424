/**
 * All durations are set in frames (based on a 60fps motion)
 * and then converted in ms
 */
export var frameToMs = function (frame) { return frame * (1000 / 60); };
export var durations = {
    /** First Panel -> Panel */
    FIRST_PANEL_TRANSLATE_IN: frameToMs(50),
    FIRST_PANEL_TRANSLATE_OUT: frameToMs(25),
    FIRST_PANEL_OPACITY_IN: frameToMs(20),
    FIRST_PANEL_OPACITY_OUT: frameToMs(10),
    /** First Panel -> Lists */
    FIRST_PANEL_LIST_STAGGER_IN: frameToMs(5),
    /** First Panel -> Items */
    FIRST_PANEL_ITEMS_STAGGER: frameToMs(1),
    FIRST_PANEL_ITEMS_TRANSLATE_IN_DELAY: frameToMs(10),
    FIRST_PANEL_ITEMS_OPACITY_IN_DELAY: frameToMs(20),
    FIRST_PANEL_ITEMS_TRANSLATE: frameToMs(40),
    FIRST_PANEL_ITEMS_OPACITY: frameToMs(20),
    /** Second panel -> Panel */
    SECOND_PANEL_TRANSLATE_IN: frameToMs(42),
    SECOND_PANEL_TRANSLATE_OUT: frameToMs(25),
    SECOND_PANEL_OPACITY_IN: frameToMs(20),
    SECOND_PANEL_OPACITY_OUT: frameToMs(10),
    /** Second Panel -> Lists */
    SECOND_PANEL_LIST_STAGGER_IN: frameToMs(5),
    /** Second Panel -> Items */
    SECOND_PANEL_ITEMS_STAGGER: frameToMs(1),
    SECOND_PANEL_ITEMS_TRANSLATE_IN_DELAY: frameToMs(10),
    SECOND_PANEL_ITEMS_OPACITY_IN_DELAY: frameToMs(20),
    SECOND_PANEL_ITEMS_TRANSLATE: frameToMs(40),
    SECOND_PANEL_ITEMS_OPACITY: frameToMs(20),
    /** First and second panel */
    FIRST_SECOND_PANEL_TRANSLATE_OUT: frameToMs(10),
    FIRST_SECOND_PANEL_OPACITY_OUT: frameToMs(10)
};
