var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import anime from 'animejs';
import { bindMethod } from 'helpers/bind';
import { EASE_OUT, frameToMs } from 'helpers/easings';
import Component from 'navigation/component/Component';
var TRANSLATE_OFFSET = 50;
var ImageZoomItem = /** @class */ (function (_super) {
    __extends(ImageZoomItem, _super);
    function ImageZoomItem() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        Object.defineProperty(_this, "motions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(_this, "header", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(_this, "onProductScroll", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: function () {
                _this.emit('item:toggle-nav', _this.refs.products.scrollTop > 100);
            }
        });
        return _this;
    }
    Object.defineProperty(ImageZoomItem.prototype, "initialized", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.initialized.call(this);
            this.header = this.el.getAttribute('data-header') || '';
        }
    });
    Object.defineProperty(ImageZoomItem.prototype, "bindEvents", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add) {
            var method = bindMethod(add);
            if (this.refs.products)
                this.refs.products[method]('scroll', this.onProductScroll);
        }
    });
    Object.defineProperty(ImageZoomItem.prototype, "onPrev", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.emit('item:prev');
        }
    });
    Object.defineProperty(ImageZoomItem.prototype, "onNext", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.emit('item:next');
        }
    });
    Object.defineProperty(ImageZoomItem.prototype, "enter", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (direction) {
            var _this = this;
            if (direction === void 0) { direction = 1; }
            this.resize();
            var m = anime({
                begin: function () {
                    _this.el.classList.add('active');
                    _this.el.classList.add('enter');
                    _this.emit('item:enter', _this.header);
                    _this.resize();
                },
                targets: this.el,
                opacity: [0, 1],
                translateX: {
                    value: direction === 1 ? [TRANSLATE_OFFSET, 0] : [-TRANSLATE_OFFSET, 0],
                    duration: frameToMs(25),
                    easing: EASE_OUT
                },
                duration: frameToMs(25),
                easing: 'linear',
                complete: function () {
                    _this.el.classList.remove('enter');
                    _this.resize();
                }
            });
            this.motions.push(m);
        }
    });
    Object.defineProperty(ImageZoomItem.prototype, "leave", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (direction) {
            var _this = this;
            if (direction === void 0) { direction = 1; }
            var m = anime({
                begin: function () {
                    _this.el.classList.add('leave');
                },
                complete: function () {
                    _this.el.classList.remove('active');
                    _this.el.classList.remove('leave');
                },
                targets: this.el,
                opacity: [1, 0],
                translateX: {
                    value: direction === 1 ? [0, -TRANSLATE_OFFSET] : [0, TRANSLATE_OFFSET],
                    duration: frameToMs(25),
                    easing: EASE_OUT
                },
                duration: frameToMs(10),
                easing: 'linear'
            });
            this.motions.push(m);
        }
    });
    Object.defineProperty(ImageZoomItem.prototype, "reset", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.el.classList.remove('active');
            this.el.classList.remove('enter');
            this.el.classList.remove('leave');
            this.el.style.opacity = '';
            this.el.style.transform = '';
            this.cancelMotions();
        }
    });
    Object.defineProperty(ImageZoomItem.prototype, "cancelMotions", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            this.motions.forEach(function (m) { return m.pause(); });
        }
    });
    Object.defineProperty(ImageZoomItem.prototype, "resize", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () {
            _super.prototype.resize.call(this);
            if (!this.refs.image)
                return;
            var offset = this.refs.image.getBoundingClientRect().height;
            if (offset > 0)
                this.parent.el.style.setProperty('--look-offset', "".concat(offset, "px"));
            // else
            //   (this.parent as any).el.style.removeProperty('--look-offset')
        }
    });
    return ImageZoomItem;
}(Component));
export default ImageZoomItem;
