import { mapValues } from 'lodash-es';
import { TinyEmitter } from 'tiny-emitter';
var State = /** @class */ (function () {
    function State(value, name) {
        Object.defineProperty(this, "emitter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "value", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "name", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.emitter = new TinyEmitter();
        this.value = value;
        this.name = name;
    }
    Object.defineProperty(State.prototype, "toggleListener", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (add, callback, context) {
            if (add)
                return this.listen(callback, context);
            else
                return this.unlisten(callback);
        }
    });
    Object.defineProperty(State.prototype, "listenAndStart", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (callback, context) {
            this.listen(callback, context);
            callback(this.value, this.value);
        }
    });
    Object.defineProperty(State.prototype, "listen", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (callback, context) { this.emitter.on('', callback, context); }
    });
    Object.defineProperty(State.prototype, "listenOnce", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (callback, context) { this.emitter.once('', callback, context); }
    });
    Object.defineProperty(State.prototype, "unlisten", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (callback) {
            this.emitter.off('', callback);
        }
    });
    Object.defineProperty(State.prototype, "destroy", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () { this.unlisten(); }
    });
    Object.defineProperty(State.prototype, "set", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function (value, force) {
            if (!force && this.value === value)
                return;
            var previous = this.value;
            this.value = value;
            this.emitter.emit('', value, previous);
        }
    });
    Object.defineProperty(State.prototype, "get", {
        enumerable: false,
        configurable: true,
        writable: true,
        value: function () { return this.value; }
    });
    return State;
}());
export { State };
export function createStore(state) {
    var emitters = {};
    for (var k in state)
        emitters[k] = new State(state[k], k);
    emitters = Object.freeze(emitters);
    return emitters;
}
export function exportStore(store) {
    return mapValues(store, function (v) { return v.get(); });
}
export default createStore;
