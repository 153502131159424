import 'normalize.css/normalize.css';
import 'styles/main.scss';
import resize from 'helpers/resize';
import 'core/pageMap';
import 'helpers/detect';
import App from './App';
resize.setRoot(document.body);
App.prototype.updateSizes();
window.addEventListener('DOMContentLoaded', function () { return new App(document.body); });
document.documentElement.classList.add('app-started');
if (module.hot)
    module.hot.accept();
